import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Modal from '@mui/material/Modal'

export default function DelinquentOperations({
    rows,
    headers,
    labels,
    modalOperationsOpen,
    handleToggleModalOperations,
    infoId,
    search,
    setSearch,
    formatAmount,
    downloadExcel,
    downloadPdf,    
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [headersFacturas, setHeadersFacturas] = useState(['Razón social emisor', 'Rut emisor', 'Fecha emisión', 'Tipo documento', 'Monto original ($)']);
    const [headersOperaciones, setHeadersOperaciones] = useState(['Razón social cesionario', 'Rut cesionario', 'Tasa operación (%)', 'Comisión ($)', 'Interés ganado ($)', 'Monto pagado ($)']);
    const [withTotals, setWithTotals] = useState(true);

    const handleDownloadExcel = () => {
        let title = 'Operaciones en mora';
        let newHeaders = headers.splice(0, headers.length - 1).concat(headersFacturas).concat(headersOperaciones)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                id: object.id,
                company: object.company,
                rut: object.rut,
                folio: object.folio,
                date: object.date,
                dueDate: object.dueDate,
                amountCeded: object.amountCeded,
                amountBought: object.amountBought,
                daysPastDue: object.daysPastDue,
                razonSocialEmisor: object.razonSocialEmisor,
                rutEmisor: object.rutEmisor,
                emissionDate: object.emissionDate,
                tipoFolio: object.tipoFolio,
                montoOriginal: object.montoOriginal,
                razonSocialCesionario: object.razonSocialCesionario,
                rutCesionario: object.rutCesionario,
                tasa: object.tasa,
                comission: object.comission,
                difference: object.difference,
                deposit: object.deposit
            })
            return dataRows;
        });
   
        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Operaciones en mora';
        let newHeaders = headers.splice(0, headers.length - 1).concat(headersFacturas).concat(headersOperaciones)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                id: object.id,
                company: object.company,
                rut: object.rut,
                folio: object.folio,
                date: object.date,
                dueDate: object.dueDate,
                amountCeded: object.amountCeded,
                amountBought: object.amountBought,
                daysPastDue: object.daysPastDue,
                razonSocialEmisor: object.razonSocialEmisor,
                rutEmisor: object.rutEmisor,
                emissionDate: object.emissionDate,
                tipoFolio: object.tipoFolio,
                montoOriginal: object.montoOriginal,
                razonSocialCesionario: object.razonSocialCesionario,
                rutCesionario: object.rutCesionario,
                tasa: object.tasa,
                comission: object.comission,
                difference: object.difference,
                deposit: object.deposit
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    const calculateTotals = (transactions) => {
        if(withTotals) {
            let totalCedido = 0;
            let totalFinanciado = 0;

            transactions.map(transaction => {
                if(transaction.id.props === undefined){
                    totalCedido += Number(transaction.amountCeded.replaceAll(".", ""))
                    totalFinanciado += Number(transaction.amountBought.replaceAll(".", ""))
                }

                return transaction
            })

            return transactions.concat([{
                id: <Typography variant="h6">Totales</Typography>,
                company: '',
                rut: '',
                folio: '',
                date: '',
                dueDate: '',
                amountCeded: formatAmount(totalCedido),
                amountBought: formatAmount(totalFinanciado),
                daysPastDue: '',
                razonSocialEmisor: ''
            }])
        }else{
            return transactions
        }
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                if(element.id !== "Totales")
                    return element.company.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                        || element.rut.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                        || String(element.number).includes(search)
                        || element.id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                        || element.folio.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                else
                    return null
            }));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rows]);

    return (
        <>
            <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            {/* <Grid item xs={12} sx={{ display: "flex", alignItems: 'center', marginBottom: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <FormControl sx={{ width: 250, marginRight: 5 }}>
                        <DatePicker
                            label={<Typography variant='h6' color="primary">Desde</Typography>}
                            value={sinceValue}
                            InputAdornmentProps={{
                                className: 'datePickerCalendars'
                            }}
                            onChange={(newValue) => {
                                setSinceValue(moment(newValue));
                            }}
                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                        />
                    </FormControl>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <DatePicker
                        label={<Typography variant='h6' color="primary">Hasta</Typography>}
                        value={untilValue}
                        InputAdornmentProps={{
                            className: 'datePickerCalendars'
                        }}
                        onChange={(newValue) => {
                            setUntilValue(moment(newValue));
                        }}
                        renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                    />
                </LocalizationProvider>
            </Grid> */}
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Operaciones en mora
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={ calculateTotals(currentTransactions.slice((page * 14), (page * 14) + 14)) }
                                headers={headers}
                                labels={labels}
                                withTotal={withTotals}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length/14)}
                />
            </Box>
            <Modal
                open={modalOperationsOpen}
                onClose={() => handleToggleModalOperations()}
            >
                <Box className='transactions-modal'>
                    <Grid container>
                        <Grid item sx={{width: '100%'}}>
                            <Typography variant="h5" fontWeight={600} sx={{marginBottom: 2}}>
                                Información de la factura
                            </Typography>
                            <Tables
                                rows={currentTransactions.filter(x => x.id === infoId)}
                                headers={headersFacturas}
                                labels={['razonSocialEmisor', 'rutEmisor', 'emissionDate', 'tipoFolio', 'montoOriginal']}
                            />
                        </Grid>
                        <Grid item sx={{marginTop: 5, width: '100%'}} >
                            <Typography variant="h5" fontWeight={600} sx={{marginBottom: 2}}>
                                Información de la operación
                            </Typography>
                            <Tables
                                rows={currentTransactions.filter(x => x.id === infoId)}
                                headers={headersOperaciones}
                                labels={['razonSocialCesionario', 'rutCesionario', 'tasa', 'comission', 'difference', 'deposit']}
                            />
                        </Grid>
                        <Grid item sx={{ marginTop: 2,  width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant='contained'
                                color='buttonColor'
                                onClick={() => handleToggleModalOperations()}
                            >
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}