import React, { useState, useEffect } from  'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paginations from '../../../Components/Pagination/Paginations'
import Tables from '../../../Components/Tables/Table'
import SearchBar from '../../../Components/SearchBar/SearchBar'

export default function DownloadRecords ({
    rows,
    headers,
    labels,
    search,
    setSearch
}) {

    const {
        downloadExcel,
        downloadPdf,
        formatAmount
    } = useAppContext();

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);

    const handleDownloadExcel = () => {
        let title = 'Histórico de descargas archivos para pagos';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                date: object.date,
                fund: object.fund,
                total: formatAmount(object.total),
                quantity: object.quantity,
                status: object.status.props.children,

            })
            return dataRows;
        });
   
        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Histórico de descargas archivos para pagos';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
                dataRows.push({
                    date: object.date,
                    fund: object.fund,
                    total: formatAmount(object.total),
                    number: object.quantity,
                    status: object.status.props.children,

                })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.fund.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || String(element.number).includes(search)
            }
            ));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rows]);

    return (
        <>
            <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Histórico de descargas archivos para pagos
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length / 14)}
                />
            </Box>
        </>
    )
}