const data = [
    {
      amount: 0.45,
      value3: 10,
      // value2: 20,
      // value1: 30,
      gap: 1
    },
    {
      amount: 0.47,
      invisible: 10,
      loss: 40,
      gap: 1
    },
    // {
    //   amount: 0.50,
    //   invisible: 100,
    //   loss1: 50,
    //   gap: 1
    // },
    // {
    //   amount: 0.54,
    //   invisible: 150,
    //   value6: 30,
    //   gap: 1
    // }
  ];

export default data