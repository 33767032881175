import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Box from '@mui/material/Box'
import Tables from '../../Components/Tables/Table'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '../../Components/Switches/Switches';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Grid from '@mui/material/Grid'
import Paginations from '../../Components/Pagination/Paginations'
import InputAdornment from '@mui/material/InputAdornment'
import axios from 'axios'

export default function LimitesDiarios({
    fundsData,
    payersData,
    handleSetActiveFund,
    search,
    setSearch,
    changeAmount,
    setChangeAmount,
    changeAmountPayer,
    setChangeAmountPayer
}) {
    const {
        activeFund,
        formatAmount,
        fundLimit,
        payerLimit,
        maxInvoiceFund,
        minInvoiceFund,
        maxInvoicePayer,
        minInvoicePayer,
        downloadExcel,
        downloadPdf,
        hostService,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType
    } = useAppContext();

    const [pageFunds, setPageFunds] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(fundsData);
    const [newMaxAmount, setNewMaxAmount] = useState([]);
    const [newMaxAmountPayer, setNewMaxAmountPayer] = useState([]);
    const [newMaxInvoiceFundAmount, setNewMaxInvoiceFundAmount] = useState([]);
    const [newMinInvoiceFundAmount, setNewMinInvoiceFundAmount] = useState([]);
    const [newMaxInvoicePayerAmount, setNewMaxInvoicePayerAmount] = useState([]);
    const [newMinInvoicePayerAmount, setNewMinInvoicePayerAmount] = useState([]);

    const [pagePayers, setPagePayers] = useState(0);
    const [currentPayers, setcurrentPayers] = useState(payersData);
    const [searchPayer, setSearchPayer] = useState('');

    const handleAddNewMaxAmount = (object) => {
        var temp = newMaxAmount.filter(x => x.id !== object.id);
        temp.push(object)
        setNewMaxAmount(temp);
    }

    const handleAddNewMaxAmountPayer = (object) => {
        var temp = newMaxAmountPayer.filter(x => x.id !== object.id);
        temp.push(object)
        setNewMaxAmountPayer(temp);
    }

    const handleAddNewMaxInvoiceFundAmount = (object, tipo) => {
        var temp;
        if (tipo === "max") temp = newMaxInvoiceFundAmount.filter(x => x.id !== object.id);
        else temp = newMinInvoiceFundAmount.filter(x => x.id !== object.id);

        temp.push(object)

        if (tipo === "max") setNewMaxInvoiceFundAmount(temp);
        else setNewMinInvoiceFundAmount(temp);
    }

    const handleAddNewMaxInvoicePayerAmount = (object, tipo) => {
        var temp;
        if (tipo === "max") temp = newMaxInvoicePayerAmount.filter(x => x.id !== object.id);
        else temp = newMinInvoicePayerAmount.filter(x => x.id !== object.id);

        temp.push(object)
        if (tipo === "max") setNewMaxInvoicePayerAmount(temp);
        else setNewMinInvoicePayerAmount(temp);

    }

    const setMaxAmount = (id) => {
        if (newMaxAmount.find(x => x.id === id) !== undefined) {
            if (newMaxAmount.find(x => x.id === id).amount !== "") {
                axios.post(hostService + `servicios/Compradores.asmx/ActualizarMontoMaximoFondo`, { idFondo: id, monto: newMaxAmount.find(x => x.id === id).amount }, { withCredentials: true })
                    .then(response => {
                        if (response.data.d === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (response.data.d === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else if (response.data.d === 'ok') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Máximo Actualizado');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                            setNewMaxAmount(newMaxAmount.filter(x => x.id !== id));
                            setChangeAmount(!changeAmount);
                        } else if (response.data.d === "montoInsuficiente") {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Insuficiente');
                            setAlertMessageDescription('El monto máximo no puede ser menor o igual al monto usado');
                            setAlertMessageType('warning');
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Ocurrió un error inesperado');
                            setAlertMessageDescription('Inténtalo nuevamente');
                            setAlertMessageType('error');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });
            }
        }
    }

    const setMaxAmountPayer = (id) => {
        if (newMaxAmountPayer.find(x => x.id === id) !== undefined) {
            if (newMaxAmountPayer.find(x => x.id === id).amount !== "") {
                axios.post(hostService + `servicios/Compradores.asmx/ActualizarMontoMaximo`, { montoMaximo: newMaxAmountPayer.find(x => x.id === id).amount, idPagadora: id }, { withCredentials: true })
                    .then(response => {
                        if (response.data.d === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (response.data.d === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else if (response.data.d === 'ok') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Máximo Actualizado');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                            setNewMaxAmountPayer(newMaxAmountPayer.filter(x => x.id !== id));
                            setChangeAmountPayer(!changeAmountPayer);
                        } else if (response.data.d === "montoInsuficiente") {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Insuficiente');
                            setAlertMessageDescription('El monto máximo no puede ser menor o igual al monto usado');
                            setAlertMessageType('warning');
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Ocurrió un error inesperado');
                            setAlertMessageDescription('Inténtalo nuevamente');
                            setAlertMessageType('error');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });
            }
        }
    }

    const SetMaxInvoiceFundAmount = (id, tipo) => {
        if ((tipo === "max" ? newMaxInvoiceFundAmount : newMinInvoiceFundAmount).find(x => x.id === id) !== undefined) {
            if ((tipo === "max" ? newMaxInvoiceFundAmount : newMinInvoiceFundAmount).find(x => x.id === id).amount !== "") {
                axios.post(hostService + `servicios/Compradores.asmx/ActualizarMaximoFacturaFondo`, { idFondo: id, monto: (tipo === "max" ? newMaxInvoiceFundAmount : newMinInvoiceFundAmount).find(x => x.id === id).amount, tipo: tipo }, { withCredentials: true })
                    .then(response => {
                        if (response.data.d === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (response.data.d === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else if (response.data.d === 'ok') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto actualizado');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                            (tipo === "max" ? setNewMaxInvoiceFundAmount : setNewMinInvoiceFundAmount)((tipo === "max" ? newMaxInvoiceFundAmount : newMinInvoiceFundAmount).filter(x => x.id !== id));
                            setChangeAmount(!changeAmount);
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Ocurrió un error inesperado');
                            setAlertMessageDescription('Inténtalo nuevamente');
                            setAlertMessageType('error');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });
            }
        }
    }

    const SetMaxInvoicePayerAmount = (id, tipo) => {
        if ((tipo === "max" ? newMaxInvoicePayerAmount : newMinInvoicePayerAmount).find(x => x.id === id) !== undefined) {
            if ((tipo === "max" ? newMaxInvoicePayerAmount : newMinInvoicePayerAmount).find(x => x.id === id).amount !== "") {
                axios.post(hostService + `servicios/Compradores.asmx/ActualizarMaximoFacturaPagadora`, { idPagadora: id, monto: (tipo === "max" ? newMaxInvoicePayerAmount : newMinInvoicePayerAmount).find(x => x.id === id).amount, tipo: tipo }, { withCredentials: true })
                    .then(response => {
                        if (response.data.d === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (response.data.d === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else if (response.data.d === 'ok') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto actualizado');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                            (tipo === "max" ? setNewMaxInvoicePayerAmount : setNewMinInvoicePayerAmount)((tipo === "max" ? newMaxInvoicePayerAmount : newMinInvoicePayerAmount).filter(x => x.id !== id));
                            setChangeAmountPayer(!changeAmountPayer);
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Ocurrió un error inesperado');
                            setAlertMessageDescription('Inténtalo nuevamente');
                            setAlertMessageType('error');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });
            }
        }
    }

    const handleDownloadExcel = () => {
        let title = 'Administrar límites diarios de Pagadoras fondos';
        let headers = {
            nombre: 'Nombre',
            montoMaximo: 'Monto Máximo ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoDisponible: 'Monto Disponible ($)',
            activo: 'Activo'
        }
        let rows = currentTransactions.map((e, i) => {
            let row = {
                name: e.nombre,
                montoMaximo: formatAmount(e.montoMaximo),
                montoUsado: formatAmount(e.montoUsado),
                montoDisponible: formatAmount(e.montoDisponible),
                active: e.nombre === activeFund.nombre ? 'Activo' : 'Inactivo'
            }
            return row;
        });

        downloadExcel(title, headers, rows);
    }

    const handleDownloadPdf = () => {
        let title = 'Administrar límites diarios de Pagadoras fondos';
        let headers = {
            nombre: 'Nombre',
            montoMaximo: 'Monto Máximo ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoDisponible: 'Monto Disponible ($)',
            activo: 'Activo'
        }
        let rows = currentTransactions.map((e, i) => {
            let row = {
                name: e.nombre,
                montoMaximo: formatAmount(e.montoMaximo),
                montoUsado: formatAmount(e.montoUsado),
                montoDisponible: formatAmount(e.montoDisponible),
                minimoFactura: formatAmount(e.minimoFactura),
                maximoFactura: formatAmount(e.maximoFactura),
                active: e.nombre === activeFund.nombre ? 'Activo' : 'Inactivo'
            }
            return row;
        });

        downloadPdf(title, headers, rows);
    }

    const handleDownloadExcelPayers = () => {
        let title = 'Administrar límites diarios de Pagadoras';
        let headers = {
            nombre: 'Nombre',
            montoDisponible: 'Monto Disponible ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoMaximo: 'Monto Máximo ($)',
            minimoFactura: 'Mínimo factura ($)',
            maximoFactura: 'Máximo factura ($)',
        }
        let rows = currentPayers.map((e, i) => {
            let row = {
                name: e.nombre,
                montoDisponible: formatAmount(e.montoDisponible),
                montoUsado: formatAmount(e.montoUsado),
                montoMaximo: formatAmount(e.montoMaximo),
                minimoFactura: formatAmount(e.minimoFactura),
                maximoFactura: formatAmount(e.maximoFactura),
            }
            return row;
        });

        downloadExcel(title, headers, rows);
    }

    const handleDownloadPdfPayers = () => {
        let title = 'Administrar límites diarios de Pagadoras';
        let headers = {
            nombre: 'Nombre',
            montoDisponible: 'Monto Disponible ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoMaximo: 'Monto Máximo ($)',
            minimoFactura: 'Mínimo factura ($)',
            maximoFactura: 'Máximo factura ($)',
        }
        let rows = currentPayers.map((e, i) => {
            let row = {
                name: e.nombre,
                montoDisponible: formatAmount(e.montoDisponible),
                montoUsado: formatAmount(e.montoUsado),
                montoMaximo: formatAmount(e.montoMaximo),
                minimoFactura: formatAmount(e.minimoFactura),
                maximoFactura: formatAmount(e.maximoFactura),
            }
            return row;
        });

        downloadPdf(title, headers, rows);
    }

    const renderTableFunds = () => {
        let data = currentTransactions.map((e) => {
            let row = {
                name: e.nombre,
                active: <Switch
                    val={e.nombre}
                    checked={activeFund.nombre}
                    disabled={activeFund.nombre === e.nombre}
                    handleChange={handleSetActiveFund}
                />
            }

            if (fundLimit) {
                row = {
                    ...row,
                    maxAmount: formatAmount(e.montoMaximo),
                    usedAmount: formatAmount(e.montoUsado),
                    availableAmount: formatAmount(e.montoDisponible),
                    updateAmount:
                        <>
                            <TextField
                                sx={{ maxWidth: 150 }}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMaxAmount.find(x => x.id === e.id) !== undefined ? newMaxAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxAmount({ id: e.id, amount: event.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => setMaxAmount(e.id)}
                            >Actualizar
                            </Button >
                        </>,
                }
            }

            if (minInvoiceFund) {
                row = {
                    ...row,
                    minInvoiceFundAmount: formatAmount(e.minimoFactura),
                    updateMinInvoiceFundAmount:
                        <>
                            <TextField
                                sx={{ maxWidth: 150 }}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMinInvoiceFundAmount.find(x => x.id === e.id) !== undefined ? newMinInvoiceFundAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxInvoiceFundAmount({ id: e.id, amount: event.target.value }, "min")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => SetMaxInvoiceFundAmount(e.id, "min")}
                            >Actualizar
                            </Button >
                        </>,
                }
            }

            if (maxInvoiceFund) {
                row = {
                    ...row,
                    maxInvoiceFundAmount: formatAmount(e.maximoFactura),
                    updateMaxInvoiceFundAmount:
                        <>
                            <TextField
                                sx={{ maxWidth: 150 }}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMaxInvoiceFundAmount.find(x => x.id === e.id) !== undefined ? newMaxInvoiceFundAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxInvoiceFundAmount({ id: e.id, amount: event.target.value }, "max")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => SetMaxInvoiceFundAmount(e.id, "max")}
                            >Actualizar
                            </Button >
                        </>,
                }
            }

            return row
        })
        return data
    }

    const rows = renderTableFunds()

    const renderHeadersFunds = () => {
        let headers = ['Nombre'];

        if (fundLimit) headers.push('Monto Disponible ($)', 'Monto Utilizado ($)', 'Monto Máximo ($)', 'Actualizar Monto Máximo ($)');
        if (minInvoiceFund) headers.push('Mínimo factura ($)', 'Actualizar mínimo factura ($)');
        if (maxInvoiceFund) headers.push('Máximo factura ($)', 'Actualizar máximo factura ($)');

        headers.push('activo');

        return headers;
    }

    const headersFunds = renderHeadersFunds();

    const renderLabelFunds = () => {

        let labels = ['name']

        if (fundLimit) labels.push('availableAmount', 'usedAmount', 'maxAmount', 'updateAmount');
        if (minInvoiceFund) labels.push('minInvoiceFundAmount', 'updateMinInvoiceFundAmount');
        if (maxInvoiceFund) labels.push('maxInvoiceFundAmount', 'updateMaxInvoiceFundAmount');

        labels.push('active');

        return labels;
    }

    const labelFunds = renderLabelFunds();

    const renderTablePayers = () => {
        let data = currentPayers.map((e) => {
            let row = {
                name: e.nombre
            }

            if (payerLimit) {
                row = {
                    ...row,
                    maxAmount: formatAmount(e.montoMaximo),
                    usedAmount: formatAmount(e.montoUsado),
                    availableAmount: formatAmount(e.montoDisponible),
                    updateAmount: <>
                        <TextField
                            sx={{ maxWidth: 150 }}
                            size='small'
                            variant="outlined"
                            color='primary'
                            value={newMaxAmountPayer.find(x => x.id === e.id) !== undefined ? newMaxAmountPayer.find(x => x.id === e.id).amount : ''}
                            onChange={(event) => handleAddNewMaxAmountPayer({ id: e.id, amount: event.target.value })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            variant='contained'
                            color='buttonColor'
                            size='small'
                            sx={{ marginLeft: 1 }}
                            onClick={() => setMaxAmountPayer(e.id)}
                        >Actualizar
                        </Button >
                    </>
                }
            }

            if (minInvoicePayer) {
                row = {
                    ...row,
                    minInvoicePayerAmount: formatAmount(e.minimoFactura),
                    updateMinInvoicePayerAmount:
                        <>
                            <TextField
                                sx={{ maxWidth: 150 }}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMinInvoicePayerAmount.find(x => x.id === e.id) !== undefined ? newMinInvoicePayerAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxInvoicePayerAmount({ id: e.id, amount: event.target.value }, "min")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => SetMaxInvoicePayerAmount(e.id, "min")}
                            >Actualizar
                            </Button >
                        </>,
                }
            }

            if (maxInvoicePayer) {
                row = {
                    ...row,
                    maxInvoicePayerAmount: formatAmount(e.maximoFactura),
                    updateMaxInvoicePayerAmount:
                        <>
                            <TextField
                                sx={{ maxWidth: 150 }}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMaxInvoicePayerAmount.find(x => x.id === e.id) !== undefined ? newMaxInvoicePayerAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxInvoicePayerAmount({ id: e.id, amount: event.target.value }, "max")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => SetMaxInvoicePayerAmount(e.id, "max")}
                            >Actualizar
                            </Button >
                        </>,
                }
            }

            return row
        })
        return data
    }

    const rowsPayers = renderTablePayers();

    const renderHeadersPayer = () => {
        let headers = ['Nombre'];

        if (payerLimit) headers.push('Monto Disponible ($)', 'Monto Utilizado ($)', 'Monto Máximo ($)', 'Actualizar Monto Máximo ($)');
        if (minInvoicePayer) headers.push('Mínimo factura ($)', 'Actualizar mínimo factura ($)');
        if (maxInvoicePayer) headers.push('Máximo factura ($)', 'Actualizar máximo factura ($)');

        headers.push('');

        return headers;
    }

    const headersPayers = renderHeadersPayer();

    const renderLabelPayers = () => {
        let labels = ['name']

        if (payerLimit) labels.push('availableAmount', 'usedAmount', 'maxAmount', 'updateAmount');
        if (minInvoicePayer) labels.push('minInvoicePayerAmount', 'updateMinInvoicePayerAmount');
        if (maxInvoicePayer) labels.push('maxInvoicePayerAmount', 'updateMaxInvoicePayerAmount');

        labels.push('');

        return labels;
    }

    const labelPayers = renderLabelPayers();

    // useEffect(() => {
    //     if (search) {
    //         setPageFunds(0);
    //         setCurrentTransactions(fundsData.filter((element) => {
    //             return element.nombre.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //                 || element.rut.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //                 || element.razonSocial.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //         }
    //         ));
    //     } else {
    //         setCurrentTransactions(fundsData);
    //     }
    // }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(fundsData);
    }, [fundsData]);

    useEffect(() => {
        if (searchPayer) {
            setPagePayers(0);
            setcurrentPayers(payersData.filter((element) => {
                return element.nombre.toLocaleLowerCase().includes(searchPayer.toLocaleLowerCase())
            }
            ));
        } else {
            setcurrentPayers(payersData);
        }
    }, [searchPayer]);

    useEffect(() => {
        setSearchPayer('');
        setcurrentPayers(payersData);
    }, [payersData]);


    return (
        <>
            {fundLimit || maxInvoiceFund || minInvoiceFund ?
                <>
                    <Box marginTop="20px">
                        <Typography variant='h5' fontWeight={600}>
                            Administrar límites diarios de Fondos
                        </Typography>
                        <Card className="table-container">
                            <Tables
                                rows={rows}
                                headers={headersFunds}
                                labels={labelFunds}
                            />
                        </Card>
                    </Box>
                    {/* <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                        <Paginations
                            onChange={(event, val) => { setPageFunds(val - 1) }}
                            count={Math.ceil(rows.length / 3)}
                        />
                    </Box> */}
                </>
                :
                <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                </Box>
            }
            {payerLimit || maxInvoicePayer || minInvoicePayer ?
                <>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            marginTop: "60px"
                        }}
                    >
                        <Grid item xs={12} className='buttons-container'>
                            <Button onClick={handleDownloadExcelPayers}
                                variant='contained'
                                color='buttonColor'
                            >
                                Descargar Excel
                            </Button>
                            <Button onClick={handleDownloadPdfPayers}
                                variant='contained'
                                color='buttonColor'>
                                Descargar PDF
                            </Button>
                            <SearchBar
                                search={searchPayer}
                                setSearch={setSearchPayer}
                            ></SearchBar>
                        </Grid>
                    </Grid>
                    <Box>
                        <Typography variant='h5' fontWeight={600}>
                            Administrar límites diarios de Pagadoras
                        </Typography>
                        <Card className="table-container">
                            <Tables
                                rows={rowsPayers.slice((pagePayers * 3), (pagePayers * 3) + 3)}
                                headers={headersPayers}
                                labels={labelPayers}
                            />
                        </Card>
                    </Box>
                    <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                        <Paginations
                            onChange={(event, val) => { setPagePayers(val - 1) }}
                            count={Math.ceil(rowsPayers.length / 3)}
                        />
                    </Box>
                </>
                :
                <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                </Box>
            }
        </>
    )
}