import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    common: {
      lighBlue: "#3760D9",
      blue: "#0F329B",
      pink: "#AC1A67",
      gray: "#949494",
      lightGray: "#E5E5E5",
      green: "#37D98B",
      red: "#EA597B",
    },
    primary: {
      main: "#fff",
      contrastText: "#0F329B",
    },
    secondary: {
      main: "#6D6D6D",
    },
    pagination: {
      main: "#3760D9",
      contrastText: "#fff",
    },
    buttonColor: {
      main: "#3760D9",
      contrastText: "#fff",
    },
    buttonHighlight: {
      main: "#EA597B",
      contrastText: "#fff",
    },
    buttonSecondary: {
      main: "#6c757d",
      contrastText: "#fff",
    },
    buttonOutlined: {
      main: "#fff",
      contrastText: "#fff",
    },
    menu: {
      main: "#171717",
      contrastText: "#fff",
    },
    action: {
      disabledBackground: "rgba(55, 96, 217, 0.5)",
      disabled: "rgba(255,255,255,0.8)",
    },
  },
  typography: {
    fontFamily: ["Roboto"],
    htmlFontSize: 18,
    useNextVariants: true,
    h6: {
      lineHeight: "150%",
    },
    body1: {
      lineHeight: "140%",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          background: "linear-gradient(0deg, #292929, #363636)",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 20px",
          borderRadius: "3px",
          boxShadow: "none",
        },
      },
    },
  },
});
