import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import logotipo from '../../assets/images/logotipo.png'
import axios from 'axios'
import moment from 'moment'

export default function RecuperarClave() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        hostService
    } = useAppContext();

    const [rut, setRut] = useState('');

    const handleChangeUsername = (event) => {
        let value = event.target.value.replace('-', '').replace('.', '');
        if (value.match(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/)) {
            value = value.replace(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/, '$1$2$3-$4');
        }
        
        setRut(value);
    }

    const handleSendRut = () => {
        axios.post(hostService + `servicios/conector.asmx/SolicitaRecuperacionClaveRut`, { rut: rut }, { withCredentials: true })
            .then(response => {
                if (response.data.d.tipo) {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Correo enviado');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "100vh" }}
        >
            <Grid item xs={6}>
                <Card className="nuevousuario-card">
                    <CardContent xs={4} className='card-content'>
                        <img className='logotipo' src={logotipo} alt="logo btrust" />
                        <Grid>
                            <Box
                                component='form'
                                className='form-container'
                            >
                                <Typography color="secondary" variant='inherit' align='justify' sx={{ margin: '20px 0px' }}>
                                Ingresa el Rut y te enviaremos un email para recuperar tu contraseña
                                </Typography>
                                <Typography color="primary" variant='h6' sx={{ marginTop: '20px' }}>
                                    Rut
                                </Typography>
                                <TextField
                                    sx={{ input: { color: '#fff' } }}
                                    color='primary'
                                    variant="outlined"
                                    className="textfield"
                                    value={rut}
                                    onChange={handleChangeUsername}
                                />
                            </Box>
                            <Box
                                className='buttons-container'
                            >
                                <Button onClick={() => handleSendRut()} variant='contained' color='buttonColor' sx={{ width: '180px', marginBottom: '10px' }}>
                                    <Typography>
                                        Enviar
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
                <Typography align='center'>
                    Plataforma de negocios
                </Typography>
                <Typography align='center'>
                    {moment().get().format("YYYY")} Copyright - btrust
                </Typography>
            </Grid>
        </Grid>
    )
}