import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Paginations from '../../../Components/Pagination/Paginations'
import Tables from '../../../Components/Tables/Table'

export default function ReprogrammingRecords({
    headers,
    labels,
    rows,
    openRecordsModal,
    handleOpenRecordsModal,
    recordHeaders,
    recordLabels,
    recordRows,
    search,
    setSearch,
    rescheduleRecordData,
    downloadExcel,
    downloadPdf
}) {
    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);

    const [pageModal, setPageModal] = useState(0);
    const [currentRowsModal, setCurrentRowsModal] = useState(recordRows);
    const [searchModal, setSearchModal] = useState('');


    const handleDownloadExcel = () => {
        let title = 'Historial de reprogramación';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                payer: object.payer,
                requestDate: object.requestDate,
                originalPayingDate: object.originalPayingDate,
                newPayingDate: object.newPayingDate,
                nBills: object.nBills,
                totalAmount: object.totalAmount,
                rate: object.rate
            })
            return dataRows;
        });

        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Historial de reprogramación';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                payer: object.payer,
                requestDate: object.requestDate,
                originalPayingDate: object.originalPayingDate,
                newPayingDate: object.newPayingDate,
                nBills: object.nBills,
                totalAmount: object.totalAmount,
                rate: object.rate
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    const handleDownloadExcelModal = () => {
        let title = 'Información sobre la reprogramación de pago';
        let headerRow = {};
        recordHeaders.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentRowsModal.map((object) => {
            dataRows.push({
                number: object.number,
                type: object.type,
                emitingRut: object.emitingRut,
                amount: object.amount
            })
            return dataRows;
        });

        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdfModal = () => {
        let title = 'Información sobre la reprogramación de pago';
        let headerRow = {};
        recordHeaders.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentRowsModal.map((object) => {
            dataRows.push({
                number: object.number,
                type: object.type,
                emitingRut: object.emitingRut,
                amount: object.amount
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.payer.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }
            ));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rescheduleRecordData]);

    useEffect(() => {
        if (searchModal) {
            setPageModal(0);
            setCurrentRowsModal(recordRows.filter((element) => {
                return element.number.toLocaleLowerCase().includes(searchModal.toLocaleLowerCase())
                    || element.type.toLocaleLowerCase().includes(searchModal.toLocaleLowerCase())
                    || element.emitingRut.toLocaleLowerCase().includes(searchModal.toLocaleLowerCase())
            }
            ));
        } else {
            setCurrentRowsModal(recordRows);
        }
    }, [searchModal]);

    useEffect(() => {
        setSearchModal('');
        setCurrentRowsModal(recordRows);
    }, [recordRows]);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Historial de reprogramación
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                headers={headers}
                                labels={labels}
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                withTotal={false}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length / 14)}
                />
            </Box>
            <Modal
                open={openRecordsModal}
                onClose={() => [handleOpenRecordsModal(), setPageModal(0)]}
            >
                <Box className='reschedule-record-modal'>
                    <Typography variant='h6' fontWeight={600} style={{ marginBottom: '20px' }}>
                        Información sobre la reprogramación de pago
                    </Typography>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={6}>
                            <Typography fontWeight={600} className="record-modal-users">
                                Información Usuario de E. Pagadora
                            </Typography>
                            <Box display="flex">
                                <Typography style={{ width: '45%' }} component='span'>Nombre Usuario:</Typography>
                                <Typography fontWeight={600} component='span'>{currentRowsModal.length > 0 ? currentRowsModal[0].payerUser : ""}</Typography>
                            </Box>
                            <Box display="flex">
                                <Typography style={{ width: '45%' }} component='span'>Rut Usuario:</Typography>
                                <Typography fontWeight={600} component='span'>{currentRowsModal.length > 0 ? currentRowsModal[0].payerRut : ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={600} className="record-modal-users">
                                Información Usuario del Comprador
                            </Typography>
                            <Box display="flex">
                                <Typography style={{ width: '45%' }} component='span'>Nombre Usuario:</Typography>
                                <Typography fontWeight={600} component='span'>{currentRowsModal.length > 0 ? currentRowsModal[0].buyerUser : ""}</Typography>
                            </Box>
                            <Box display="flex">
                                <Typography style={{ width: '45%' }} component='span'>Rut Usuario:</Typography>
                                <Typography fontWeight={600} component='span'>{currentRowsModal.length > 0 ? currentRowsModal[0].buyerRut : ""}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='buttons-container' style={{ marginTop: '50px' }}>
                        <Button onClick={handleDownloadExcelModal}
                            variant='contained'
                            color='buttonColor'
                        >
                            Descargar Excel
                        </Button>
                        <Button onClick={handleDownloadPdfModal}
                            variant='contained'
                            color='buttonColor'
                            >
                            Descargar PDF
                        </Button>
                        <SearchBar
                            search={searchModal}
                            setSearch={setSearchModal}
                        ></SearchBar>
                    </Grid>
                    <Box marginTop="30px">
                        <Tables
                            headers={recordHeaders}
                            labels={recordLabels}
                            rows={currentRowsModal.slice((pageModal * 5), (pageModal * 5) + 5)}
                        />
                        <Grid container justifyContent="end" style={{ marginTop: "20px" }}>
                            <Paginations
                                onChange={(event, val) => { setPageModal(val - 1) }}
                                count={Math.ceil(currentRowsModal.length / 5)}
                            />
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}