import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './table.css'

import Paper from '@mui/material/Paper';



export default function Tables({ rows, headers, labels, type, withTotal, minWidth = 650 }) {
    
    return (
        <TableContainer className='table standard-table'>
            <Table sx={{ minWidth: minWidth }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: "2px 0px"}}>{headers[0]}</TableCell>
                        {headers.slice(1).map((e) => {
                            return <TableCell style={{ padding: "2px 0px" }} key={e} align="right">{e}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={row + i}
                            className={withTotal ? 'with-total' : null}
                        >
                            <TableCell style={{ padding: "9px 0px"}}>{row[labels[0]]}</TableCell>
                            {labels.slice(1).map((e, i) => {
                                return <TableCell style={{ padding: "9px 0px"}} key={e} align="right">
                                    <span style={{ color: (type === 'blueNumbers' && (row[e] > 0 || row[e] !== '0')) ? '#A3BAFF' : null }}>
                                        {row[e]}
                                    </span>
                                </TableCell>
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}