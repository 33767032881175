import React, { useState, useEffect } from "react";
import { useAppContext } from '../../libs/contextlib'
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button'
//import Icon from '@mui/material/Icon'
import moment from 'moment'

import "./transactions.css";
import payingCompanyLineChartData from '../../mockData/Transacciones/payingCompanyLineChartData'
import segementedChartData from '../../mockData/Transacciones/segmentedChartData'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography'


// Views
import Manage from './TransaccionesViews/Manage'
import DayTransactions from "./TransaccionesViews/DayTransactions";
import AllTransactions from "./TransaccionesViews/AllTransactions";
import Balance from "./TransaccionesViews/Balance";
import Certificados from "./TransaccionesViews/Certificados";
import PayingCompanies from "./TransaccionesViews/PayingCompanies";
import PayingSummary from "./TransaccionesViews/PayingSummary";
import MarketTransactions from "./TransaccionesViews/MarketTransactions";
import BulkLoad from "./TransaccionesViews/BulkLoad";

export default function Transacciones() {
	const {
		transactionView,
		setTransactionView,
		setAlertMessageOpen,
		setAlertMessageTitle,
		setAlertMessageDescription,
		setAlertMessageType,
		formatAmount,
		formatFee,
		formatFeeComplete,
		hostService,
		downloadExcel,
		downloadPdf,
		offerLimit,
		fundLimit,
		payerLimit

	} = useAppContext();

	const [sinceValue, setSinceValue] = useState(moment().get());
	const [untilValue, setUntilValue] = useState(moment().get());
	const [payingCompany, setPayingCompany] = useState('');
	const [payingCompanyData, setPayingCompanyData] = useState({});
	const [payingCompaniesData, setPayingCompaniesData] = useState([]);
	const [transaccionesTableData, setTransaccionesTableData] = useState([]);
	const [balanceData, setBalanceData] = useState([]);
	const [balanceInfo, setBalanceInfo] = useState([]);
	const [summaryData, setSummaryData] = useState([]);
	const [summaryInfo, setSummaryInfo] = useState([]);
	const [manageData, setManageData] = useState([]);
	const [maxAmount, setMaxAmount] = useState(0);
	const [search, setSearch] = useState('');
	const [modalTransactionsOpen, setModalTransactionsOpen] = useState(false);
	const [infoId, setInfoId] = useState();
	const [actualizarOfertas, setActualizarOfertas] = useState(false);
	const [marketTransactionsTableData, setMarketTransactionsTableData] = useState([])

	const emptyChart = [{}, {}];
	const emptySegmentedChart = [{ amount: 0 }];

	const [openModal, setOpenModal] = useState(false);
	const [openModalSummary, setOpenModalSummary] = useState(false);

	const handleToggleModalTransactions = () => {
		setModalTransactionsOpen(!modalTransactionsOpen);
	}

	const handleOpenModal = (pagadoraId) => {
		axios.post(hostService + `servicios/conector.asmx/GetFacturasCarteraInfo`, { pagadoraId: pagadoraId }, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else if (response.data.d !== 'error') {
					var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.facturas.map((object) => data.push({
						id: object.idOperacion,
						nombrePagadora: object.nombrePagadora,
						rutPagadora: object.rutPagadora,
						nombreFondo: object.nombreFondo,
						pagadoraId: object.pagadoraId,
						folio: object.folio,
						tipoFolio: object.tipoFolio,
						montoOriginal: object.montoOriginal,
						montoCedible: object.montoCedible,
						montoFinanciable: object.montoFinanciable,
						rutEmisor: object.rutEmisor,
						fechaCompra: object.fechaCompra,
						fechaPago: object.fechaPago,
						status: object.estado
					}));

					setBalanceInfo(data);

				} else {
					setAlertMessageOpen(true);
					setAlertMessageTitle('Ocurrió un error inesperado');
					setAlertMessageDescription('Inténtalo nuevamente');
					setAlertMessageType('error');
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
		setOpenModal(!openModal);
	}

	const handleOpenModalSummary = (pagadoraId, compradorId) => {
		axios.post(hostService + `servicios/Compradores.asmx/GetResumenPagadorasInfo`, { idPagadora: pagadoraId }, { withCredentials: true })
			.then(response => {
				let respuesta = JSON.parse(response.data.d)
				if (respuesta.status === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else if (respuesta.status === 'error') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('Ocurrió un error inesperado');
					setAlertMessageDescription('Inténtalo nuevamente');
					setAlertMessageType('error');
				} else {
					const data = [];
					respuesta.data.data.map((object) => data.push({
						averageRate: object.idComprador === compradorId ? <Typography sx={{ fontWeight: 'bold' }} color="#92adff">{formatFee(object.tasaPromedio)}</Typography> : formatFee(object.tasaPromedio),
						availableAmount: object.montoDisponible === null ? "Sin límite" : object.idComprador === compradorId ? <Typography sx={{ fontWeight: 'bold' }} color="#92adff">{formatAmount(object.montoDisponible)}</Typography> : formatAmount(object.montoDisponible)
					}));

					setSummaryInfo(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
		setOpenModalSummary(!openModalSummary);
	}

	//   Transactions table
	const transactionTableHeaders = ['Id', 'Empresa', 'RUT', 'Fecha de operación', 'Fecha de cobro', 'Monto cedido ($)', 'Monto financiado ($)', 'Comisión + IVA ($)', 'Interés ganado ($)', 'Monto pagado ($)', 'Info']
	const transcationTableLabels = ['id', 'company', 'rut', 'date', 'paymentDate', 'amountCeded', 'amountBought', 'comission', 'difference', 'deposit', 'info']


	//   Balance table
	const balanceTableHeaders = ['Nombre Empresa', 'Total Facturas', 'Monto total ($)', 'Facturas en mora', 'Monto en mora ($)', '']
	const balanceTableLabels = ['name', 'quantity', 'totalAmount', 'quantityArrear', 'totalAmountArrear', 'button']


	//   Summary table
	const summaryTableHeaders = ['Nombre pagadora', 'Mi tasa promedio (%)', 'Mi monto disponible ($)', 'Mercado']
	const summaryTableLabels = ['payingName', 'averageRate', 'availableAmount', 'info']

	const marketTransactionsTableHeaders = ['Nombre', 'Última (%)', 'Volumen ($ M)', 'Menor (%)', 'Volumen ($ M)', 'Promedio (%)', 'Volumen total ($ M)']
	const marketTransactionsTableLabels = ['payerName', 'last', 'lastVolume', 'min', 'minVolume', 'average', 'totalVolume']

	const renderBalanceTable = () => {
		let data = balanceData.map((e) => {
			let row = {
				name: <Button onClick={() => [setPayingCompany(e.nombrePagadora), setTransactionView('payingCompanies')]}>{e.nombrePagadora}</Button>,
				quantity: e.cantidadFacturas,
				totalAmount: formatAmount(e.montoTotal),
				quantityArrear: e.cantidadFacturasEnMora,
				totalAmountArrear: formatAmount(e.montoTotalMora),
				button: <Button onClick={() => handleOpenModal(e.pagadoraId)} variant='contained' color="buttonColor">Ver más</Button>
			}
			return row
		})
		return data
	}

	const balanceRows = renderBalanceTable()
	//   Balance table

	// Balance info table
	const balanceInfoTableHeaders = ['Id', 'Nombre Pagadora', 'RUT Pagadora', 'Folio', 'Tipo de Documento', 'Rut del Emisor', 'Monto Original', 'Monto Cedido', 'Monto Financiado', 'Fecha de Compra', 'Fecha de Pago', 'Estado']
	const balanceInfoTableLabels = ['id', 'payerName', 'payerRut', 'number', 'docType', 'emitingRut', 'originalAmount', 'cededAmount', 'financeableAmount', 'buyDate', 'paymentDate', 'status']

	const renderBalanceInfoData = () => {
		let data = balanceInfo.map((e) => {
			let row = {
				id: e.id,
				payerName: e.nombrePagadora,
				payerRut: e.rutPagadora,
				number: e.folio,
				docType: e.tipoFolio,
				emitingRut: e.rutEmisor,
				originalAmount: formatAmount(e.montoOriginal),
				cededAmount: formatAmount(e.montoCedible),
				financeableAmount: formatAmount(e.montoFinanciable),
				buyDate: e.fechaCompra,
				paymentDate: e.fechaPago,
				status: e.status
			}
			return row
		})
		return data
	}

	const balanceInfoRows = renderBalanceInfoData()
	// Balance info table

	useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/PagadorasDelComprador`, {}, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else {
					var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.pagadoras.map((object) => data.push({ id: object.idPagadora, nombre: object.nombrePagadora, montoMaximo: object.montoMaximo }));
					setPayingCompaniesData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, []);

	useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/GetTransacciones`, { desde: sinceValue.format('YYYY-MM-DD'), hasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else {
					var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.transacciones.map((object) => data.push({
						id: object.idTransaccion,
						company: object.nombrePagadora,
						rut: object.rutPagadora,
						date: object.fecha,
						emissionDate: object.fechaEmision,
						paymentDate: object.fechaCobro,
						amountCeded: formatAmount(object.montoCedible),
						amountBought: formatAmount(object.montoComprado),
						comission: formatAmount(object.comisionConIva),
						difference: formatAmount(object.interesGanado),
						deposit: formatAmount(object.montoDepositar),
						razonSocialEmisor: object.razonSocialEmisor,
						rutEmisor: object.rutEmisor,
						tipoFolio: object.tipoFolio,
						folio: object.folio,
						montoOriginal: formatAmount(object.montoOriginal),
						razonSocialCesionario: object.razonSocialCesionario,
						rutCesionario: object.rutCesionario,
						tasa: formatFeeComplete((object.tasa)),
						info: <Button onClick={() => [handleToggleModalTransactions(), setInfoId(object.idTransaccion)]}><InfoOutlinedIcon /></Button>
					}));
					setTransaccionesTableData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, [sinceValue, untilValue]);

	useEffect(() => {
		axios.post(hostService + `servicios/conector.asmx/GetFacturasCartera`, {}, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else {
					var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.facturas.map((object) => data.push({
						nombrePagadora: object.nombrePagadora,
						nombreFondo: object.nombreFondo,
						pagadoraId: object.pagadoraId,
						montoTotal: object.montoTotal,
						cantidadFacturas: object.cantidadFacturas,
						cantidadFacturasEnMora: object.cantidadFacturasEnMora,
						montoTotalMora: object.montoTotalMora
					}));
					setBalanceData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, []);


	useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/GetTransaccionesDelMercado`, { desde: sinceValue.format('YYYY-MM-DD'), hasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
			.then(response => {
				let respuesta = JSON.parse(response.data.d)
				if (respuesta.status === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else if (respuesta.status === 'error') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('Ocurrió un error inesperado');
					setAlertMessageDescription('Inténtalo nuevamente');
					setAlertMessageType('error');
				} else {
					const data = [];
					respuesta.data.data.map((object) => data.push({
						payerId: object.idPagadora,
						payerName: object.nombrePagadora,
						last: formatFee(object.ultimaTasa),
						lastVolume: ((object.ultimoMonto.replace(',', '.')) / 1000000).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2, }),
						min: formatFee(object.menorTasa),
						minVolume: ((object.montoMenorTasa.replace(',', '.')) / 1000000).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2, }),
						average: formatFee(object.promedioTasa),
						totalVolume: ((object.montoTotal.replace(',', '.')) / 1000000).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2, })
					}));

					setMarketTransactionsTableData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, [sinceValue, untilValue]);

	return (
		<Container maxWidth="xxl">
			<Grid container className="transaction-container">
				{transactionView === 'manage' ?
					<Manage />
					: transactionView === 'dayTransactions' ?
						<DayTransactions
							rows={transaccionesTableData}
							headers={transactionTableHeaders}
							labels={transcationTableLabels}
						/>
						: transactionView === 'allTransactions' ?
							<AllTransactions
								rows={transaccionesTableData}
								headers={transactionTableHeaders}
								labels={transcationTableLabels}
								modalTransactionsOpen={modalTransactionsOpen}
								handleToggleModalTransactions={handleToggleModalTransactions}
								infoId={infoId}
								sinceValue={sinceValue}
								setSinceValue={setSinceValue}
								untilValue={untilValue}
								setUntilValue={setUntilValue}
								search={search}
								setSearch={setSearch}
								downloadExcel={downloadExcel}
								downloadPdf={downloadPdf}
							/>
							: transactionView === 'marketTransactions' ?
								<MarketTransactions
									rows={marketTransactionsTableData}
									headers={marketTransactionsTableHeaders}
									labels={marketTransactionsTableLabels}
									setMarketTransactionsTableData={setMarketTransactionsTableData}
									payingCompaniesData={payingCompaniesData}
									sinceValue={sinceValue}
									setSinceValue={setSinceValue}
									untilValue={untilValue}
									setUntilValue={setUntilValue}
								/>
								: transactionView === 'balance' ?
									<Balance
										headers={balanceTableHeaders}
										labels={balanceTableLabels}
										rows={balanceRows}
										openModal={openModal}
										handleOpenModal={handleOpenModal}
										balanceInfoHeaders={balanceInfoTableHeaders}
										balanceInfoLabels={balanceInfoTableLabels}
										balanceInfoRows={balanceInfoRows}
										setOpenModal={setOpenModal}
										balanceData={balanceData}
										search={search}
										setSearch={setSearch}
										downloadExcel={downloadExcel}
										downloadPdf={downloadPdf}
									/>
									: transactionView === 'payingCompanies' ?
										<PayingCompanies
											payingCompany={payingCompany}
											offerLimit={offerLimit}
											payerLimit={payerLimit}
											setPayingCompany={setPayingCompany}
											payingCompaniesData={payingCompaniesData}
											setPayingCompaniesData={setPayingCompaniesData}
											payingCompanyData={payingCompanyData}
											setPayingCompanyData={setPayingCompanyData}
											maxAmount={maxAmount}
											actualizarOfertas={actualizarOfertas}
											setActualizarOfertas={setActualizarOfertas}
											setMaxAmount={setMaxAmount}
											chartData={payingCompany !== '' ? payingCompanyLineChartData : emptyChart}
											segmentedChartData={payingCompany !== '' ? segementedChartData : emptySegmentedChart}
										/>
										: transactionView === 'payingSummary' ?
											<PayingSummary
												headers={summaryTableHeaders}
												labels={summaryTableLabels}
												rows={summaryData}
												search={search}
												setSearch={setSearch}
												rowsInfo={summaryInfo}
												openModalSummary={openModalSummary}
												setOpenModalSummary={setOpenModalSummary}
												downloadExcel={downloadExcel}
												downloadPdf={downloadPdf}
												setPayingCompany={setPayingCompany}
												payingCompaniesData={payingCompaniesData}
												setPayingCompaniesData={setPayingCompaniesData}
												payingCompanyData={payingCompanyData}
												setPayingCompanyData={setPayingCompanyData}
												maxAmount={maxAmount}
												setMaxAmount={setMaxAmount}
												chartData={payingCompany !== '' ? payingCompanyLineChartData : emptyChart}
												segmentedChartData={payingCompany !== '' ? segementedChartData : emptySegmentedChart}
											/>
											: transactionView === 'certificates' ?
												<Certificados />
												:
												transactionView === 'bulkLoad' ?
													<BulkLoad /> :
													null
				}
			</Grid>
		</Container>
	);
}
