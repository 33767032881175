import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import logo from '../../assets/images/logotipo.png'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import './menu.css'

export default function ResponsiveAppBar({
    isActive
}) {
    const {
        activeFund,
        setActiveFund,
        setFundView,
        setOfferLimit,
        setFundLimit,
        maxTotalFund,
        setMaxTotalFund,
        setPayerLimit,
        setMaxInvoiceFund,
        setMinInvoiceFund,
        setMaxInvoicePayer,
        setMinInvoicePayer,
        setTransactionView,
        customSchedule,
        setCustomSchedule,
        openingTime,
        setOpeningTime,
        closingTime,
        setClosingTime,
        openingTimeBtrust,
        setOpeningTimeBtrust,
        closingTimeBtrust,
        setClosingTimeBtrust,
        updateTimeIndicator,
        rejectedPayments,
        setRejectedPayments,
        setPagosView,
        setCobrosView,
        setMercadoView,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        hostService,
        rechazados,
        rolComprador,
        setRolComprador
    } = useAppContext();
    const [fondosAnchor, setFondosAnchor] = useState(null);
    const [transaccionesAnchor, setTransaccionesAnchor] = useState(null);
    const [mercadoAnchor, setMercadoAnchor] = useState(null);
    const [pagosAnchor, setPagosAnchor] = useState(null);
    const [cobrosAnchor, setCobrosAnchor] = useState(null);
    const [perfilAnchor, setPerfilAnchor] = useState(null);
    let navigate = useNavigate();

    const handleOpenFondos = (event) => {
        setFondosAnchor(event.currentTarget);
    };
    const handleCloseFondos = (event) => {
        setFondosAnchor(null);
    };

    const handleOpenTransacciones = (event) => {
        setTransaccionesAnchor(event.currentTarget);
    };
    const handleCloseTransacciones = (event) => {
        setTransaccionesAnchor(null);
    };

    const handleOpenMercado = (event) => {
        setMercadoAnchor(event.currentTarget);
    };
    const handleCloseMercado = (event) => {
        setMercadoAnchor(null);
    };

    const handleOpenPagos = (event) => {
        setPagosAnchor(event.currentTarget);
    };
    const handleClosePagos = (event) => {
        setPagosAnchor(null);
    };

    const handleOpenCobros = (event) => {
        setCobrosAnchor(event.currentTarget);
    };
    const handleCloseCobros = (event) => {
        setCobrosAnchor(null);
    };

    const handleOpenPerfil = (event) => {
        setPerfilAnchor(event.currentTarget);
    };
    const handleClosePerfil = (event) => {
        setPerfilAnchor(null);
    };

    const handleLogOut = () => {
        axios.post(hostService + `servicios/conector.asmx/LogOut`, {}, { withCredentials: true });
        navigate('/login');
    }

    const stringTimeToDate = (stringTime) => {
        if (stringTime.trim() !== "") {
            const [horasStr, minutosStr, segundosStr] = stringTime.split(':');

            const fechaTiempo = new Date();
            fechaTiempo.setHours(parseInt(horasStr, 10));
            fechaTiempo.setMinutes(parseInt(minutosStr, 10));
            fechaTiempo.setSeconds(parseInt(segundosStr, 10));

            return fechaTiempo;
        } else {
            return new Date();
        }
    }

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetFondoActivo`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setActiveFund({ id: respuesta.fondos[0].idFondo, nombre: respuesta.fondos[0].nombreFondo });
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, []);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetRestricciones`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setFundLimit(respuesta.LimiteFondo === "True");
                    setPayerLimit(respuesta.LimitePagadora === "True");
                    setOfferLimit(respuesta.LimiteOferta === "True");
                    setMaxInvoiceFund(respuesta.MaximoFacturaFondo === "True");
                    setMinInvoiceFund(respuesta.MinimoFacturaFondo === "True");
                    setMaxInvoicePayer(respuesta.MaximoFacturaPagadora === "True");
                    setMinInvoicePayer(respuesta.MinimoFacturaPagadora === "True");
                    setMaxTotalFund(respuesta.MaximoTotal === "True");
                    setRolComprador(respuesta.Rol);
                    setCustomSchedule(respuesta.HorarioPersonalizado === "True");
                    setOpeningTime(stringTimeToDate(respuesta.HorarioApertura));
                    setClosingTime(stringTimeToDate(respuesta.HorarioCierre));
                    setOpeningTimeBtrust(stringTimeToDate(respuesta.HorarioAperturaBtrust));
                    setClosingTimeBtrust(stringTimeToDate(respuesta.HorarioCierreBtrust));
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [updateTimeIndicator]);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetOperacionesRechazadas`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    if (respuesta.length > 0) setRejectedPayments(true);
                    else setRejectedPayments(false);
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [rechazados]);

    let now = new Date();
    const abierto = customSchedule ? (openingTime < now && openingTimeBtrust < now && closingTime > now && closingTimeBtrust > now)
        : (openingTimeBtrust < now && closingTimeBtrust > now);

    return (
        <AppBar position="fixed" color='menu'>
            <Container maxWidth="xl">
                <Toolbar className='toolbar'>
                    <img className='menu-logo' src={logo} alt="btrust" />
                    <Box className='appbar-container'>
                        <Button onClick={() => navigate('/home')}>
                            <span className={isActive === '/' ? 'activebtn' : null}>
                                Inicio
                            </span>
                        </Button>
                        <div>
                            <Button
                                onClick={handleOpenFondos}
                                aria-controls='fondos'
                            >
                                <span className={isActive === 'fondos' ? 'activebtn' : null}>
                                    Fondos
                                </span>
                            </Button>
                            <Menu
                                id='fondos'
                                open={Boolean(fondosAnchor)}
                                anchorEl={fondosAnchor}
                                onClose={handleCloseFondos}
                                PopoverClasses={{ paper: 'dropdown' }}
                            >
                                <MenuItem onClick={() => { setFundView('dailyLimits'); navigate('/fondos'); handleCloseFondos() }}>
                                    Administrar límites diarios
                                </MenuItem>
                                {maxTotalFund ?
                                    <MenuItem onClick={() => { setFundView('totalLimits'); navigate('/fondos'); handleCloseFondos() }}>
                                        Administrar límites totales
                                    </MenuItem>
                                    :
                                    null
                                }
                                {customSchedule ?
                                    <MenuItem onClick={() => { setFundView('schedules'); navigate('/fondos'); handleCloseFondos() }}>
                                        Administrar horarios
                                    </MenuItem>
                                    :
                                    null
                                }
                                <MenuItem onClick={() => { setFundView('fundData'); navigate('/fondos'); handleCloseFondos() }}>
                                    Datos fondos
                                </MenuItem>
                            </Menu>
                        </div>
                        {
                            (rolComprador !== 'analistaComprador') ?
                                <div>
                                    <Button
                                        onClick={handleOpenTransacciones}
                                        aria-controls='transacciones'
                                    >
                                        <span className={isActive === 'transacciones' ? 'activebtn' : null}>
                                            Transacciones
                                        </span>
                                    </Button>
                                    <Menu
                                        id='transacciones'
                                        open={Boolean(transaccionesAnchor)}
                                        anchorEl={transaccionesAnchor}
                                        onClose={handleCloseTransacciones}
                                        PopoverClasses={{ paper: 'dropdown' }}
                                    >
                                        <MenuItem onClick={() => { setTransactionView('allTransactions'); navigate('/transacciones'); handleCloseTransacciones() }}>
                                            Transacciones históricas
                                        </MenuItem>
                                        <MenuItem onClick={() => { setTransactionView('balance'); navigate('/transacciones'); handleCloseTransacciones() }}>
                                            Saldos
                                        </MenuItem>
                                        <MenuItem onClick={() => { setTransactionView('payingCompanies'); navigate('/transacciones'); handleCloseTransacciones() }}>
                                            Empresas pagadoras
                                        </MenuItem>
                                        <MenuItem onClick={() => { setTransactionView('certificates'); navigate('/transacciones'); handleCloseTransacciones() }}>
                                            Certificados
                                        </MenuItem>
                                        <MenuItem onClick={() => { setTransactionView('bulkLoad'); navigate('/transacciones'); handleCloseTransacciones() }}>
                                            Carga masiva
                                        </MenuItem>
                                    </Menu>
                                </div>
                                : null
                        }
                        <div>
                            <Button
                                onClick={handleOpenMercado}
                                aria-controls='mercado'
                            >
                                <span className={isActive === 'mercado' ? 'activebtn' : null}>
                                    Mercado
                                </span>
                            </Button>
                            <Menu
                                id='mercado'
                                open={Boolean(mercadoAnchor)}
                                anchorEl={mercadoAnchor}
                                onClose={handleCloseMercado}
                                PopoverClasses={{ paper: 'dropdown' }}
                            >
                                <MenuItem onClick={() => { setMercadoView('lastTransactions'); navigate('/mercado'); handleCloseMercado() }}>
                                    Ofertas y últimas transacciones
                                </MenuItem>
                                <MenuItem onClick={() => { setMercadoView('marketTransactions'); navigate('/mercado'); handleCloseMercado() }}>
                                    Transacciones del mercado
                                </MenuItem>
                            </Menu>
                        </div>
                        {
                            (rolComprador !== 'analistaComprador') ?
                                <div>
                                    <Button
                                        onClick={handleOpenPagos}
                                        aria-controls='pagos'
                                    >
                                        <span className={isActive === 'pagos' ? 'activebtn' : null}>
                                            Pagos
                                        </span>
                                    </Button>
                                    <Menu
                                        id='pagos'
                                        open={Boolean(pagosAnchor)}
                                        anchorEl={pagosAnchor}
                                        onClose={handleClosePagos}
                                        PopoverClasses={{ paper: 'dropdown' }}
                                    >
                                        <MenuItem onClick={() => { setPagosView('downloadarchive'); navigate('/pagos'); handleClosePagos() }}>
                                            Verificación y pagos
                                        </MenuItem>
                                        <MenuItem onClick={() => { setPagosView('donepayments'); navigate('/pagos'); handleClosePagos() }}>
                                            Informar pagos realizados
                                        </MenuItem>
                                        <MenuItem onClick={() => { setPagosView('downloadrecords'); navigate('/pagos'); handleClosePagos() }}>
                                            Histórico de descargas
                                        </MenuItem>
                                        {rejectedPayments ?
                                            <MenuItem onClick={() => { setPagosView('rejectedpayments'); navigate('/pagos'); handleClosePagos() }}>
                                                Pagos rechazados
                                            </MenuItem>
                                            : null
                                        }
                                    </Menu>
                                </div>
                                : null
                        }
                        {
                            (rolComprador !== 'analistaComprador') ?
                                <div>
                                    <Button
                                        onClick={handleOpenCobros}
                                        aria-controls='cobros'
                                    >
                                        <span className={isActive === 'cobros' ? 'activebtn' : null}>
                                            Cobros
                                        </span>
                                    </Button>
                                    <Menu
                                        id='cobros'
                                        open={Boolean(cobrosAnchor)}
                                        anchorEl={cobrosAnchor}
                                        onClose={handleCloseCobros}
                                        PopoverClasses={{ paper: 'dropdown' }}
                                    >
                                        <MenuItem onClick={() => { setCobrosView('collectioncalendar'); navigate('/cobros'); handleCloseCobros() }}>
                                            Calendario de recaudación
                                        </MenuItem>
                                        <MenuItem onClick={() => { setCobrosView('delinquentOperations'); navigate('/cobros'); handleCloseCobros() }}>
                                            Operaciones en mora
                                        </MenuItem>
                                        <MenuItem onClick={() => { setCobrosView('collectedOperations'); navigate('/cobros'); handleCloseCobros() }}>
                                            Operaciones recaudadas
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => { setCobrosView('acceptreprogramming'); navigate('/cobros') }}>
                                    Aceptar reprogramaciones
                                </MenuItem>
                                <MenuItem onClick={() => { setCobrosView('reprogrammingrecords'); navigate('/cobros') }}>
                                    Historial de reprogramación
                                </MenuItem> */}
                                    </Menu>
                                </div>
                                : null
                        }

                        <div>
                            <Button
                                onClick={handleOpenPerfil}
                                aria-controls='perfil'
                            >
                                Perfil
                            </Button>
                            <Menu
                                id='perfil'
                                open={Boolean(perfilAnchor)}
                                anchorEl={perfilAnchor}
                                onClose={handleClosePerfil}
                                PopoverClasses={{ paper: 'dropdown' }}
                            >
                                <MenuItem onClick={() => { navigate('/perfil'); handleClosePerfil() }}>
                                    Mi perfil
                                </MenuItem>
                                <MenuItem onClick={() => handleLogOut()}>
                                    Cerrar Sesión
                                </MenuItem>
                            </Menu>
                        </div>
                        <Box padding="5px 10px" border="solid 1px #fff" borderRadius="5px">
                            <Typography>
                                Fondo Activo: <span style={{ textTransform: "Capitalize" }}>{activeFund.nombre}</span>
                            </Typography>
                        </Box>
                        <Box padding="5px 10px" margin={"0px 10px"} border="solid 1px #fff" borderRadius="5px" sx={{ display: 'flex' }} >
                            <Typography>
                                {abierto ?
                                    "Abierto"
                                    :
                                    "Cerrado"
                                }
                            </Typography>
                            <CircleIcon sx={{ color: abierto ? 'green' : 'red', marginLeft: '5px' }}></CircleIcon>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};