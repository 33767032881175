import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppContext } from '../../libs/contextlib';

import './ratetables.css'

export default function RateTables ({
    rows
}) {
    const {
        formatAmount
    } = useAppContext();

    return (
        <TableContainer className='table'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Ultima (%)</TableCell>
                    <TableCell align="right">Monto ($ M)</TableCell>
                    <TableCell align="right">Mayor (%)</TableCell>
                    <TableCell align="right">Menor (%)</TableCell>
                    <TableCell align="right">Promedio (%)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    const last = <span>{row.last}</span>
                    const amount = <span>{((row.amount.replace(',', '.'))/1000000).toFixed(2).replace('.', ',').replace('-', '')}</span>
                    const max = <span>{row.max}</span>
                    const min = <span>{row.min}</span>
                    const avrg = <span>{row.avrg}</span>
                    return (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{row.name}</TableCell>
                            <TableCell align="right">{last}</TableCell>
                            <TableCell align="right">{amount}</TableCell>
                            <TableCell align="right">{max}</TableCell>
                            <TableCell align="right">{min}</TableCell>
                            <TableCell align="right">{avrg}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
            </Table>
        </TableContainer>
    )
}