import React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FundInfo from '../../Components/FundInfo/FundInfo'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export default function DatosFondos ({
    fundsData,
    reviewingFund,
    setReviewingFund,
    formatAmount,
    activeFund,
    handleSetActiveFund
}) {

    const handleReviewingFund = (event) => {
        setReviewingFund(event.target.value);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h5' fontWeight={700}>
                    Datos fondos
                </Typography>
                <FormControl sx={{width: '15vw', marginTop: '20px'}}>
                        <InputLabel className="labels">Fondo</InputLabel>
                        <Select
                            className="filters-select"
                            value={reviewingFund}
                            label="Fondo"
                            onChange={handleReviewingFund}
                        >
                            {fundsData.map((e) => 
                                <MenuItem key={e.id} value={e.nombre}>{e.nombre}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
            </Grid>
            <Grid item xs={7} sx={{margin: '40px auto 0 auto'}}>
                <Card sx={{padding: '20px'}}>
                    <FundInfo
                        fundsData={fundsData}
                        reviewingFund={reviewingFund}
                        formatAmount={formatAmount}
                    />
                    <Box display='flex' justifyContent='end' marginTop='10px'>
                        <Button 
                            variant="contained" 
                            color='buttonColor' 
                            disabled={ reviewingFund === activeFund.nombre }
                            onClick={() => handleSetActiveFund(reviewingFund)}
                            >
                            <Typography>
                                {reviewingFund === activeFund.nombre ? "Fondo activo" : "Activar fondo"}
                            </Typography>
                        </Button>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    )
}