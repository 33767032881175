import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import './fundinfo.css'

export default function FundInfo ({
    fundsData,
    reviewingFund,
    formatAmount
}) {

    const firstRow = ['Nombre del fondo', 'Rut', 'Persona de referencia ante Sii', 'Banco de Recaudación']
    const firstRowVariables = ['name', 'rut', 'siiContact', 'bank']

    const secondRow = ['Razón social', 'Dirección', 'Email para notificaciones de Sii', 'Cuenta']
    const secondRowVariables = ['social', 'address', 'siiEmailNotifications', 'account']

    const renderTable = () => {
        let data = fundsData.map((e) => {
         let row = {
             name: e.nombre,
             social: e.razonSocial,
             rut: e.rut,
             bank: e.banco,
             account: e.cuenta,
             notificationAmount: formatAmount(e.montoAlerta),
             address: e.direccion,
             siiContact: e.personaReferenciaSii,
             custody: e.custodia,
             siiEmailNotifications: e.emailNotificacionesSii,
             password: e.clave
         }
         return row
        })
        return data
    }
 
    const rows = renderTable()

    const fundInfoReviewing = rows.filter(object => object.name === reviewingFund);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                {firstRow.map((e,i) => {
                    return (
                        <div key={e}>
                            <Typography className='info-titles' color="primary">
                                {e}
                            </Typography>
                            <Box className='info-boxes'>
                                <Typography color="primary" fontWeight={600}>
                                    { fundInfoReviewing.length > 0 ? fundInfoReviewing[0][firstRowVariables[i]] : ""}
                                </Typography>
                            </Box>
                        </div>
                    )
                })}
            </Grid>
            <Grid item xs={6}>
                {secondRow.map((e,i) => {
                    return (
                        <div key={e}>
                            <Typography className='info-titles' color="primary">
                                {e}
                            </Typography>
                            <Box className='info-boxes'>
                                <Typography color="primary" fontWeight={600}>
                                    { fundInfoReviewing.length > 0 ? fundInfoReviewing[0][secondRowVariables[i]] : ""}
                                </Typography>
                            </Box>
                        </div>
                    )
                })}
            </Grid>
        </Grid>
    )
}