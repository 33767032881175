import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import axios from 'axios'

import RateTables from "../../../Components/RateTables/RateTables";

import uploadIcon from "../../../assets/images/upload-icon.png";

export default function Manage() {

    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatFee,
        hostService
    } = useAppContext();

    // const [openUploads, setOpenUploads] = useState(null);
    const [manageData, setManageData] = useState([]);

    // const handleOpenUploads = (event) => {
    //     setOpenUploads(event.currentTarget);
    // };
    // const handleCloseUploads = (event) => {
    //     setOpenUploads(null);
    // };

    // Manage tables
    const renderManageTable = () => {
        let data = manageData.map((e) => {
            let row = {
                name: e.pagadora,
                last: formatFee(e.ultimaTasa),
                amount: e.monto,
                max: formatFee(e.mayorTasa),
                min: formatFee(e.menorTasa),
                avrg: formatFee(e.promedioTasa)
            }
            return row
        })
        return data
    }
    const manageTableRows = renderManageTable()
	// Manage tables

    useEffect(() => {
        axios.post(hostService + `servicios/conector.asmx/TablaOfertasComprador`, { }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setManageData(respuesta.ofertas);
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, []);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    marginTop: '30px'
                }}
            >
                {/* <ToggleButtonGroup
                    value={period}
                    exclusive
                    onChange={handlePeriod}
                    aria-label="text alignment"
                    className="toggleButtons"
                >
                    <ToggleButton
                        color="buttonOutlined"
                        value="minus"
                        aria-label="left aligned"
                    >
                        Menos de 7 días
                    </ToggleButton>
                    <ToggleButton
                        color="buttonOutlined"
                        value="more"
                        aria-label="centered"
                    >
                        Más de 7 días
                    </ToggleButton>
                </ToggleButtonGroup> */}
                {/* <Box position="absolute" right="0px">
                    <Button
                        onClick={handleOpenUploads}
                        aria-controls="upload"
                        variant="contained"
                        color="buttonColor"
                    >
                        <img
                            style={{ width: "15px", marginRight: "10px" }}
                            src={uploadIcon}
                            alt="upload"
                        />
                        Carga masiva de tasas
                    </Button>
                    <Menu
                        id="upload"
                        open={Boolean(openUploads)}
                        anchorEl={openUploads}
                        onClose={handleCloseUploads}
                        PopoverClasses={{ paper: "whiteDropdown" }}
                    >
                        <MenuItem>Repetir todas las tasas</MenuItem>
                        <MenuItem>Repetir tasas mas bajas</MenuItem>
                        <MenuItem>Repetir tasas mas altas</MenuItem>
                        <MenuItem>Carga masiva con archivo Excel</MenuItem>
                    </Menu>
                </Box> */}
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    height: "80vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Gestionar tasas
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/* <RateTables rows={manageTableRows.slice(0, Math.round(manageTableRows.length/2))} /> */}
                            <RateTables rows={manageTableRows} />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <RateTables rows={manageTableRows.slice(Math.round(manageTableRows.length/2), manageTableRows.length)} />
                        </Grid> */}
                    </Grid>
                </Card>
            </Grid>
        </>
    )
}