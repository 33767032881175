import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';

import './downloadSnackbar.css'


export default function DownloadSnackbar({
    open,
    title
}) {

    return (
            <Snackbar
                sx={{ maxWidth: 300 }}
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                TransitionComponent={(props) => <Slide {...props} direction="right" />}
            >
                <Box className='snackbar-paper'>
                    <Typography variant="subtitle1" color="primary" sx={{ width: 300 }}>{title}</Typography>
                    <br />
                    <LinearProgress color="secondary" />
                </Box>
            </Snackbar>
    );
}