import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography'


import './searchbar.css'

export default function SearchBar ({
    search,
    setSearch
}) {

    const options = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },
        { label: 'The Lord of the Rings: The Return of the King', year: 2003 },
        { label: 'The Good, the Bad and the Ugly', year: 1966 },
        { label: 'Fight Club', year: 1999 },
        { label: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 }
    ]

      return (
        <div className='search'>
            {/* <Autocomplete
                disablePortal
                id="combo-box"
                options={options}
                sx={{
                    width: 300,
                }}
                disableClearable
                forcePopupIcon={false}
                renderInput={
                    (params) =>  */}
                    <TextField
                        //{...params}
                        size="small"
                        label={<Typography variant="h6" color="primary">Buscar</Typography>}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <SearchIcon/>
                            )
                        }}
                    />
                {/* }
            /> */}
        </div>
      )
}