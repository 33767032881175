import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paginations from '../../../Components/Pagination/Paginations'
import Tables from '../../../Components/Tables/Table'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import downloadIcon from '../../../assets/images/download-icon.png'

import axios from 'axios'

export default function RejectedPayments({
    downloaded,
    setDownloaded
}){
    const {
        pagosView,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        downloadBase64,
        downloadingOpen,
        setDownloadingOpen,
        setDownloadingTitle,
        hostService,
        rechazados,
        setRechazados,
        downloadExcel,
        downloadPdf,
        formatAmount
    } = useAppContext();

    const [page, setPage] = useState(0);
    const [rejectedPayments, setRejectedPayments] = useState([]);
    const [checkedRejectedPayments, setCheckedRejectedPayments] = useState([]);
    const [checkedRejectedPaymentsCount, setCheckedRejectedPaymentsCount] = useState(0);
    const [downloadFormat, setDownloadFormat] = useState('santander')


    const getCheckboxValue = (e) => {
        if (checkedRejectedPayments.includes(e.target.value)) {
            const isInArray = (element) => element === e.target.value;
            const elementIndex = checkedRejectedPayments.findIndex(isInArray);
            checkedRejectedPayments.splice(elementIndex, 1);
        } else {
            let data = checkedRejectedPayments;
            data.push(e.target.value);
        }

        setCheckedRejectedPaymentsCount(checkedRejectedPayments.length)
    };

    const handleDownloadFormat = (event) => {
        setDownloadFormat(event.target.value);
    };

    const hadleDownload = () => {
        setDownloadingTitle('Descargando archivo para pago');
        setDownloadingOpen(true);
        const service = downloadFormat === 'santander' ? 'DescargarFormatoSantanderRechazados'
            : downloadFormat === 'universal' ? 'DescargarFormatoUniversalRechazados'
            : downloadFormat === 'scotiabank' ? 'DescargarFormatoScotibankRechazados'
                : 'DescargarFormatoChileRechazados';

        let data = [];
        checkedRejectedPayments.map(e => data.push({
            idOperacion: e
        }));
        console.log(data)
        axios.post(hostService + `servicios/Compradores.asmx/` + service, { operaciones: JSON.stringify(data) }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay operaciones por descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    respuesta.documents.forEach(element => {
                        downloadBase64(element.nombre, element.base64);
                    });

                    setDownloaded(!downloaded);
                    setRechazados(!rechazados);
                }
            }).catch(error => {
                console.log(error);
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetOperacionesRechazadas`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    respuesta.map((object) => data.push({
                        number: object.estadoOperacion === "rechazada-habilitada" ?
                            <FormControlLabel
                                className="checkboxLabel"
                                value={object.idOperacion}
                                control={
                                    <Checkbox
                                        className="checkbox"
                                        onChange={(e) => getCheckboxValue(e)}
                                        style={{
                                            color: "#fff",
                                        }}
                                    />
                                }
                                label=''
                                labelPlacement="end"
                            /> : '',
                        idOperacion: object.idOperacion,
                        nombreFondo: object.nombreFondo,
                        rutEmisor: object.rutEmisor,
                        folio: object.folio,
                        tipoFolio: object.tipoFolio,
                        estadoOperacion: object.estadoOperacion === "rechazada-habilitada" ? "Habilitada" : "No Habilitada"
                    }));

                    setRejectedPayments(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [downloaded]);

    const headers = ["", "Id", "Nombre Fondo", "Rut emisor", "Folio", "Tipo folio", "Estado operación"]
    const labels = ["number", "idOperacion", "nombreFondo", "rutEmisor", "folio", "tipoFolio", "estadoOperacion"]

    return (
    <>
        <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
        >
            <Grid item xs={12} className='buttons-container'>
                <Box display='flex' alignItems='stretch' justifyContent='space-around' width="50%">
                    <FormControl sx={{width: '60%'}}>
                        <InputLabel className="labels">Formato</InputLabel>
                        <Select
                            className="filters-select"
                            value={downloadFormat}
                            label="Formato"
                            onChange={handleDownloadFormat}
                        >
                            <MenuItem value='santander'>Formato Banco Santander</MenuItem>
                            <MenuItem value='chile'>Formato Banco de Chile</MenuItem>
                            <MenuItem value='scotiabank'>Formato Banco Scotiabank</MenuItem>
                            <MenuItem value='universal'>Formato otros bancos</MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={() => hadleDownload()} disabled={downloadingOpen || checkedRejectedPaymentsCount === 0} variant='contained' color='buttonColor' sx={{width: '35%'}}>
                        <img
                            style={{
                                width: '17px',
                                marginRight: '10px'
                            }}
                            src={downloadIcon}
                            alt="descargar"
                        />
                        Descargar
                    </Button>
                </Box>
            </Grid>
        </Grid>
        <Grid
            item
            xs={12}
            style={{
                maxHeight: "75vh",
                overflow: "auto",
                marginTop: "10px",
            }}
        >
            <Typography variant="h5" fontWeight={600}>
                Pagos rechazados
            </Typography>
            <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Tables
                            rows={rejectedPayments.slice((page * 14), (page * 14) + 14)}
                            headers={headers}
                            labels={labels}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
            <Paginations
                onChange={(event, val) => { setPage(val - 1) }}
                count={Math.ceil(rejectedPayments.length / 14)}
            />
        </Box>
    </>
    )
}