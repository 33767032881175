import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'
import SearchBar from '../../../Components/SearchBar/SearchBar'

export default function DayTransactions ({
    rows,
    headers,
    labels
}) {
    return (
        <>
            <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button variant='contained' color='buttonColor'>
                        Descargar XML
                    </Button>
                    <Button variant='contained' color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Transacciones del día
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={rows}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations />
            </Box>
        </>
    )
}