import React from 'react'
import { useAppContext } from '../../libs/contextlib'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { CheckCircleOutlined, ErrorOutline, WarningAmber, InfoOutlined } from '@mui/icons-material';

import './dialog.css'

export default function AlertDialog(){
    const {
        alertMessageOpen,
        setAlertMessageOpen,
        alertMessageTitle,
        alertMessageDescription,
        alertMessageType
    } = useAppContext();

    // const [open, setOpen] = useState(true);
    // const [title, setTitle] = useState('');
    // const [description, setDescription] = useState('');

    return (
        <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={alertMessageOpen}
            onClose={() => setAlertMessageOpen(false)}
            PaperProps={
                {
                    className: 'modal-paper'
            }}
        >
            <DialogTitle id="alert-dialog-title" color='primary'>
                <div style={{ marginBottom: 30 }}>
                    {alertMessageType === 'success' ?
                        <CheckCircleOutlined style={{ fontSize: 100, color: 'green' }} />
                        : alertMessageType === 'error' ?
                            <ErrorOutline style={{ fontSize: 100, color: 'red' }} />
                            : alertMessageType === 'warning' ?
                                <WarningAmber style={{ fontSize: 100, color: 'yellow' }} />
                                : <InfoOutlined style={{ fontSize: 100, color: 'blue' }} />
                    }

                    
                </div>
                {alertMessageTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" color='primary'>
                    {alertMessageDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='buttonOutlined' size='large' onClick={() => setAlertMessageOpen(false)}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}