import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import '../nuevoUsuario.css'


export default function IngresarClave({
    dataUsuario,
    password,
    handleSetPassword,
    password2,
    messagePassword,
    handleSendPassword
}) {
    return (
        <Grid>
            <Box
                component='form'
                className='form-container'
            >
                <Typography color="primary" variant='h6'>
                    Nombre
                </Typography>
                <Box className='info-boxes'>
                    <Typography color="primary" variant='h6' sx={{verticalAlign: 'top'}} fontWeight={500}>
                        {dataUsuario.contacto}
                    </Typography>
                </Box>
                <Typography color="primary" variant='h6'>
                    Email
                </Typography>
                <Box className='info-boxes'>
                    <Typography color="primary" variant='h6' fontWeight={500}>
                        {dataUsuario.username}
                    </Typography>
                </Box>
                <Typography color="primary" variant='h6'>
                    Rut
                </Typography>
                <Box className='info-boxes'>
                    <Typography color="primary" variant='h6' fontWeight={500}>
                        {dataUsuario.rut}
                    </Typography>
                </Box>
                <Typography color="primary" variant='h6'>
                    Teléfono
                </Typography>
                <Box className='info-boxes'>
                    <Typography color="primary" variant='h6' fontWeight={500}>
                        {dataUsuario.telefono}
                    </Typography>
                </Box>

                <Typography color="primary" variant='h6' sx={{ marginTop: '20px' }}>
                    Contraseña
                </Typography>
                <TextField
                    sx={{ input: { color: '#fff' } }}
                    color='primary'
                    variant="outlined"
                    className="textfield"
                    type='password'
                    value={password}
                    onChange={(event) => handleSetPassword(event.target.value, password2)}
                />
                <Typography color="primary" variant='h6' sx={{ marginTop: '20px' }}>
                    Repetir contraseña
                </Typography>
                <TextField
                    sx={{ input: { color: '#fff' } }}
                    color='primary'
                    variant="outlined"
                    className="textfield"
                    type='password'
                    value={password2}
                    onChange={(event) => handleSetPassword(password, event.target.value)}
                />
                <Typography color="red" align='center' sx={{ marginTop: '10px' }}>
                    {messagePassword}
                </Typography>
            </Box>
            <Box
                className='buttons-container'
            >
                <Button
                    onClick={() => handleSendPassword() }
                    disabled={!password || !password2 || messagePassword !== ''}
                    variant='contained' color='buttonColor'
                    sx={{ width: '180px', marginBottom: '10px' }}
                >
                    <Typography>
                        Continuar
                    </Typography>
                </Button>
            </Box>
        </Grid>
    )
}