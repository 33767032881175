import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Paginations from '../../../Components/Pagination/Paginations'
import Tables from '../../../Components/Tables/Table'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import LoadingButton from '@mui/lab/LoadingButton';

export default function DonePayments({
    rows,
    headers,
    labels,
    openDonePaymentsModal,
    handleToggleModal,
    confirmPayment,
    notPayed,
    setNotPayed,
    notPayedHeaders,
    notPayedLabels,
    notPayedRows,
    checkedBillsLength,
    checkedReasonsLength,
    notPayedContinue,
    setNotPayedContinue,
    notPayedReasonsTableHeaders,
    notPayedReasonsTableLabels,
    notPayedReasons,
    handleNotPayedContinue,
    search,
    setSearch,
    hadleAcceptPay,
    hadleNoPayed,
    hadleAcceptNoPayed,
    chargingPayed,
    chargingNoPayed
}) {

    const {
        formatAmount,
        downloadExcel,
        downloadPdf
    } = useAppContext();

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [currentNotPayedRows, setCurrentNotPayedRows] = useState(notPayedRows);
    const [searchNotPayedRows, setSearchNotPayedRows] = useState();

    const handleDownloadExcel = () => {
        let title = 'Informar pagos realizados';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                number: object.number,
                user: object.user,
                date: object.date,
                total: formatAmount(object.total),
                quantity: object.quantity
            })
            return dataRows;
        });

        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Informar pagos realizados';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                number: object.number,
                user: object.user,
                date: object.date,
                total: formatAmount(object.total),
                quantity: object.quantity
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (searchNotPayedRows) {
            setCurrentNotPayedRows(notPayedRows.filter((element) => {
                return element.number.props.value.toLocaleLowerCase().includes(searchNotPayedRows.toLocaleLowerCase())
                    || element.company.includes(searchNotPayedRows)
                    || element.rut.includes(searchNotPayedRows)
                    || element.amount.includes(searchNotPayedRows)
            }
            ));
        } else {
            setCurrentNotPayedRows(notPayedRows);
        }
    }, [searchNotPayedRows]);

    useEffect(() => {
        setSearch('');
        setCurrentNotPayedRows(notPayedRows);
    }, [notPayedRows]);

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.user.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || String(element.number).includes(search)
            }
            ));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rows]);


    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Informar pagos realizados
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length / 14)}
                />
            </Box>
            <Modal
                open={openDonePaymentsModal}
                onClose={() => [handleToggleModal(), setNotPayed(false)]}
            >
                <Box className='done-payments-modal'>
                    <Grid container>
                        <Grid item xs={8}>
                            {!notPayed ?
                                <Typography variant='h6'>
                                    Informar Pago
                                </Typography>
                                :
                                <Typography variant='h6'>
                                    Confirmación Parcial
                                </Typography>
                            }
   
                            {!notPayed ?
                                <Typography variant='body1'>
                                    ¿Usted confirma que se han pagado exitosamente las {confirmPayment.cantidadFacturas} transacciones del Archivo #{confirmPayment.id} por un total de ${formatAmount(confirmPayment.montoTotal)}?
                                </Typography>
                                :
                                !notPayedContinue ?
                                <Typography variant='body1'>
                                    Por favor, indique los pagos que tuvieron problemas
                                </Typography>
                                :
                                <Typography variant='body1'>
                                    Por favor, indique las razones del rechazo
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {!notPayed || notPayedContinue ?
                                ''
                                :
                                <SearchBar
                                    search={searchNotPayedRows}
                                    setSearch={setSearchNotPayedRows}
                                ></SearchBar>
                            }
                        </Grid>

                        {!notPayed ?
                            <Grid item xs={12} className="btn-container">
                                {chargingNoPayed ?
                                    <LoadingButton loading variant="contained" color='buttonSecondary'>
                                        informar pagos con problemas
                                    </LoadingButton>
                                    :
                                    <Button
                                        onClick={() => [setNotPayed(true), hadleNoPayed()]}
                                        variant='contained'
                                        color='buttonSecondary'
                                        disabled={chargingPayed}
                                    >
                                        informar pagos con problemas
                                    </Button>
                                }
                                {chargingPayed ?
                                    <LoadingButton loading variant="contained" color='buttonColor'>
                                        Todos los pagos exitosos
                                    </LoadingButton>
                                    :
                                    <Button
                                        onClick={() => [hadleAcceptPay(), setNotPayed(false)]}
                                        variant='contained'
                                        color='buttonColor'
                                        disabled={chargingNoPayed}
                                    >
                                        Todos los pagos exitosos
                                    </Button>
                                }
                            </Grid>
                            :
                            <>
                            <Box sx={{maxHeight: '500px', display: notPayedContinue ? 'none' : 'block' }} overflow='auto' className='notPayedTable'>
                                <Tables
                                    headers={notPayedHeaders}
                                    labels={notPayedLabels}
                                    rows={currentNotPayedRows}
                                />
                            </Box>
                            
                            <Box sx={{maxHeight: '500px', display: notPayedContinue ? 'block' : 'none' }} overflow='auto' className='notPayedTable' >
                                <Tables
                                    headers={notPayedReasonsTableHeaders}
                                    labels={notPayedReasonsTableLabels}
                                    rows={notPayedReasons}
                                />
                            </Box>
                            </>
                        }
                        <Grid item xs={12} className='modalButtonsContainer'>
                            {notPayed && !notPayedContinue ?
                                <>
                                    <Typography sx={{ display: chargingPayed || chargingNoPayed ? 'none' : 'block' }} onClick={() => [handleToggleModal(), setNotPayed(false), setNotPayedContinue(false)]} style={{ textDecoration: 'underline', cursor: "pointer" }}>
                                        Cancelar
                                    </Typography>
                                    <Button onClick={() => handleNotPayedContinue()} disabled={checkedBillsLength === 0} variant='contained' color='buttonSecondary' style={{ marginLeft: '20px' }}>
                                        Continuar
                                    </Button>
                                </>
                                : notPayed && notPayedContinue ?
                                <>
                                    <Typography sx={{ display: chargingPayed || chargingNoPayed ? 'none' : 'block' }} onClick={() => [handleToggleModal(), setNotPayed(false), setNotPayedContinue(false)]} style={{ textDecoration: 'underline', cursor: "pointer" }}>
                                        Cancelar
                                    </Typography>
                                    <Button onClick={() => [hadleAcceptNoPayed(), setNotPayed(false)]} disabled={checkedReasonsLength === 0} variant='contained' color='buttonSecondary' style={{ marginLeft: '20px' }}>
                                        Confirmar
                                    </Button>
                                </>
                                : 
                                <Typography sx={{ display: chargingPayed || chargingNoPayed ? 'none' : 'block' }} onClick={() => [handleToggleModal(), setNotPayed(false), setNotPayedContinue(false)]} style={{ textDecoration: 'underline', cursor: "pointer" }}>
                                        Cancelar
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}