import React, { useState, useEffect, useRef } from 'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircle from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Event from '@mui/icons-material/Event';
import Tables from '../../../Components/Tables/Table'
import LineChart from '../../../Components/LineChart/LineChart'
import Tooltip from '@mui/material/Tooltip'
import ButtonGroup from '@mui/material/ButtonGroup';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { WarningAmber } from '@mui/icons-material'

import moment from 'moment'
import axios from 'axios'
import SegmentedStackBarChart from '../../../Components/SegmentedStackBarChart/SegmentedStackBarChart'
import uploadIcon from "../../../assets/images/upload-icon.png"
import { Link, styled } from '@mui/material'


export default function PayingCompanies({
    payingCompany,
    offerLimit,
    payerLimit,
    setPayingCompany,
    payingCompaniesData,
    maxAmount,
    setMaxAmount,
    actualizarOfertas,
    setActualizarOfertas
}) {
    const {
        activeFund,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatAmount,
        formatFee,
        hostService
    } = useAppContext();

    const [newMontoMax, setNewMontoMax] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tasaOferta, setTasaOferta] = useState('');
    const [montoOferta, setMontoOferta] = useState('');
    const [plazoOferta, setPlazoOferta] = useState('');
    const [ofertasVigentes, setOfertasVigentes] = useState([]);
    const [transaccionesHoy, setTransaccionesHoy] = useState([{}, {}]);
    const [tasasMercado, setTasasMercado] = useState([{}]);
    const [montoTransado, setMontoTransado] = useState(0);
    const [tablePeriod, setTablePeriod] = useState(0);
    const [sinceValue, setSinceValue] = useState(moment().get());
    const [untilValue, setUntilValue] = useState(moment().get());
    const [activeCompany, setActiveCompany] = useState(true);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [acceptDialog, setAcceptDialog] = useState(null);
    const [offersSelected, setOffersSelected] = useState([]);
    const [actualizar, setActualizar] = useState(false);

    const Input = styled('input')({
        display: 'none',
      });

    const handlePayingCompany = (event) => {
        setPayingCompany(event.target.value);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleTablePeriod = (event) => {
        setTablePeriod(event);
        setUntilValue(moment().get());
        setSinceValue(moment().subtract(event, 'days'));
    }

    const handleUploadFees = (file) => {
        let data = new FormData() 
        data.append('file', file)
        data.append('idPagadora', payingCompaniesData.find(company => company.nombre === payingCompany).id)
        data.append('idFondo', activeFund.id)

        axios({
            method: "post",
            url: hostService + 'servicios/Compradores.asmx/IngresarTasasMasivas',
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true
          })
        .then(response => {
            setActualizar(!actualizar);   
            setActualizarOfertas(!actualizarOfertas);      
            if (response.data.includes('errorSession')) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('La sesión ha caducado');
                setAlertMessageDescription('');
                setAlertMessageType('error');
                window.location = "/login";
            } else if (response.data.includes('errorPermiso')) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Error de permiso');
                setAlertMessageDescription('No tiene permiso para realizar esta acción');
                setAlertMessageType('warning');
            } else if (response.data.includes('errorFormato')) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Extensión del archivo incorrecta, debe ser .xlsx o .xls');
                setAlertMessageDescription('');
                setAlertMessageType('error');

            } else if (response.data.includes('columnasIncorrectas')) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Las columnas del documento no cumplen los requisitos');
                setAlertMessageDescription('');
                setAlertMessageType('error');

            } else if (response.data.includes('ok')) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Las órdenes se han actualizado correctamente');
                setAlertMessageDescription('');
                setAlertMessageType('success');

            } else {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            }
        }).catch(error => {
            console.log(error);
            setAlertMessageOpen(true);
            setAlertMessageTitle('Ocurrió un error inesperado');
            setAlertMessageDescription('Inténtalo nuevamente');
            setAlertMessageType('error');
        });
    }

    const handleAceptDelete = () => {
        setDialogOpen(false);
        axios.post(hostService + `servicios/motorOtc.asmx/EliminarOfertas`, { ofertas: JSON.stringify(offersSelected) }, { withCredentials: true })
            .then(response => {
                setActualizar(!actualizar);
                setActualizarOfertas(!actualizarOfertas)
                setOffersSelected([]);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'needed') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No se puede borrar esta orden ya que es la única que tienes');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'reservado') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Las órdenes se han eliminado');
                    setAlertMessageDescription('Pero por los próximos 2 minutos las órdenes seguirán vigentes para los proveedores que las reservaron');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Órdenes eliminadas correctamente');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleAsignPermanent = () => {
        axios.post(hostService + `servicios/motorOtc.asmx/AsignarOfertasPermanentes`, { ofertas: JSON.stringify(offersSelected) }, { withCredentials: true })
            .then(response => {
                setActualizar(!actualizar);
                setOffersSelected([]);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Órdenes asignadas como permanentes');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleAsignDaily = () => {
        axios.post(hostService + `servicios/motorOtc.asmx/AsignarOfertasDiarias`, { ofertas: JSON.stringify(offersSelected) }, { withCredentials: true })
            .then(response => {
                setActualizar(!actualizar);
                setOffersSelected([]);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Órdenes asignadas como diarias');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleAgregarOferta = () => {
        if (!payerLimit || (maxAmount !== 0 && maxAmount !== '0')) {
            if (offerLimit && (!tasaOferta || !montoOferta || !plazoOferta)) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Debes ingresar tasa, plazo y monto');
                setAlertMessageDescription('');
                setAlertMessageType('warning');
            } else if (!offerLimit && (!tasaOferta || !plazoOferta)) {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Debes ingresar tasa y plazo');
                setAlertMessageDescription('');
                setAlertMessageType('warning');
            } else {
                const data = {
                    idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id,
                    idFondo: activeFund.id,
                    tasa: tasaOferta.replace(',', '.'),
                    vence: false,
                    montoOfertado: (!montoOferta && !offerLimit) ? 0 : montoOferta,
                    plazo: plazoOferta
                }
                axios.post(hostService + `servicios/motorOtc.asmx/RecibirOferta`, data, { withCredentials: true })
                    .then(response => {
                        const respuesta = JSON.parse(response.data.d)
                        setActualizar(!actualizar);
                        setActualizarOfertas(!actualizarOfertas)
                        setOffersSelected([]);
                        if (respuesta.Status === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (respuesta.Status === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Orden ingresada correctamente');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });

                setTasaOferta('');
                setMontoOferta('');
                setPlazoOferta('');
            }
        } else {
            setAlertMessageOpen(true);
            setAlertMessageTitle('Ingresar monto máximo');
            setAlertMessageDescription('Antes de ofertar debes ingresar un monto máximo para esta pagadora');
            setAlertMessageType('warning');
        }

    }

    const handleSetMontoMax = () => {
        if(newMontoMax === ''){
            setAlertMessageOpen(true);
            setAlertMessageTitle('Debes ingresar un monto máximo');
            setAlertMessageDescription('');
            setAlertMessageType('warning');

        }else if (payingCompany) {
            axios.post(hostService + `servicios/Compradores.asmx/ActualizarMontoMaximo`, { montoMaximo: newMontoMax, idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else if (response.data.d === 'errorPermiso') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Error de permiso');
                        setAlertMessageDescription('No tiene permiso para realizar esta acción');
                        setAlertMessageType('warning');
                    } else if (response.data.d === 'montoInsuficiente') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Monto insuficiente');
                        setAlertMessageDescription('El monto máximo no puede ser menor o igual al monto usado');
                        setAlertMessageType('warning');
                    } else if (response.data.d === 'ok') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Monto máximo ingresado correctamente');
                        setAlertMessageDescription('');
                        setAlertMessageType('success');
                        setMaxAmount(newMontoMax);
                        setNewMontoMax('');
                    } else {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    }
                }).catch(error => {
                    console.log(error);
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        }
    }

    const handleSelectAllOffers = (checked) => {
        if (ofertasVigentes?.length > 0) {
            setOffersSelected([]);
            if (checked) {
                var temp = [];
                ofertasVigentes.map((e, i) => {
                    temp.push({ id: e[0] });
                    return temp;
                })
                setOffersSelected(temp);
            }
        }
    }

    const handleSelectOffer = (id) => {
        if (ofertasVigentes?.length > 0) {
            var temp = offersSelected;
            temp = temp.filter(x => x.id !== id);
            if (offersSelected.find(x => x.id === id) === undefined) {
                temp.push({ id: id });
            }
            setOffersSelected(temp);
        }
    }

    const ObtenerDataTiempoReal = () => {
        if (payingCompany) {
            axios.post(hostService + `servicios/Compradores.asmx/ObtenerDataTiempoReal`, { idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id, fechaDesde: sinceValue.format('YYYY-MM-DD'), fechaHasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else {
                        var respuesta = JSON.parse(response.data.d);
                        const data = [{}, {}];
                        respuesta.transacciones.map((object) => data.push({ time: new Date(object.fechaOferta).getTime(), rate: (object.tasa), amount: (object.montoTransado / 1000000) }));
                        setTransaccionesHoy(data);
                        setMontoTransado(respuesta.resumen.montoTransado / 1000000);
                    }
                }).catch(error => {
                    console.log(error);
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        }
    }

    const interval = useRef();
    useEffect(() => {
        ObtenerDataTiempoReal();
        interval.current = setInterval(ObtenerDataTiempoReal, 30000);
        return () => {clearInterval(interval.current)};
    }, [payingCompany, sinceValue, untilValue, actualizar]);

    useEffect(() => {
        if (payingCompany) {
            axios.post(hostService + `servicios/Compradores.asmx/GetMontoMaximo`, { idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else {
                        var respuesta = JSON.parse(response.data.d);
                        setMaxAmount(respuesta.montoMaximo[0].montoMaximo)
                        setActiveCompany(respuesta.montoMaximo[0].activo === "True")
                        setNewMontoMax('');
                    }
                }).catch(error => {
                    console.log(error);
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        }
    }, [payingCompany, activeCompany]);

    // const ObtenerTasasDelMercado = () => {
    //     if (payingCompany) {
    //         setOffersSelected([]);
    //         axios.post(hostService + `servicios/Compradores.asmx/ObtenerTasasDelMercado`, { idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id }, { withCredentials: true })
    //             .then(response => {
    //                 if (response.data.d === 'errorSession') {
    //                     setAlertMessageOpen(true);
    //                     setAlertMessageTitle('La sesión ha caducado');
    //                     setAlertMessageDescription('');
    //                     setAlertMessageType('error');
    //                     window.location = "/login";
    //                 } else {
    //                     var respuesta = JSON.parse(response.data.d);
    //                     if (respuesta.Response.length === 1) {
    //                         let oferta = respuesta.Response[0];
    //                         let data = [];
    //                         data.push({
    //                             amount: oferta.Tasa - 0.01,
    //                             value: 0,
    //                             gap: 1
    //                         });

    //                         let newValue = {};
    //                         newValue['amount'] = oferta.Tasa;
    //                         newValue['gap'] = 1;
    //                         if (String(oferta.idFondo) === activeFund.id) {
    //                             newValue['loss'] = oferta.MontoTotal;
    //                         } else {
    //                             newValue['value'] = oferta.MontoTotal;
    //                         }

    //                         data.push(newValue);

    //                         data.push({
    //                             amount: oferta.Tasa + 0.01,
    //                             value: 0,
    //                             invisible: oferta.MontoTotal,
    //                             gap: 1
    //                         });

    //                         setTasasMercado(data);
    //                     } else if (respuesta.Response.length > 0) {
    //                         let data = [];
    //                         let cont = 0;
    //                         respuesta.Response.map((v, i) => {
    //                             if (data.length > 0) {
    //                                 if (v.Tasa === data[cont - 1].amount) {
    //                                     let newValue = {};
    //                                     if (String(v.idFondo) === activeFund.id) {
    //                                         newValue['loss' + i] = v.MontoTotal;
    //                                     } else {
    //                                         newValue['value' + i] = v.MontoTotal;
    //                                     }
    //                                     data[cont - 1] = Object.assign(data[cont - 1], newValue);
    //                                 } else {
    //                                     let newValue = {};
    //                                     newValue['amount'] = v.Tasa;
    //                                     newValue['invisible'] = v.MontoInferior;
    //                                     newValue['gap'] = 1;
    //                                     if (String(v.idFondo) === activeFund.id) {
    //                                         newValue['loss' + i] = v.MontoTotal;
    //                                     } else {
    //                                         newValue['value' + i] = v.MontoTotal;
    //                                     }
    //                                     data.push(newValue);
    //                                     cont++;
    //                                 }
    //                             }
    //                             else {
    //                                 let newValue = {};
    //                                 newValue['amount'] = v.Tasa;
    //                                 newValue['gap'] = 1;
    //                                 if (String(v.idFondo) === activeFund.id) {
    //                                     newValue['loss' + i] = v.MontoTotal;
    //                                 } else {
    //                                     newValue['value' + i] = v.MontoTotal;
    //                                 }
    //                                 data.push(newValue);
    //                                 cont++;
    //                             }
    //                             return data;
    //                         });

    //                         setTasasMercado(data);
    //                     } else {
    //                         setTasasMercado([{}]);
    //                     }
    //                 }

    //             }).catch(error => {
    //                 console.log(error);
    //                 setAlertMessageOpen(true);
    //                 setAlertMessageTitle('Ocurrió un error inesperado');
    //                 setAlertMessageDescription('Inténtalo nuevamente');
    //                 setAlertMessageType('error');
    //             });
    //     }
    // }
    
    // useEffect(() => {
    //     ObtenerTasasDelMercado();
    //     const interval = setInterval(ObtenerTasasDelMercado, 30000);
    //     return () => {clearInterval(interval)};
    // }, [payingCompany, actualizar, maxAmount]);

    const ObtenerOfertasPorComprador = () => {
        if (payingCompany) {
            axios.post(hostService + `servicios/Compradores.asmx/ObtenerOfertasPorComprador`, { idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id, idFondo: activeFund.id }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else {
                        var respuesta = JSON.parse(response.data.d);
                        const data = [];
                        respuesta.ofertas.map((object) => data.push([object.idOferta, object.tasa, object.diasAlVencimiento, object.fechaVencimiento, object.montoDisponible]));
                        setOfertasVigentes(data);
                    }
                }).catch(error => {
                    console.log(error);
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        }
    }

    useEffect(() => {
        ObtenerOfertasPorComprador();
    }, [payingCompany, actualizar, maxAmount]);

    //   Offer Table
    function createTableData(checkbox, rate, dayCount, permanent) {
        return { checkbox, rate, dayCount, permanent };
    }

    function createTableDataLimit(checkbox, rate, dayCount, amount, permanent) {
        return { checkbox, rate, dayCount, amount, permanent };
    }

    let tableHeaders = [];
    let tableLabels = [];
    if(offerLimit){
        tableHeaders = [<Checkbox sx={{ color: 'white' }} onChange={(e) => handleSelectAllOffers(e.target.checked)} checked={ofertasVigentes.length === offersSelected.length && offersSelected.length > 0} disabled={ofertasVigentes.length === 0} />, 'Tasa (%)', 'Días al vencimiento', 'Monto disponible ($)', <Typography sx={{ textAlign: 'center' }}>Permanente</Typography>];
        tableLabels = ['checkbox', 'rate', 'dayCount', 'amount', 'permanent'];
    }
    else{
        tableHeaders = [<Checkbox sx={{ color: 'white' }} onChange={(e) => handleSelectAllOffers(e.target.checked)} checked={ofertasVigentes.length === offersSelected.length && offersSelected.length > 0} disabled={ofertasVigentes.length === 0} />, 'Tasa (%)', 'Días al vencimiento', <Typography sx={{ textAlign: 'center' }}>Permanente</Typography>];
        tableLabels = ['checkbox', 'rate', 'dayCount', 'permanent'];
    }
   

    let tableRows = []
    if (ofertasVigentes?.length > 0) {
        ofertasVigentes.map((e, i) => {
            var permanente = moment(e[3]).diff(moment().get(), 'days') > 1;
            if (offerLimit) {
                tableRows.push(createTableDataLimit(
                    <Checkbox key={"check" + i} sx={{ color: 'white' }} onChange={() => handleSelectOffer(e[0])} checked={offersSelected.find(x => x.id === e[0]) !== undefined} />,
                    formatFee(e[1]),
                    <Typography key={"typo" + i} sx={{ textAlign: 'right' }}>{e[2]}</Typography>,
                    formatAmount(e[4]),
                    permanente ? <Typography sx={{ textAlign: 'center' }}>Sí</Typography> : <Typography sx={{ textAlign: 'center' }}>No</Typography>
                ))
            } else {
                tableRows.push(createTableData(
                    <Checkbox key={"check" + i} sx={{ color: 'white' }} onChange={() => handleSelectOffer(e[0])} checked={offersSelected.find(x => x.id === e[0]) !== undefined} />,
                    formatFee(e[1]),
                    <Typography key={"typo" + i} sx={{ textAlign: 'right' }}>{e[2]}</Typography>,
                    permanente ? <Typography key={"typo2" + i} sx={{ textAlign: 'center' }}>Sí</Typography> : <Typography key={"typo2" + i} sx={{ textAlign: 'center' }}>No</Typography>
                ))
            }
            return tableRows
        })
    }

    // [object.idOferta, object.tasa, object.diasAlVencimiento, object.fechaVencimiento]

    let menuItems = [];
    if (payingCompaniesData?.length > 0) {
        payingCompaniesData.map((object, i) => {
            menuItems.push(<MenuItem key={object.id} value={object.nombre}>{object.nombre}</MenuItem>);
            return menuItems
        })
    }

    const tableActions = [{
        delete: <Typography sx={{ textAlign: 'center' }}><Tooltip title={<Typography>Eliminar órdenes</Typography>}>
            <IconButton
                onClick={() => {
                    setModalTitle('¿Estás Seguro?');
                    setModalContent('Este proceso es irreversible y deberás ofertar nuevamente');
                    setAcceptDialog(() => handleAceptDelete);
                    setDialogOpen(true);
                }}
                color='primary'
                size='large'
                sx={{ height: 10, textAlign: 'center', alignItems: "center", minHeight: '100%' }}>
                <DeleteIcon fontSize='large' />
            </IconButton>
        </Tooltip>
        </Typography>,
        permanent: <Typography sx={{ textAlign: 'center' }}><Tooltip title={<Typography>Asignar como órdenes permanentes</Typography>}>
            <IconButton onClick={handleAsignPermanent} color='primary' size='large'><EventRepeatIcon fontSize='large' /></IconButton>
        </Tooltip></Typography>,
        daily: <Typography sx={{ textAlign: 'center' }}><Tooltip title={<Typography>Asignar como órdenes diarias</Typography>}>
            <IconButton onClick={handleAsignDaily} color='primary' size='large'><Event fontSize='large' /></IconButton>
        </Tooltip></Typography>
    }];


    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    marginBottom: "80px",
                    marginTop: '30px'
                }}
            >
                <Box position="absolute" left="0px">
                    <Typography variant='h5' fontWeight={600}>
                        Empresas pagadoras
                    </Typography>
                    <FormControl sx={{ width: '15vw', marginTop: '20px' }}>
                        <InputLabel className="labels">Empresa</InputLabel>
                        <Select
                            className="filters-select"
                            value={payingCompany}
                            label="Empresa"
                            onChange={handlePayingCompany}
                            MenuProps={{ PaperProps: { style:{ maxHeight: 500 } } }}
                        >
                            {menuItems}
                        </Select>
                    </FormControl>
                </Box>
                {payerLimit ? 
                <Box position="absolute" right="0px">
                    <Typography variant='h6' color='primary'>
                        Monto máximo: ${formatAmount(maxAmount)}
                    </Typography>
                    <TextField
                        sx={{ input: { color: '#fff' } }}
                        color='primary'
                        variant="outlined"
                        className="textfield"
                        disabled={!payingCompany}
                        value={newMontoMax}
                        onChange={(event) => setNewMontoMax(event.target.value.replace('.', '').replace(',', ''))}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button className='add-offer-btn' variant='contained' color='buttonColor' onClick={handleSetMontoMax} disabled={!payingCompany}>
                        Actualizar
                    </Button>
                </Box>
                : 
                <Box position="absolute" right="0px">
                </Box>
                }
            </Grid>
            <Grid item xs={12} sx={{ height: '75vh' }} marginTop={3}>
                <Grid container spacing={2} height='100%'>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '75vh' }}>
                        <Box sx={{ flex: '0.1' }}>
                            <Typography variant='h5' fontWeight={600}>
                                Órdenes vigentes
                            </Typography>
                        </Box>
                        <Card sx={{ flex: '0.9' }} className="paying-company-cards">
                            <Box maxHeight='100%' overflow='auto' width='100%'>
                                <Tables
                                    headers={tableHeaders}
                                    labels={tableLabels}
                                    rows={tableRows}
                                />
                            </Box>
                        </Card>
                        {offersSelected.length === 0 ?
                            <Card sx={{ flex: '0.3', justifySelf: 'center' }} className="paying-company-cards">
                                <Box className='add-offer' display='flex' sx={{ justifyContent: 'center' }}>
                                    <Box className='add-offer' display='flex' flexDirection='column' width={100}>
                                        <Typography color='primary'>
                                            Tasa:
                                        </Typography>
                                        <TextField
                                            sx={{ input: { color: '#fff' } }}
                                            color='primary'
                                            variant="outlined"
                                            className="textfield"
                                            disabled={!payingCompany}
                                            value={tasaOferta}
                                            onChange={(event) => setTasaOferta(event.target.value.replace('.', ','))}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography sx={{ color: '#BFBFBF' }}>%</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box className='add-offer' display='flex' flexDirection='column' width={130}>
                                        <Typography color='primary'>
                                            Días al vencimiento:
                                        </Typography>
                                        <TextField
                                            sx={{ input: { color: '#fff' } }}
                                            color='primary'
                                            variant="outlined"
                                            className="textfield"
                                            disabled={!payingCompany}
                                            value={plazoOferta}
                                            onChange={(event) => setPlazoOferta(event.target.value.replace('.', ','))}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography sx={{ color: '#BFBFBF' }}>días</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    {offerLimit ?
                                        <Box className='add-offer' display='flex' flexDirection='column' width={150}>
                                            <Typography color='primary'>
                                                Monto:
                                            </Typography>
                                            <TextField
                                                sx={{ input: { color: '#fff' } }}
                                                color='primary'
                                                variant="outlined"
                                                className="textfield"
                                                disabled={!payingCompany}
                                                value={montoOferta}
                                                onChange={(event) => setMontoOferta(event.target.value.replace('.', '').replace(',', ''))}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                        :
                                        <Box className='add-offer' display='flex' flexDirection='column'>
                                        </Box>
                                    }
                                        <Button sx={{ marginTop: 3, marginLeft: 1, float: 'right', display: 'flex', width: "130px", height: "55px" }} variant='contained' color='buttonColor' onClick={handleAgregarOferta} disabled={!payingCompany} >
                                            Agregar orden
                                        </Button>
                                </Box>
                                <Box className='add-offer' marginTop={2} textAlign='center'>
                                    <label>
                                        <Input accept=".xlsx, .xls" type="file" onChange={(e) => handleUploadFees(e.target.files[0])} />
                                        <Button
                                            variant="contained"
                                            color="buttonColor"
                                            disabled={!payingCompany}
                                            component="span"
                                            onClick={() => clearInterval(interval.current)}
                                        >
                                            <img
                                                style={{ width: "15px", marginRight: "10px" }}
                                                src={uploadIcon}
                                                alt="upload"
                                            />
                                            Carga masiva de tasas
                                        </Button>
                                    </label>
                                    {offerLimit ?
                                        <Link href={hostService + 'Ejemplos/Ejemplo carga masiva de tasas con límite.xlsx'} >
                                            <Typography color='primary' >
                                                Ejemplo archivo
                                            </Typography>
                                        </Link>
                                        :
                                        <Link href={hostService + 'Ejemplos/Ejemplo carga masiva de tasas.xlsx'} >
                                            <Typography color='primary' >
                                                Ejemplo archivo
                                            </Typography>
                                        </Link>
                                    }
                                </Box>
                            </Card>
                            :
                            <Card sx={{ flex: '0.3', display: 'flex', direction: "column", justifyContent: "left", verticalAlign: 'middle' }} className="paying-company-cards">
                                <Box maxHeight='100%' overflow='auto' width='100%'>
                                    <Tables
                                        headers={[<Typography sx={{textAlign: 'center'}}>Eliminar órdenes</Typography>, <Typography sx={{textAlign: 'center'}}>Asignar permanentes</Typography>, <Typography sx={{textAlign: 'center'}}>Asignar diarias</Typography>]}
                                        labels={['delete', 'permanent', 'daily']}
                                        rows={tableActions}
                                    />
                                </Box>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='h5' fontWeight={600}>
                            Mis transacciones
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
                            <Typography>
                                Monto Transado: <span style={{
                                }}>{montoTransado.toFixed(2).replace('.', ',')} M</span>
                            </Typography>
                        </Box>
                        <Card className="paying-company-cards" sx={{ marginBottom: 0 }}>
                            <LineChart
                                data={transaccionesHoy}
                                sinceValue={sinceValue}
                                untilValue={untilValue}
                                leftTitle={"tasa %"}
                                rightTitle={"$ M"}
                            />
                        </Card>
                        <Grid xs={12} item >
                            <Card className="paying-company-cards" style={{ height: 80, textAlign: 'center', background: '#292929' }} >
                                <Box>
                                    <ButtonGroup size='small'>
                                        <Button onClick={() => handleTablePeriod(0)} disabled={tablePeriod === 0}>1 día</Button>
                                        <Button onClick={() => handleTablePeriod(7)} disabled={tablePeriod === 7}>1 semana</Button>
                                        <Button onClick={() => handleTablePeriod(30)} disabled={tablePeriod === 30}>1 mes</Button>
                                        <Button onClick={() => handleTablePeriod(90)} disabled={tablePeriod === 90}>3 meses</Button>
                                        <Button onClick={() => handleTablePeriod(180)} disabled={tablePeriod === 180}>6 meses</Button>
                                        <Button onClick={() => handleTablePeriod(365)} disabled={tablePeriod === 365}>1 año</Button>
                                        <Button onClick={() => handleTablePeriod(1825)} disabled={tablePeriod === 1825}>5 años</Button>
                                    </ButtonGroup>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <FormControl sx={{ width: 190 }}>
                                                <DatePicker
                                                    label={<Typography variant='h6' color="primary">Desde</Typography>}
                                                    value={sinceValue}
                                                    InputAdornmentProps={{
                                                        className: 'datePickerCalendars'
                                                    }}
                                                    onChange={(newValue) => {
                                                        setSinceValue(moment(newValue));
                                                    }}
                                                    renderInput={(params) => <TextField className="datePickerContainer" size='small' {...params} />}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ width: 190 }}>
                                                <DatePicker
                                                    label={<Typography variant='h6' color="primary">Hasta</Typography>}
                                                    value={untilValue}
                                                    InputAdornmentProps={{
                                                        className: 'datePickerCalendars'
                                                    }}
                                                    onChange={(newValue) => {
                                                        setUntilValue(moment(newValue));
                                                    }}
                                                    renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </LocalizationProvider>
                                </Box>
                            </Card>
                        </Grid>
                        {/* <Typography variant='h5' fontWeight={600}>
                            Órdenes vigentes
                        </Typography>
                        <Card className="paying-company-cards">
                            <SegmentedStackBarChart
                                data={tasasMercado}
                            />
                        </Card> */}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={dialogOpen}
                PaperProps={
                    {
                        className: 'modal-paper'
                    }}
            >
                <DialogTitle id="alert-dialog-title" color='primary'>
                    <div>
                        <WarningAmber style={{ fontSize: 100, color: 'yellow' }} />
                    </div>
                    {modalTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color='primary'>
                        {modalContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' size='large' onClick={handleCloseDialog}>Cancelar</Button>
                    <Button variant='outlined' color='buttonOutlined' size='large' onClick={acceptDialog}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}