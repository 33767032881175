import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../libs/contextlib'
import { Container } from '@mui/material'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import SearchBar from '../../Components/SearchBar/SearchBar'
import AdministrarFondos from './LimitesDiarios'
import DatosFondos from './FundsInfo'

import axios from 'axios'

import './fondos.css'
import LimitesDiarios from './LimitesDiarios'
import LimitesTotales from './LimitesTotales'
import AdministrarHorarios from './AdministrarHorarios'

export default function Fondos() {
    const {
        fundView,
        activeFund,
        setActiveFund,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatAmount,
        hostService
    } = useAppContext();

    const [reviewingFund, setReviewingFund] = useState(activeFund.nombre);
    const [fundsData, setFundsData] = useState([]);
    const [payerData, setPayerData] = useState([]);
    const [search, setSearch] = useState('');
    const [changeAmount, setChangeAmount] = useState(false);
    const [changeAmountPayer, setChangeAmountPayer] = useState(false);

    const handleSetActiveFund = (fundName) => {
        axios.post(hostService + `servicios/Compradores.asmx/ActivarFondoPorComprador`, { idFondo: fundsData.filter(object => object.nombre === fundName)[0].id }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Fondo Activado');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                    setActiveFund(fundsData.filter(object => object.nombre === fundName)[0]);
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetFondosDelComprador`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setFundsData(respuesta.fondos);
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [activeFund, changeAmount]);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetPagadorasDelComprador`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setPayerData(respuesta.pagadoras);
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [changeAmountPayer]);

    return (
        <Container maxWidth='xxl'>
            <Grid container className='fondos-container'>
                <Grid item xs={12} className='buttons-container'>
                    {/* <Button variant='contained' color='buttonColor'>
                        Descargar XML
                    </Button>
                    <Button variant='contained' color='buttonColor'>
                        Descargar PDF
                    </Button> */}
                </Grid>
                <Grid item xs={12}>
                    {fundView === 'dailyLimits' ?
                    <LimitesDiarios
                        fundsData={fundsData}
                        payersData={payerData}
                        setReviewingFund={setReviewingFund}
                        handleSetActiveFund={handleSetActiveFund}
                        search={search}
                        setSearch={setSearch}
                        changeAmount={changeAmount}
                        setChangeAmount={setChangeAmount}
                        changeAmountPayer={changeAmountPayer}
                        setChangeAmountPayer={setChangeAmountPayer}
                    />
                    : fundView === 'totalLimits' ?
                    <LimitesTotales
                        fundsData={fundsData}
                        payersData={payerData}
                        setReviewingFund={setReviewingFund}
                        handleSetActiveFund={handleSetActiveFund}
                        search={search}
                        setSearch={setSearch}
                        changeAmount={changeAmount}
                        setChangeAmount={setChangeAmount}
                        changeAmountPayer={changeAmountPayer}
                        setChangeAmountPayer={setChangeAmountPayer}
                    />
                    : fundView === 'schedules' ?
                    <AdministrarHorarios
                        fundsData={fundsData}
                        payersData={payerData}
                        setReviewingFund={setReviewingFund}
                        handleSetActiveFund={handleSetActiveFund}
                        search={search}
                        setSearch={setSearch}
                        changeAmount={changeAmount}
                        setChangeAmount={setChangeAmount}
                        changeAmountPayer={changeAmountPayer}
                        setChangeAmountPayer={setChangeAmountPayer}
                    />
                    : 
                    <DatosFondos
                        fundsData={fundsData}
                        reviewingFund={reviewingFund}
                        setReviewingFund={setReviewingFund}
                        formatAmount={formatAmount}
                        activeFund={activeFund}
                        handleSetActiveFund={handleSetActiveFund}
                    />
                    }
                </Grid>
            </Grid>
        </Container>
    )
}