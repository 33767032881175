import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Modal from '@mui/material/Modal'
import Paginations from '../../../Components/Pagination/Paginations'
import Tables from '../../../Components/Tables/Table'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import LoadingButton from '@mui/lab/LoadingButton';

export default function CollectionCalendar({
    rows,
    headers,
    labels,
    openModal,
    handleCloseModal,
    modalData,
    handleRejectExtension,
    handleAcceptExtension,
    collectionData,
    search,
    setSearch,
    chargingSend,
    downloadExcel,
    downloadPdf
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [withTotals, setWithTotals] = useState(true);

    const handleDownloadExcel = () => {
        let title = 'Calendario de recaudación';
        let headerRow = {};
        headers.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((e) => {
            dataRows.push({
                payer: e.payer.props.children === "Totales" ? "Totales" : e.payer.props.children[1],
                d1: e.d1,
                d2: e.d2,
                d3: e.d3,
                d4: e.d4,
                d5: e.d5,
                d6: e.d6,
                d7: e.d7,
                d8: e.d8,
                d9: e.d9,
                d10: e.d10,
                d11: e.d11,
                d12: e.d12,
            })
            return dataRows;
        });

        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Calendario de recaudación';
        let headerRow = {};
        headers.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((e) => {
            dataRows.push({
                payer: e.payer.props.children === "Totales" ? "Totales" : e.payer.props.children[1],
                d1: e.d1,
                d2: e.d2,
                d3: e.d3,
                d4: e.d4,
                d5: e.d5,
                d6: e.d6,
                d7: e.d7,
                d8: e.d8,
                d9: e.d9,
                d10: e.d10,
                d11: e.d11,
                d12: e.d12,
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            let filtered = rows.filter((element) => {
                if(element.payer.props.children !== "Totales")
                    return element.payer.props.children[1].toLocaleLowerCase().includes(search.toLocaleLowerCase())
                return null
            })
            setWithTotals(false);
            setCurrentTransactions(filtered);
        } else {
            setWithTotals(true);
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [collectionData]);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Calendario de recaudación
                </Typography>
                <Typography variant="h7" color='secondary'>
                    *Montos en Millones de Pesos
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                                type={'blueNumbers'}
                                withTotal={withTotals}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length / 14)}
                />
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box className='collection-modal'>
                    <Grid container direction='column'>
                        <Typography variant='h6' fontWeight={600}>
                            Solicitudes Pendientes de Aprobación
                        </Typography>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                Monto:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    <span>$ </span>{modalData.length > 0 ? modalData[0].monto : 0}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                Tasa:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    {modalData.length > 0 ? modalData[0].tasa : 0}<span> %</span>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                N° de Facturas:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    {modalData.length > 0 ? modalData[0].nFacturas : 0}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                Semana de Vencimiento Original:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    {modalData.length > 0 ? modalData[0].vencimientoOriginal : 0}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                Solicitud Nueva Fecha Vencimiento:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    {modalData.length > 0 ? modalData[0].solicitudNuevaFecha : 0}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='modal-item'>
                            <Typography variant='body1'>
                                Status:
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                    {modalData.length > 0 ? modalData[0].status : 0}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Box className='modal-action'>
                        <Typography onClick={() => handleRejectExtension(modalData.length > 0 ? modalData[0].extensionId : 0)} variant='body1'>
                            {!chargingSend ? "Rechazar" : ""}
                        </Typography>
                        {chargingSend ?
                            <LoadingButton loading variant="contained" color='buttonColor'>
                                Aceptar
                            </LoadingButton>
                            :
                            <Button onClick={() => handleAcceptExtension(modalData.length > 0 ? modalData[0].extensionId : 0)} variant='contained' color='buttonColor'>
                                Aceptar
                            </Button>}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}