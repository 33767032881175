import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'

export default function AcceptReprogramming({
    headers,
    labels,
    rows,
    search,
    setSearch,
    notificationsData,
    downloadExcel,
    downloadPdf
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);

    const handleDownloadExcel = () => {
        let title = 'Aceptar reprogramaciones';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                payer: object.payer,
                quantity: object.quantity,
                totalAmount: object.totalAmount,
                previousPayWeek: object.previousPayWeek,
                newPayWeek: object.newPayWeek
            })
            return dataRows;
        });

        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Aceptar reprogramaciones';
        let headerRow = {};
        headers.slice(0, headers.length - 1).map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });
   
        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                payer: object.payer,
                quantity: object.quantity,
                totalAmount: object.totalAmount,
                previousPayWeek: object.previousPayWeek,
                newPayWeek: object.newPayWeek
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.payer.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }
            ));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [notificationsData]);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Aceptar reprogramaciones
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                headers={headers}
                                labels={labels}
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                withTotal={false}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length / 14)}
                />
            </Box>
        </>
    )
}