import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CodigoVerificacion from './NuevoUsuarioViews/CodigoVerificacion'
import IngresarClave from './NuevoUsuarioViews/IngresarClave'

import logotipo from '../../assets/images/logotipo.png'
import axios from 'axios'
import './nuevoUsuario.css'
import moment from 'moment'

export default function NuevoUsuario() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        resultPassword,
        hostService 
    } = useAppContext();

    const [view, setView] = useState('CodigoVerificacion');
    const [securityCode, setSecurityCode] = useState('');
    const [rut, setRut] = useState('');
    const [dataUsuario, setDataUsuario] = useState({});
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [messagePassword, setMessagePassword] = useState('');

    const handleSendCode = (rut) => {
        axios.post(hostService + `servicios/conector.asmx/RegistroCompradorAvance`, { textRut: rut/*, codigo: securityCode*/ }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    let respuesta = JSON.parse(response.data.d);
                    setDataUsuario(respuesta);
                    setView('IngresarClave');
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleSetPassword = (passwordValue, password2Value) => {
        if (passwordValue && password2Value) {
            if (passwordValue !== password2Value) {
                setMessagePassword('Las contraseñas no coinciden');
            } else {
                setMessagePassword(resultPassword(passwordValue));
            }
        }else{
            setMessagePassword('');
        }
        setPassword(passwordValue);
        setPassword2(password2Value);
    }

    const handleSendPassword = () => {
        if (password && password2) {
            axios.post(hostService + `servicios/conector.asmx/ActualizarPassword`, { clave: password }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorFormatoClave') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Error de formato');
                        setAlertMessageDescription('La contraseña no cumple con el formato solicitado');
                        setAlertMessageType('error');
                    } else if (response.data.d === 'ok') {
                        axios.post(hostService + `servicios/conector.asmx/ObtenerLogin`, { username: rut, password: password }, { withCredentials: true })
                            .then(response2 => {
                                var respuesta = JSON.parse(response2.data.d);
                                if (respuesta.tipo) {
                                    setAlertMessageOpen(true);
                                    setAlertMessageTitle('ERROR');
                                    setAlertMessageDescription('Rut o Contraseña incorrectos');
                                    setAlertMessageType('error');
                                } else {
                                    window.location = "/";
                                }
                            }).catch(error => {
                                setAlertMessageOpen(true);
                                setAlertMessageTitle('Ocurrió un error inesperado');
                                setAlertMessageDescription('Inténtalo nuevamente');
                                setAlertMessageType('error');
                            });
                    } else {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    }
                }).catch(error => {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        } else {
            setAlertMessageOpen(true);
            setAlertMessageTitle('Debes ingresar una contraseña');
            setAlertMessageDescription('');
            setAlertMessageType('error');
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('key')) window.location = "/login";
        var key = urlParams.get('key');

        axios.post(hostService + `servicios/Compradores.asmx/GetInfoKey`, { key: key }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'error') {
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setRut(respuesta.rut);
                    //Esto se debe borrar cuando exista código de seguridad
                    handleSendCode(respuesta.rut);
                }
            }).catch(error => {
                console.log(error);
                window.location = "/login";
            });
    }, []);


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "100vh" }}
        >
            <Grid item xs={6}>
                <Card className="nuevousuario-card">
                    <CardContent xs={4} className='card-content'>
                        <img className='logotipo' src={logotipo} alt="logo btrust" />
                        {view === 'CodigoVerificacion' ?
                            <CodigoVerificacion
                                rut={rut}
                                securityCode={securityCode}
                                setSecurityCode={setSecurityCode}
                                handleSendCode={handleSendCode}
                            />
                            :
                            view === 'IngresarClave' ?
                                <IngresarClave
                                    dataUsuario={dataUsuario}
                                    password={password}
                                    handleSetPassword={handleSetPassword}
                                    password2={password2}
                                    messagePassword={messagePassword}
                                    handleSendPassword={handleSendPassword}
                                />
                                :
                                null
                        }
                    </CardContent>
                </Card>
                <Typography align='center'>
                    Plataforma de negocios
                </Typography>
                <Typography align='center'>
                    {moment().get().format("YYYY")} Copyright - btrust
                </Typography>
            </Grid>
        </Grid>
    )
}