import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../libs/contextlib';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import esLocale from 'date-fns/locale/es';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import axios from 'axios';
import moment from 'moment'

import LineChart from '../../Components/LineChart/LineChart'
import PieCharts from '../../Components/PieChart/PieCharts'
import RateTables from '../../Components/RateTables/RateTables';

import './home.css';

export default function Home() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        hostService,
        formatFee
    } = useAppContext();

    const [tablePeriod, setTablePeriod] = useState(0);
    const [sinceValue, setSinceValue] = useState(moment().get());
    const [untilValue, setUntilValue] = useState(moment().get());
    const [datosTasas, setDatosTasas] = useState([]);
    const [datosGrafico, setDatosGrafico] = useState([{}, {}]);
    const [datosCartera, setDatosCartera] = useState([]);

    const handleTablePeriod = (event) => {
        setTablePeriod(event);
        setUntilValue(moment().get());
        setSinceValue(moment().subtract(event, 'days'));
    }

    const TablaOfertasComprador = () => {
        axios.post(hostService + `servicios/conector.asmx/TablaOfertasComprador`, { }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    respuesta.ofertas.map((object) => data.push({
                        name: object.pagadora,
                        last: formatFee(object.ultimaTasa),
                        amount: object.monto,
                        max: formatFee(object.mayorTasa),
                        min: formatFee(object.menorTasa),
                        avrg: formatFee(object.promedioTasa)
                    }));
                    setDatosTasas(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        TablaOfertasComprador();
        const interval = setInterval(TablaOfertasComprador, 30000);
        return () => { clearInterval(interval) };
    }, []);

    const ObtenerDataFondoTransacciones = () => {
        axios.post(hostService + `servicios/Compradores.asmx/ObtenerDataFondoTransacciones`, { fechaDesde: sinceValue.format('YYYY-MM-DD'), fechaHasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [{}, {}];
                    respuesta.Response.map((object) => data.push({ time: new Date(object.FechaOferta).getTime(), rate: (object.TasaMinima), amount: (object.MontoTotal / 1000000) }));
                    setDatosGrafico(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        ObtenerDataFondoTransacciones();
        const interval = setInterval(ObtenerDataFondoTransacciones, 30000);
        return () => { clearInterval(interval) };
    }, [sinceValue, untilValue]);

    // const MontosCarteraPorComprador = () => {
    //     axios.post(hostService + `servicios/conector.asmx/MontosCarteraPorComprador`, {}, { withCredentials: true })
    //         .then(response => {
    //             if (response.data.d === 'errorSession') {
    //                 setAlertMessageOpen(true);
    //                 setAlertMessageTitle('La sesión ha caducado');
    //                 setAlertMessageDescription('');
    //                 setAlertMessageType('error');
    //                 window.location = "/login";
    //             } else {
    //                 var respuesta = JSON.parse(response.data.d);
    //                 const data = [];
    //                 respuesta.montos.map(object =>
    //                     data.push({ name: object.pagadora, value: object.porcentaje }));
    //                 setDatosCartera(data);
    //             }
    //         });
    // }

    // useEffect(() => {
    //     MontosCarteraPorComprador();
    //     const interval = setInterval(MontosCarteraPorComprador, 30000);
    //     return () => { clearInterval(interval) };
    // }, []);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} className='home-grid-container'>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    {/* <ToggleButtonGroup
                        value={period}
                        exclusive
                        onChange={handlePeriod}
                        aria-label="text alignment"
                        className="toggleButtons"
                    >
                        <ToggleButton color='buttonOutlined' value="minus" aria-label="left aligned">
                            Menos de 7 días
                        </ToggleButton>
                        <ToggleButton color='buttonOutlined' value="more" aria-label="centered">
                            Más de 7 días
                        </ToggleButton>
                    </ToggleButtonGroup> */}
                </Grid>
                <Grid xs={12} className='top-charts' item>
                    <Typography variant='h5' fontWeight={600}>
                        Mis transacciones
                    </Typography>
                    <Card className="home-cards home-cards-line">
                        <LineChart
                            data={datosGrafico}
                            sinceValue={sinceValue}
                            untilValue={untilValue}
                            leftTitle={"tasa promedio %"}
                            rightTitle={"$ M"}
                        />
                    </Card>
                </Grid>
                {/* <Grid xs={4} className='top-charts' item>
                    <Typography variant='h6' color='primary'>
                        Cartera Propia
                    </Typography>
                    <Card className="home-cards home-cards-pie" style={{ height: 400 }}>
                        <PieCharts
                            data={datosCartera}
                        />

                    </Card>
                </Grid> */}
                <Grid xs={12} item >
                    <Card className="home-cards home-cards-line" style={{ height: 80, marginTop: 5, textAlign: 'center', background: '#292929' }}>
                            <ButtonGroup>
                                <Button onClick={() => handleTablePeriod(0)} disabled={tablePeriod === 0}>1 día</Button>
                                <Button onClick={() => handleTablePeriod(7)} disabled={tablePeriod === 7}>1 semana</Button>
                                <Button onClick={() => handleTablePeriod(30)} disabled={tablePeriod === 30}>1 mes</Button>
                                <Button onClick={() => handleTablePeriod(90)} disabled={tablePeriod === 90}>3 meses</Button>
                                <Button onClick={() => handleTablePeriod(180)} disabled={tablePeriod === 180}>6 meses</Button>
                                <Button onClick={() => handleTablePeriod(365)} disabled={tablePeriod === 365}>1 año</Button>
                                <Button onClick={() => handleTablePeriod(1825)} disabled={tablePeriod === 1825}>5 años</Button>
                            </ButtonGroup>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Desde</Typography>}
                                            value={sinceValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setSinceValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" size='small' {...params} />}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Hasta</Typography>}
                                            value={untilValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setUntilValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </LocalizationProvider>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{
                    height: '38vh',
                    overflow: 'auto',
                    marginTop: '30px'
                }}>
                    <Typography variant='h5' fontWeight={600}>
                        Ofertas del mercado
                    </Typography>
                    <Card style={{ padding: "10px 20px", marginTop: "10px" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <RateTables
                                    rows={datosTasas}
                                // rows={datosTasas.slice(0, Math.round(datosTasas.length / 2))}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <RateTables
                                    rows={datosTasas.slice(Math.round(datosTasas.length / 2), datosTasas.length)}
                                />
                            </Grid> */}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}