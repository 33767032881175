import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import esLocale from 'date-fns/locale/es';
import moment from 'moment'

import Modal from '@mui/material/Modal'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function AllTransactions ({
    rows,
    headers,
    labels,
    sinceValue,
    setSinceValue,
    modalTransactionsOpen,
    handleToggleModalTransactions,
    infoId,
    untilValue,
    setUntilValue,
    search,
    setSearch,
    downloadExcel,
    downloadPdf
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [headersFacturas, setHeadersFacturas] = useState(['Razón social emisor', 'Rut emisor', 'Fecha emisión', 'Tipo documento', 'Folio', 'Monto original ($)']);
    const [headersOperaciones, setHeadersOperaciones] = useState(['Razón social cesionario', 'Rut cesionario', 'Tasa operación (%)']);

    const handleDownloadExcel = () => {
        let title = 'Transacciones históricas';
        let newHeaders = headers.splice(0, headers.length - 1).concat(headersFacturas).concat(headersOperaciones)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                id: object.id,
                company: object.company,
                rut: object.rut,
                date: object.date,
                paymentDate: object.paymentDate,
                amountCeded: object.amountCeded,
                amountBought: object.amountBought,
                comission: object.comission,
                difference: object.difference,
                deposit: object.deposit,
                razonSocialEmisor: object.razonSocialEmisor,
                rutEmisor: object.rutEmisor,
                emissionDate: object.emissionDate,
                tipoFolio: object.tipoFolio,
                folio: object.folio,
                montoOriginal: object.montoOriginal,
                razonSocialCesionario: object.razonSocialCesionario,
                rutCesionario: object.rutCesionario,
                tasa: object.tasa
            })
            return dataRows;
        });
   
        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Transacciones históricas';
        let newHeaders = headers.splice(0, headers.length - 1).concat(headersFacturas).concat(headersOperaciones)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });

        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                id: object.id,
                company: object.company,
                rut: object.rut,
                date: object.date,
                paymentDate: object.paymentDate,
                amountCeded: object.amountCeded,
                amountBought: object.amountBought,
                comission: object.comission,
                difference: object.difference,
                deposit: object.deposit,
                razonSocialEmisor: object.razonSocialEmisor,
                rutEmisor: object.rutEmisor,
                emissionDate: object.emissionDate,
                tipoFolio: object.tipoFolio,
                folio: object.folio,
                montoOriginal: object.montoOriginal,
                razonSocialCesionario: object.razonSocialCesionario,
                rutCesionario: object.rutCesionario,
                tasa: object.tasa
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.company.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || element.rut.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || String(element.number).includes(search)
                    || element.id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rows]);

    return (
        <>
            <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: 'center', marginBottom: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <FormControl sx={{ width: 250, marginRight: 5 }}>
                        <DatePicker
                            label={<Typography variant='h6' color="primary">Desde</Typography>}
                            value={sinceValue}
                            InputAdornmentProps={{
                                className: 'datePickerCalendars'
                            }}
                            onChange={(newValue) => {
                                setSinceValue(moment(newValue));
                            }}
                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                        />
                    </FormControl>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <DatePicker
                        label={<Typography variant='h6' color="primary">Hasta</Typography>}
                        value={untilValue}
                        InputAdornmentProps={{
                            className: 'datePickerCalendars'
                        }}
                        onChange={(newValue) => {
                            setUntilValue(moment(newValue));
                        }}
                        renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Transacciones históricas
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length/14)}
                />
            </Box>
            <Modal
                open={modalTransactionsOpen}
                onClose={() => handleToggleModalTransactions()}
            >
                <Box className='transactions-modal'>
                    <Grid container>
                        <Grid item sx={{width: '100%'}}>
                            <Typography variant="h5" fontWeight={600} sx={{marginBottom: 2}}>
                                Información de la factura
                            </Typography>
                            <Tables
                                rows={currentTransactions.filter(x => x.id === infoId)}
                                headers={headersFacturas}
                                labels={['razonSocialEmisor', 'rutEmisor', 'emissionDate', 'tipoFolio', 'folio', 'montoOriginal']}
                            />
                        </Grid>
                        <Grid item sx={{marginTop: 5, width: '100%'}} >
                            <Typography variant="h5" fontWeight={600} sx={{marginBottom: 2}}>
                                Información de la operación
                            </Typography>
                            <Tables
                                rows={currentTransactions.filter(x => x.id === infoId)}
                                headers={headersOperaciones}
                                labels={['razonSocialCesionario', 'rutCesionario', 'tasa']}
                            />
                        </Grid>
                        <Grid item sx={{ marginTop: 2,  width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant='contained'
                                color='buttonColor'
                                onClick={() => handleToggleModalTransactions()}
                            >
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}
