import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Modal from '@mui/material/Modal'

export default function PayingSummary ({
    rows,
    headers,
    labels,
    openModalSummary,
    setOpenModalSummary,
    rowsInfo,
    search,
    setSearch,
    downloadExcel,
    downloadPdf
}){    
    const [page, setPage] = useState(0);
    const [pageModal, setPageModal] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [currentTransactionsInfo, setCurrentTransactionsInfo] = useState(rowsInfo);
    const [headersInfo, setHeadersInfo] = useState(['Tasa promedio (%)', 'Monto disponible ($)']);
    const [labelsInfo, setLabelsInfo] = useState(['averageRate', 'availableAmount']);

    useEffect(() => {
        if (search) {
            setPage(0);
            setCurrentTransactions(rows.filter((element) => {
                return element.payingName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }));
        } else {
            setCurrentTransactions(rows);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(rows);
    }, [rows]);

    useEffect(() => {
        setCurrentTransactionsInfo(rowsInfo);
    }, [rowsInfo]);

    const handleDownloadExcel = () => {
        let title = 'Resumen de pagadoras';
        let newHeaders = headers.splice(0, headers.length - 1)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });
        
        const dataRows = [];
        currentTransactions.map((object) => {
            dataRows.push({
                payingName: object.payingName,
                averageRate: typeof object.averageRate === typeof object ? object.averageRate.props.children : object.averageRate,
                availableAmount: typeof object.availableAmount === typeof object ? object.availableAmount.props.children : object.availableAmount,
            })
            return dataRows;
        });
   
        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Resumen de pagadoras';
        let newHeaders = headers.splice(0, headers.length - 1)
        let headerRow = {};
        newHeaders.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });
        
        const dataRows = [];
        currentTransactions.map((object) => {
            console.log(object)
            dataRows.push({
                payingName: object.payingName,
                averageRate: typeof object.averageRate === typeof object ? object.averageRate.props.children : object.averageRate,
                availableAmount: typeof object.availableAmount === typeof object ? object.availableAmount.props.children : object.availableAmount,
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    const handleDownloadExcelModal = () => {
        let title = 'Competencia de tasas';
        let headerRow = {};
        headersInfo.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });
        
        const dataRows = [];
        rowsInfo.map((object) => {
            dataRows.push({
                averageRate: typeof object.averageRate === typeof object ? object.averageRate.props.children : object.averageRate,
                availableAmount:  typeof object.availableAmount === typeof object ? object.availableAmount.props.children : object.availableAmount,
            })
            return dataRows;
        });
   
        downloadExcel(title, headerRow, dataRows);
    }

    const handleDownloadPdfModal = () => {
        let title = 'Competencia de tasas';
        let headerRow = {};
        headersInfo.map(e =>  {
            let element = {};
            element[e] = e 
            headerRow = {...headerRow, ...element}
            return element;
        });
        
        const dataRows = [];
        rowsInfo.map((object) => {
            console.log(typeof object.averageRate === typeof object)
            dataRows.push({
                averageRate: typeof object.averageRate === typeof object ? object.averageRate.props.children : object.averageRate,
                availableAmount:  typeof object.availableAmount === typeof object ? object.availableAmount.props.children : object.availableAmount,
            })
            return dataRows;
        });

        downloadPdf(title, headerRow, dataRows);
    }

    return (
        <>
            <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
            }}
            >
                <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Resumen de pagadoras
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length/14)}
                />
            </Box>

            <Modal
                open={openModalSummary}
                onClose={() => {
                    setPageModal(0)
                    setOpenModalSummary(false)
                }}
            >
                <Box className='payingSummary-modal'>
                    <Grid container>
                        <Grid item xs={12} className='buttons-container'>
                            <Button onClick={handleDownloadExcelModal}
                                variant='contained'
                                color='buttonColor'
                            >
                                Descargar Excel
                            </Button>
                            <Button onClick={handleDownloadPdfModal}
                                variant='contained'
                                color='buttonColor'>
                                Descargar PDF
                            </Button>
                        </Grid>
                        <Typography variant="h5" fontWeight={600} sx={{ marginBottom: 2 }}>
                            Competencia de tasas
                        </Typography>
                        <Grid item xs={12}>
                            <Tables
                                rows={currentTransactionsInfo.slice((pageModal * 10), (pageModal * 10) + 10)}
                                headers={headersInfo}
                                labels={labelsInfo}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                        <Paginations
                            onChange={(event, val) => { setPageModal(val - 1) }}
                            count={Math.ceil(currentTransactionsInfo.length / 10)}
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

