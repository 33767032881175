import React, { useEffect, useState } from "react";
import { useAppContext } from '../../libs/contextlib'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from 'axios'

//import perfilData from '../../mockData/Perfil/perfilData'

import "./perfil.css";

export default function Perfil() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        resultPassword,
        hostService 
    } = useAppContext();

    const [userData, setUserData] = useState({});
    const [messagePassword, setMessagePassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const labels = ["Rut", "Nombre", "Email", "Teléfono", "Rut Comprador", "Comprador"];
    const dataLabel = ["rutUsuario", "nombreUsuario", "emailUsuario", "telefonoUsuario", "rutComprador", "nombreComprador"];

    const handleSetPassword = (passwordValue, password2Value) => {
        if (passwordValue && password2Value) {
            if (passwordValue !== password2Value) {
                setMessagePassword('Las contraseñas no coinciden');
            } else {
                setMessagePassword(resultPassword(passwordValue));
            }
        }else{
            setMessagePassword('');
        }
        setPassword(passwordValue);
        setPassword2(password2Value);
    }

    const handleSendPassword = () => {
        if (password && password2) {
            axios.post(hostService + `servicios/conector.asmx/ActualizarClave`, { textPass1: password }, { withCredentials: true })
                .then(response => {
                    if (response.data.d === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else if (!JSON.parse(response.data.d).tipo) {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Contraseña actualizada');
                        setAlertMessageDescription('');
                        setAlertMessageType('success');
                        setPassword('');
                        setPassword2('');
                    } else {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    }
                }).catch(error => {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        } else {
            setAlertMessageOpen(true);
            setAlertMessageTitle('Debes ingresar una contraseña');
            setAlertMessageDescription('');
            setAlertMessageType('error');
        }
    }

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetInfoUsuario`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d !== 'error') {
                    var respuesta = JSON.parse(response.data.d);
                    setUserData(respuesta);
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, []);

    return (
        <Container maxWidth="xxl">
            <Grid container className="perfil-container">
                <Card className="perfil-card">
                    <Grid container>
                        <Grid item xs={12} className="info-container">
                            {labels.map((e, i) => {
                                return (
                                    <div key={e} className="info-items">
                                        <Typography className="info-titles" color="primary">
                                            {e}
                                        </Typography>
                                        <Box className="info-boxes">
                                            <Typography color="primary" fontWeight={600}>
                                                {userData[dataLabel[i]]}
                                            </Typography>
                                        </Box>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Box className="password-container">
                        <div className="info-items">
                            <Typography className="info-titles" color="primary">
                                Contraseña
                            </Typography>
                            <TextField
                                sx={{ input: { color: '#fff' } }}
                                color='primary'
                                variant="outlined"
                                className="textfield"
                                type='password'
                                value={password}
                                onChange={(event) => handleSetPassword(event.target.value, password2)}
                            />
                            <Typography className="info-titles" color="primary" sx={{marginTop: '10px'}}>
                                Repetir contraseña
                            </Typography>
                            <TextField
                                sx={{ input: { color: '#fff' } }}
                                color='primary'
                                variant="outlined"
                                className="textfield"
                                type='password'
                                value={password2}
                                onChange={(event) => handleSetPassword(password, event.target.value)}
                            />
                        </div>
                        
                        <Button disabled={!password || !password2 || messagePassword !== ''} onClick={() => handleSendPassword()} variant="contained" color="buttonColor">
                            Cambiar contraseña
                        </Button>
                    </Box>
                    <Typography color="red" align='center' sx={{ marginTop: '10px' }}>
                        {messagePassword}
                    </Typography>
                </Card>
            </Grid>
        </Container>
    );
}
