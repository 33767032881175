import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField'
import TableWithSeparation from '../../../Components/Tables/TableWithSeparation'
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Paginations from '../../../Components/Pagination/Paginations'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import esLocale from 'date-fns/locale/es';
import moment from 'moment'


export default function MarketTransactions ({
    rows,
    headers,
    labels,
    setMarketTransactionsTableData,
    payingCompaniesData,
    sinceValue,
    setSinceValue,
    untilValue,
    setUntilValue
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [selectedPayers, setSelectedPayers] = useState([])
    const [period, setPeriod] = useState(0);
    
    const icon = <CheckBoxOutlineBlankIcon sx={{ color: 'blue' }} fontSize="small" />;
    const checkedIcon = <CheckBoxIcon sx={{ color: 'blue' }} fontSize="small" />;

    const handlePeriod = (event) => {
        setPeriod(event);
        setUntilValue(moment().get());
        setSinceValue(moment().subtract(event, 'days'));
    }

    useEffect(() => {
        if(selectedPayers.length > 0)
            setCurrentTransactions(rows.filter((object) => selectedPayers.some(item => item.id === object.payerId)))
        else
            setCurrentTransactions(rows)
    }, [rows]);

    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={2}>
                    <Autocomplete
                        id="payers-tags"
                        multiple
                        options={payingCompaniesData}
                        disableCloseOnSelect
                        renderTags={ () => {} }
                        onChange={(event, newValue) => {
                            setSelectedPayers(newValue)
                            if(newValue.length > 0)
                                setCurrentTransactions(rows.filter((object) => newValue.some(item => item.id === object.payerId)))
                            else
                                setCurrentTransactions(rows)
                          }}
                        noOptionsText='No se encuentran pagadoras'
                        getOptionLabel={(option) =>  option.nombre }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.nombre}
                            </li>
                        )}
                        style={{ width: '20vw', marginTop: '20px' }}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="h7" color="primary">Filiales pagadoras</Typography>} placeholder="Buscar..." />
                        )}
                    />

                </Grid>

                <Grid item xs={10}>
                    <Card className="paying-company-cards" style={{ height: 80, textAlign: 'right', background: '#262626' }} >
                        <Box>
                            <ButtonGroup size='small'>
                                <Button onClick={() => handlePeriod(0)} disabled={period === 0}>1 día</Button>
                                <Button onClick={() => handlePeriod(7)} disabled={period === 7}>1 semana</Button>
                                <Button onClick={() => handlePeriod(30)} disabled={period === 30}>1 mes</Button>
                                <Button onClick={() => handlePeriod(90)} disabled={period === 90}>3 meses</Button>
                                <Button onClick={() => handlePeriod(180)} disabled={period === 180}>6 meses</Button>
                                <Button onClick={() => handlePeriod(365)} disabled={period === 365}>1 año</Button>
                                <Button onClick={() => handlePeriod(1825)} disabled={period === 1825}>5 años</Button>
                            </ButtonGroup>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Desde</Typography>}
                                            value={sinceValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setSinceValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" size='small' {...params} />}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Hasta</Typography>}
                                            value={untilValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setUntilValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </LocalizationProvider>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginTop: "10px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Transacciones de mercado
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TableWithSeparation
                                rows={currentTransactions.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(currentTransactions.length/14)}
                />
            </Box>
        </>
    )
}