import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './table.css'

import Paper from '@mui/material/Paper';

export default function TableStickyHeader({ rows, headers, labels, type, withTotal, minWidth = 650 }) {
    
    return (
        <TableContainer sx={{ maxHeight: '60vh' }} className='table standard-table'>
            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: minWidth }} >
                <TableHead>
                    <TableRow>
                        <TableCell className='sticky-header-cell' style={{ padding: "2px 0px" }}>{headers[0]}</TableCell>
                        {headers.slice(1).map((e, i) => {
                            let isLastElement = i === headers.slice(1).length - 1;
                            return <TableCell className='sticky-header-cell' style={{ padding: isLastElement ? "2px 10px" : "2px 0px" }} key={e} align="right">{e}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={row + i}
                            className={withTotal ? 'with-total' : null}
                        >
                            <TableCell className='sticky-cell' style={{ padding: "9px 0px" }}>{row[labels[0]]}</TableCell>
                            {labels.slice(1).map((e, i) => {
                                let isLastElement = i === headers.slice(1).length - 1;
                                return <TableCell className='sticky-cell' style={{ padding: isLastElement ? "2px 10px" : "2px 0px" }} key={e} align="right">
                                    <span style={{ color: (type === 'blueNumbers' && (row[e] > 0 || row[e] !== '0')) ? '#A3BAFF' : null }}>
                                        {row[e]}
                                    </span>
                                </TableCell>
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}