import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import '../nuevoUsuario.css'


export default function CodigoVerificacion({
    rut,
    securityCode,
    setSecurityCode,
    handleSendCode
}) {
    return (
        <Grid>
            <Box
                component='form'
                className='form-container'
            >
                <Typography color="secondary" variant='inherit' align='justify' sx={{ margin: '20px 0px' }}>
                    Para formar parte de btrust debes haber recibido el correo que te trajo aquí y un código enviado a tu celular para poder registrarte.
                    Si no has recibido el código, puedes contactarnos por medio de contacto@btrust-finance.com
                </Typography>
                <Typography color="primary" variant='h6'>
                    Rut
                </Typography>
                <Box className='info-boxes'>
                    <Typography color="primary" variant='h6' fontWeight={500}>
                        {rut}
                    </Typography>
                </Box>
                <Typography color="primary" variant='h6' sx={{ marginTop: '20px' }}>
                    Código de seguridad
                    <Typography color="secondary">
                        (Debió llegar a tu celular)
                    </Typography>
                </Typography>
                <TextField
                    sx={{ input: { color: '#fff' } }}
                    color='primary'
                    variant="outlined"
                    className="textfield"
                    value={securityCode}
                    onChange={(event) => setSecurityCode(event.target.value.trim().toUpperCase())}
                />
            </Box>
            <Box
                className='buttons-container'
            >
                <Button onClick={() => handleSendCode()} disabled={ securityCode.length !== 4 } variant='contained' color='buttonColor' sx={{ width: '180px', marginBottom: '10px' }}>
                    <Typography>
                        Continuar
                    </Typography>
                </Button>
            </Box>
        </Grid>
    )
}