import React, { useState } from 'react'
import { useAppContext } from '../../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl';
import esLocale from 'date-fns/locale/es';
import moment from 'moment'
import axios from 'axios'

import downloadIcon from '../../../assets/images/download-icon.png'

export default function Certificados() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        downloadBase64,
        downloadingOpen,
        setDownloadingOpen,
        setDownloadingTitle,
        hostService
    } = useAppContext();

    const [certificateSinceValue, setCertificateSinceValue] = useState(moment().get());
    const [certificateUntilValue, setCertificateUntilValue] = useState(moment().get());

    const handleDownloadCertificates = () => {
        setDownloadingTitle('Descargando Archivo de Certificados de Cesión');
        setDownloadingOpen(true);
        axios.post(hostService + `servicios/Compradores.asmx/GetCertificadosCesion`, { desde: certificateSinceValue.format('YYYY-MM-DD'), hasta: certificateUntilValue.format('YYYY-MM-DD') }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay operaciones por descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '20px',
                justifyContent: 'center',
                height: '85vh'
            }}>
                <Typography color='primary' variant="h5" fontWeight={700} align='center' sx={{ marginBottom: '20px' }}>
                    Descargar certificados de propiedad desde el SII
                </Typography>
                <Card className='certificados-card-container'>
                    <Box display='flex' alignItems='center'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <FormControl sx={{ width: 250, marginRight: 5 }}>
                                <DatePicker
                                    label={<Typography variant='h6' color="primary">Desde</Typography>}
                                    value={certificateSinceValue}
                                    InputAdornmentProps={{
                                        className: 'datePickerCalendars'
                                    }}
                                    onChange={(newValue) => {
                                        setCertificateSinceValue(moment(newValue));
                                    }}
                                    renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                />
                            </FormControl>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <FormControl sx={{ width: 250 }}>
                                <DatePicker
                                    label={<Typography variant='h6' color="primary">Hasta</Typography>}
                                    value={certificateUntilValue}
                                    InputAdornmentProps={{
                                        className: 'datePickerCalendars'
                                    }}
                                    onChange={(newValue) => {
                                        setCertificateUntilValue(moment(newValue));
                                    }}
                                    renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography color='secondary' align='center' sx={{ margin: '20px 0' }}>
                            Descargar los certificados de cesión de todas las facturas.
                        </Typography>
                        <Button onClick={() => handleDownloadCertificates()} disabled={downloadingOpen} variant='contained' color='buttonColor'>
                            <img
                                style={{
                                    width: '17px',
                                    marginRight: '10px'
                                }}
                                src={downloadIcon}
                                alt="descargar"
                            />
                            Descargar
                        </Button>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    )
}