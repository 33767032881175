import * as React from 'react';
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';

const blue = {
  500: '#3760D9',
  100: '#6E8EEF'
};

const grey = {
  400: '#BFBFBF',
  500: '#FFFFFF',
};

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 15px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 3px;
    border-radius: 20px;
    background: radial-gradient(${grey[500]}, ${grey[400]});
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 21px;
      top: 2px;
      background: radial-gradient(${blue[100]}, ${blue[500]});
    }

    .${switchUnstyledClasses.track} {
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export default function Switch({
    handleChange,
    val,
    checked,
    disabled
}) {

  return (
    <div>
        <SwitchUnstyled
            component={Root}
            onChange={() => handleChange(val)}
            value={val}
            checked={checked === val}
            disabled={disabled}
        />
    </div>
  );
}