import React, { useState } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import {Link as MuiLink} from '@mui/material'
import Button from '@mui/material/Button'
import axios from 'axios';
import moment from 'moment';

import logotipo from '../../assets/images/logotipo.png'
import './login.css'

export default function Login() {
    const {
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        hostService 
    } = useAppContext();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeUsername = (event) => {
        let value = event.target.value.replace('-', '').replace('.', '');
        if (value.match(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/)) {
            value = value.replace(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/, '$1$2$3-$4');
        }
        
        setUsername(value);
    }

    const handleIngresar = () => {
        if (username === '' || password === '') {
            setAlertMessageOpen(true);
            setAlertMessageTitle('Todos los campos son requeridos');
            setAlertMessageDescription('Debes ingresar tu Rut y Contraseña');
            setAlertMessageType('warning');
        } else {
            axios.post(hostService + `servicios/conector.asmx/ObtenerLogin`, { username: username, password: password }, { withCredentials: true })
            .then(response => {
                var respuesta = JSON.parse(response.data.d);
                if (respuesta.tipo) {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('ERROR');
                    setAlertMessageDescription('Rut o Contraseña incorrectos');
                    setAlertMessageType('error');
                } else {
                    window.location = "/home";
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{minHeight: "100vh"}}
        >
            <Grid item xs={4}>
                <Card className="login-card">
                    <CardContent className='card-content'>
                        <img className='logotipo' src={logotipo} alt="logo btrust" />
                        <Box
                            component='form'
                            className='form-container'
                        >
                            <Typography color="primary" variant='h6'>
                                Rut usuario
                            </Typography>
                            <TextField
                                sx={{ input: { color: '#fff' } }}
                                color='primary'
                                variant="outlined"
                                className="textfield"
                                value={username}
                                onChange={handleChangeUsername}
                            />
                            <Typography color="primary" variant='h6' sx={{marginTop: '20px'}}>
                                Contraseña
                            </Typography>
                            <TextField
                                sx={{ input: { color: '#fff' } }}
                                color='primary'
                                variant="outlined"
                                className="textfield"
                                type='password'
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />
                        </Box>
                        <MuiLink href='/RecuperarClave' color='primary' align="center">
                            ¿Olvidaste tu contraseña?
                        </MuiLink>
                        <Box
                            className='buttons-container'
                        >
                            <Button onClick={handleIngresar} variant='contained' color='buttonColor' sx={{ width: '180px', marginBottom: '10px' }}>
                                <Typography>
                                    Ingresar
                                </Typography>
                            </Button>
                            {/* <Button variant='outlined' color='buttonOutlined' sx={{ width: '180px' }}>
                                <Typography>
                                    Activa tu cuenta
                                </Typography>
                            </Button> */}
                        </Box>
                    </CardContent>
                </Card>
                <Typography align='center'>
                    Ecosistema financiero
                </Typography>
                <Typography align='center'>
                    {moment().get().format("YYYY")} Copyright - btrust
                </Typography>
            </Grid>
        </Grid>
    )
}