import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Box from '@mui/material/Box'
import Tables from '../../Components/Tables/Table'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '../../Components/Switches/Switches';
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl';
import TimePicker from '@mui/lab/TimePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

import axios from 'axios'


export default function AdministrarHorarios() {
    const {
        openingTime,
        closingTime,
        openingTimeBtrust,
        closingTimeBtrust,
        updateTimeIndicator,
        setUpdateTimeIndicator,
        activeFund,
        rolComprador,
        hostService,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType
    } = useAppContext();

    const [editing, setEditing] = useState(false);
    const [openingTimeEdit, setOpeningTimeEdit] = useState(openingTime);
    const [closingTimeEdit, setClosingTimeEdit] = useState(closingTime);

    // True if first date is greater than the second
    const isGreaterTime = (date1, date2) => {
        const hour1 = date1.getHours();
        const minutes1 = date1.getMinutes();

        const hour2 = date2.getHours();
        const minutes2 = date2.getMinutes();

        if (hour1 > hour2) return true;
        else if (hour1 === hour2 && minutes1 > minutes2) return true;
        
        return false;
    }

    const handleChangeTime = () => {
        if(isGreaterTime(openingTimeBtrust, openingTimeEdit) || isGreaterTime(closingTimeEdit, closingTimeBtrust)){
            setAlertMessageOpen(true);
            setAlertMessageTitle('Horario incorrecto');
            setAlertMessageDescription('El horario debe estar dentro del horario de operación de Btrust');
            setAlertMessageType('warning');
        }
        else if (openingTimeEdit !== null && closingTimeEdit !== null) {
            axios.post(hostService + `servicios/Compradores.asmx/ActualizarHorario`, { horarioApertura: openingTimeEdit.getHours() + ":" + openingTimeEdit.getMinutes(), 
                horarioCierre: closingTimeEdit.getHours() + ":" + closingTimeEdit.getMinutes() }, { withCredentials: true })

                .then(response => {
                    let respuesta = JSON.parse(response.data.d);
                    if (respuesta.Status === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else if (respuesta.Status === 'errorPermiso') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Error de permiso');
                        setAlertMessageDescription('No tiene permiso para realizar esta acción');
                        setAlertMessageType('warning');
                    } else if (respuesta.Status === 'error') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    } else {
                        setUpdateTimeIndicator(!updateTimeIndicator);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Horario ingresado correctamente');
                        setAlertMessageDescription('');
                        setAlertMessageType('success');
                    }
                }).catch(error => {
                    console.log(error);
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        } else {
            setAlertMessageOpen(true);
            setAlertMessageTitle('Debe llenar todos los campos');
            setAlertMessageDescription('');
            setAlertMessageType('warning');
        }
    }

    return (
        <>
            <Grid container marginTop="20px">
                <Grid item xs={12}>
                    <Typography variant='h5' fontWeight={700}>
                        Administrar horarios
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ margin: '40px auto 0 auto' }}>
                    <Card sx={{ padding: '20px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} textAlign={'center'}>
                                <Typography variant='h6' fontWeight={700} color={'primary'}>
                                    Horario { activeFund.nombre }
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: '80%', paddingTop: 3 }}>
                                        <TimePicker
                                            label={<Typography variant='h6' color="primary">Horario apertura</Typography>}
                                            value={openingTimeEdit}
                                            readOnly={!editing}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setOpeningTimeEdit(newValue);
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: '80%', paddingTop: 3 }}>
                                        <TimePicker
                                            label={<Typography variant='h6' color="primary">Horario cierre</Typography>}
                                            value={closingTimeEdit}
                                            readOnly={!editing}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setClosingTimeEdit(newValue);
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                                <Box marginTop='30px'>
                                    {rolComprador === 'operacionesComprador' || rolComprador === 'inversionesComprador' || rolComprador === 'fullComp' ?
                                        !editing ?
                                            <Button
                                                variant="contained"
                                                color='buttonColor'
                                                disabled={editing}
                                                onClick={() => setEditing(!editing)}
                                            >
                                                <Typography>
                                                    Activar cambio de hora
                                                </Typography>
                                            </Button>
                                            :
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color='error'
                                                    sx={{ margin: '0px 5px' }}
                                                    onClick={() => { setEditing(!editing); setClosingTimeEdit(closingTime) }}
                                                >
                                                    <Typography>
                                                        Cancelar
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color='buttonColor'
                                                    sx={{ margin: '0px 5px' }}
                                                    onClick={() => { setEditing(!editing); handleChangeTime(); }}
                                                >
                                                    <Typography>
                                                        Confirmar
                                                    </Typography>
                                                </Button>
                                            </>
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                                <Typography variant='h6' fontWeight={700} color={'primary'}>
                                    Horario Btrust
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: '80%', paddingTop: 3 }}>
                                        <TimePicker
                                            label={<Typography variant='h6' color="primary">Horario apertura</Typography>}
                                            value={openingTimeBtrust}
                                            readOnly={true}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: '80%', paddingTop: 3 }}>
                                        <TimePicker
                                            label={<Typography variant='h6' color="primary">Horario cierre</Typography>}
                                            value={closingTimeBtrust}
                                            readOnly={true}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}