import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './table.css'


export default function TableWithSeparation({ rows, headers, labels, type, withTotal }) {
    
    return (
        <TableContainer className='table standard-table'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: "2px 10px", borderRight: "1px solid" }}>{headers[0]}</TableCell>
                        {headers.slice(1).map((e, i) => {
                            if((i + 1) % 2 === 0 && i !== headers.length - 2)
                                return <TableCell style={{ padding: "2px 10px", borderRight: "1px solid" }} key={e} align="right">{e}</TableCell>
                            else
                                return <TableCell style={{ padding: "2px 10px" }} key={e} align="right">{e}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={row + i}
                            className={withTotal ? 'with-total' : null}
                        >
                            <TableCell style={{ padding: "9px 10px", borderRight: "1px solid" }}>{row[labels[0]]}</TableCell>
                            {labels.slice(1).map((e, i) => {
                                if ((i + 1) % 2 === 0 && i !== headers.length - 2)
                                    return <TableCell style={{ padding: "9px 10px", borderRight: "1px solid" }} key={e} align="right">
                                        <span style={{ color: (type === 'blueNumbers' && (row[e] > 0 || row[e] !== '0')) ? '#A3BAFF' : null }}>
                                            {row[e]}
                                        </span>
                                    </TableCell>
                                else
                                    return <TableCell style={{ padding: "9px 10px" }} key={e} align="right">
                                        <span style={{ color: (type === 'blueNumbers' && (row[e] > 0 || row[e] !== '0')) ? '#A3BAFF' : null }}>
                                            {row[e]}
                                        </span>
                                    </TableCell>
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}