import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useAppContext } from '../../libs/contextlib'

import DownloadArchive from './PagosViews/DownloadArchive'
import DownloadRecords from './PagosViews/DownloadRecords'
import DonePayments from './PagosViews/DonePayments'
import downloadIcon from '../../assets/images/download-icon.png'

import axios from 'axios'

import './pagos.css'
import { Typography } from '@mui/material'
import RejectedPayments from './PagosViews/RejectedPayments'

export default function Pagos() {
    const {
        pagosView,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatAmount,
        downloadBase64,
        downloadingOpen,
        setDownloadingOpen,
        setDownloadingTitle,
        hostService,
        rechazados,
        setRechazados
    } = useAppContext();

    const [downloadFormat, setDownloadFormat] = useState('santander')
    const [confirmPayment, setConfirmPayment] = useState([])
    const [openDonePaymentsModal, setOpenDonePaymentsModal] = useState(false);
    const [notPayed, setNotPayed] = useState(false)
    const [notPayedReasons, setNotPayedReasons] = useState([])
    const [notPayedContinue, setNotPayedContinue] = useState(false)
    const [userBills, setUserBills] = useState([{}])
    const [checkedBills, setCheckedBills] = useState([])
    const [checkedBillsLength, SetCheckedBillsLength] = useState(0);
    const [checkedReasons, setCheckedReasons] = useState([])
    const [checkedReasonsLength, SetCheckedReasonsLength] = useState(0);
    const [archiveRows, setArchiveRows] = useState([])
    const [downloaded, setDownloaded] = useState(false)
    const [recordRows, setRecordRows] = useState([])
    const [donePaymentsRows, setDonePaymentsRows] = useState([])
    const [search, setSearch] = useState('')
    const [chargingPayed, setChargingPayed] = useState(false);
    const [chargingNoPayed, setChargingNoPayed] = useState(false);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetTransaccionesPendientes`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    respuesta.transacciones.map((object) => data.push({
                        quantity: object.cantidadFacturas,
                        amount: formatAmount(object.montoTotal)
                    }));

                    setArchiveRows(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });

        axios.post(hostService + `servicios/Compradores.asmx/GetHistoricoDescargas`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    respuesta.transacciones.map((object) => data.push({
                        number: object.id,
                        fund: object.nombreFondo,
                        total: formatAmount(object.montoTotal),
                        date: object.fecha,
                        status: <span style={{ fontStyle: "italic" }}>{object.status}</span>,
                        action: renderButton(object.id),
                        quantity: object.cantidadFacturas
                    }));
                    setRecordRows(data);

                    const data2 = [];
                    respuesta.transacciones.map((object) => {
                        if (object.status === 'Pendiente') {
                            data2.push({
                                number: object.id,
                                user: object.nombreUsuario,
                                date: object.fecha,
                                total: formatAmount(object.montoTotal),
                                quantity: object.cantidadFacturas,
                                action: <Button
                                    variant='contained'
                                    color="buttonColor"
                                    onClick={() => {
                                        setConfirmPayment(object);
                                        handleToggleModal();
                                    }}
                                >Confirmar pago
                                </Button>
                            })
                        }
                        return data2;
                    });
                    setDonePaymentsRows(data2);
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, [downloaded, chargingPayed, chargingNoPayed]);

    const handleToggleModal = () => {
        setOpenDonePaymentsModal(!openDonePaymentsModal);
        setCheckedBills([]);
        setCheckedReasons([]);
        SetCheckedBillsLength(0);
        SetCheckedReasonsLength(0);
    }

    const handleDownloadFormat = (event) => {
        setDownloadFormat(event.target.value);
    };

    const hadleAcceptPay = () => {
        setChargingPayed(true);
        axios.post(hostService + `servicios/conector.asmx/confirmarPagoDescarga`, { descarga: confirmPayment.id }, { withCredentials: true })
            .then(response => {
                setChargingPayed(false);
                setOpenDonePaymentsModal(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Se ha confirmado correctamente');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                    setConfirmPayment([]);
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const hadleNoPayed = () => {
        axios.post(hostService + `servicios/Compradores.asmx/GetOperacionesPorDescarga`, { idDescarga: confirmPayment.id }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    respuesta.operaciones.map(e => data.push({
                        number: <FormControlLabel
                            name='formControlOperaciones'
                            className="checkboxLabel"
                            value={e.id}
                            control={
                                <Checkbox
                                    className="checkbox"
                                    onChange={(e) => getCheckboxValue(e)}
                                    style={{
                                        color: "#fff",
                                    }}
                                />
                            }
                            label=''
                            labelPlacement="end"
                        />,
                        company: e.nombreEmpresa,
                        rut: e.rutEmpresa,
                        amount: formatAmount(e.montoRecibir)
                    }));

                    setUserBills(data);
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleNotPayedContinue = () => {
        const reasons = [
            {
                check: <FormControlLabel
                    className="checkboxLabel"
                    value={"Problema con cuenta bancaria"}
                    control={
                        <Checkbox
                            className="checkbox"
                            onChange={(e) => getCheckboxValueReasons(e)}
                            style={{
                                color: "#fff",
                            }}
                        />
                    }
                    label=''
                    labelPlacement="end"
                />,
                reason: <Typography textAlign={'left'}>Problema con cuenta bancaria</Typography>
            },
            {
                check: <FormControlLabel
                    className="checkboxLabel"
                    value={"Problema con la cesión"}
                    control={
                        <Checkbox
                            className="checkbox"
                            onChange={(e) => getCheckboxValueReasons(e)}
                            style={{
                                color: "#fff",
                            }}
                        />
                    }
                    label=''
                    labelPlacement="end"
                />,
                reason: <Typography textAlign={'left'}>Problema con la cesión</Typography>
            },
            {
                check: <FormControlLabel
                    className="checkboxLabel"
                    value={"Otro Problema"}
                    control={
                        <Checkbox
                            className="checkbox"
                            onChange={(e) => getCheckboxValueReasons(e)}
                            style={{
                                color: "#fff",
                            }}
                        />
                    }
                    label=''
                    labelPlacement="end"
                />,
                reason: <Typography textAlign={'left'}>Otro Problema</Typography>
            }
        ]
        setNotPayedReasons(reasons);
        setNotPayedContinue(true)
    }

    const hadleAcceptNoPayed = () => {
        setChargingNoPayed(true);
        let datos = {};
        datos["descarga"] = confirmPayment.id;
        datos["operaciones"] = userBills.map((e) => {
            if (checkedBills.includes(e.number.props.value)) {
                return { id: e.number.props.value, rechazado: true }
            } else {
                return { id: e.number.props.value, rechazado: false }
            }
        });
        datos["razones"] = notPayedReasons.map((e) => {
            if (checkedReasons.includes(e.check.props.value)) {
                return { razon: e.check.props.value, valido: true }
            } else {
                return { razon: e.check.props.value, valido: false }
            }
        });

        datos["operaciones"] = JSON.stringify(datos["operaciones"]);
        datos["razones"] = JSON.stringify(datos["razones"]);

        axios.post(hostService + `servicios/conector.asmx/confirmarPagoRechazados`, datos, { withCredentials: true })
            .then(response => {
                setChargingNoPayed(false);
                setOpenDonePaymentsModal(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'errorPermiso') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Error de permiso');
                    setAlertMessageDescription('No tiene permiso para realizar esta acción');
                    setAlertMessageType('warning');
                } else if (response.data.d === "ok") {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Se ha confirmado correctamente');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                    setConfirmPayment([]);
                    setRechazados(!rechazados);
                    setDownloaded(!downloaded);
        
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const getCheckboxValue = (e) => {

        if (e.target.checked) {
            let data = checkedBills;
            data.push(e.target.value);
        } else {
            if (checkedBills.includes(e.target.value)) {
                const isInArray = (element) => element === e.target.value;
                const elementIndex = checkedBills.findIndex(isInArray);
                checkedBills.splice(elementIndex, 1);
            }
        }
        SetCheckedBillsLength(checkedBills.length);
    };

    const getCheckboxValueReasons = (e) => {
        if (e.target.checked) {
            let data = checkedReasons;
            data.push(e.target.value);
        } else {
            if (checkedReasons.includes(e.target.value)) {
                const isInArray = (element) => element === e.target.value;
                const elementIndex = checkedReasons.findIndex(isInArray);
                checkedReasons.splice(elementIndex, 1);
            }
        }

        SetCheckedReasonsLength(checkedReasons.length);
    };

    const renderButton = (idDescarga) => {
        return (
            <Button
                variant='contained'
                color='buttonColor'
                sx={{ width: '35%' }}
                disabled={downloadingOpen}
                onClick={() => {
                    hadleDescargaHistorico(idDescarga);
                }}
            >
                <img
                    style={{
                        width: '17px',
                        marginRight: '10px'
                    }}
                    src={downloadIcon}
                    alt="descargar"
                />
                Descargar
            </Button>
        )
    }

    const hadleDescargaHistorico = (idDescarga) => {
        setDownloadingTitle('Descargando archivo de pago');
        setDownloadingOpen(true);
        axios.post(hostService + `servicios/Compradores.asmx/DescargarHistoricoDescargas`, { idDescarga: idDescarga }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay operaciones por descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    respuesta.documents.forEach(element => {
                        downloadBase64(element.nombre, element.base64);
                    });
                }
            }).catch(error => {
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const hadleDownload = () => {
        setDownloadingTitle('Descargando archivo para pago');
        setDownloadingOpen(true);
        const service = downloadFormat === 'santander' ? 'DescargarFormatoSantander'
            : downloadFormat === 'universal' ? 'DescargarFormatoUniversal'
            : downloadFormat === 'scotiabank' ? 'DescargarFormatoScotibank'
                : 'DescargarFormatoChile';

        axios.post(hostService + `servicios/Compradores.asmx/` + service, {}, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay operaciones por descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    respuesta.documents.forEach(element => {
                        downloadBase64(element.nombre, element.base64);
                    });

                    setDownloaded(!downloaded);
                }
            }).catch(error => {
                console.log(error);
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    //   Archivos table
    const archiveTableHeaders = ['Cantidad', 'Monto a pagar ($)']
    const archiveTableLabels = ['quantity', 'amount']

    //   Archivos table

    //   Histórico table
    const recordsTableHeaders = ['Fecha', 'Fondo', 'Monto a pagar ($)', 'Cantidad', 'Estado', 'Acción']
    const recordsTableLabels = ['date', 'fund', 'total', 'quantity', 'status', 'action']

    //   Histórico table

    //   Informar pagos table
    const donePaymentsTableHeaders = ['#', 'Usuario', 'Fecha', 'Monto a pagar ($)', 'Cantidad de facturas', 'Acción']
    const donePaymentsTableLabels = ['number', 'user', 'date', 'total', 'quantity', 'action']

    //   Informar no pagados table
    const notPayedTableHeaders = ['', 'Nombre Empresa', 'Rut empresa', 'Monto a pagar ($)']
    const notPayedTableLabels = ['number', 'company', 'rut', 'amount']

    //   Informar razones de no pago
    const notPayedReasonsTableHeaders = ['', '']
    const notPayedReasonsTableLabels = ['check', 'reason']

    return (
        <Container maxWidth="xxl">
            <Grid container className="pagos-container">
                {pagosView === 'downloadarchive' ?
                    <DownloadArchive
                        downloadFormat={downloadFormat}
                        setDownloadFormat={setDownloadFormat}
                        handleDownloadFormat={handleDownloadFormat}
                        headers={archiveTableHeaders}
                        labels={archiveTableLabels}
                        rows={archiveRows}
                        handleDownload={hadleDownload}
                        downloadingOpen={downloadingOpen}
                    />
                    : pagosView === 'downloadrecords' ?
                        <DownloadRecords
                            headers={recordsTableHeaders}
                            labels={recordsTableLabels}
                            rows={recordRows}
                            search={search}
                            setSearch={setSearch}
                        />
                        : pagosView === 'donepayments' ?
                            <DonePayments
                                headers={donePaymentsTableHeaders}
                                labels={donePaymentsTableLabels}
                                rows={donePaymentsRows}
                                openDonePaymentsModal={openDonePaymentsModal}
                                handleToggleModal={handleToggleModal}
                                confirmPayment={confirmPayment}
                                notPayed={notPayed}
                                setNotPayed={setNotPayed}
                                notPayedHeaders={notPayedTableHeaders}
                                notPayedLabels={notPayedTableLabels}
                                notPayedRows={userBills}
                                checkedBillsLength={checkedBillsLength}
                                checkedReasonsLength={checkedReasonsLength}
                                notPayedContinue={notPayedContinue}
                                handleNotPayedContinue={handleNotPayedContinue}
                                setNotPayedContinue={setNotPayedContinue}
                                notPayedReasonsTableHeaders={notPayedReasonsTableHeaders} 
                                notPayedReasonsTableLabels={notPayedReasonsTableLabels} 
                                notPayedReasons={notPayedReasons} 
                                search={search}
                                setSearch={setSearch}
                                hadleAcceptPay={hadleAcceptPay}
                                hadleNoPayed={hadleNoPayed}
                                hadleAcceptNoPayed={hadleAcceptNoPayed}
                                chargingPayed={chargingPayed}
                                chargingNoPayed={chargingNoPayed}
                            />
                            : pagosView === 'rejectedpayments' ?
                                <RejectedPayments
                                    downloaded={downloaded}
                                    setDownloaded={setDownloaded}
                                />
                                :
                                null
                }
            </Grid>
        </Container>
    )
}