import React from  'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Tables from '../../../Components/Tables/Table'

import downloadIcon from '../../../assets/images/download-icon.png'

export default function DownloadArchive ({
    downloadFormat,
    handleDownloadFormat,
    headers,
    labels,
    rows,
    handleDownload,
    downloadingOpen,
}) {

    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h5' fontWeight={700}>
                    Descargar verificaciones y pagos pendientes
                </Typography>
            </Grid>
            <Grid className='descarga-centered' xs={5} item>
                <Box display='flex' alignItems='stretch' justifyContent='space-around' width="100%">
                    <FormControl sx={{width: '60%'}}>
                        <InputLabel className="labels">Formato</InputLabel>
                        <Select
                            className="filters-select"
                            value={downloadFormat}
                            label="Formato"
                            onChange={handleDownloadFormat}
                        >
                            <MenuItem value='santander'>Formato Banco Santander</MenuItem>
                            <MenuItem value='chile'>Formato Banco de Chile</MenuItem>
                            <MenuItem value='scotiabank'>Formato Banco Scotiabank</MenuItem>
                            <MenuItem value='universal'>Formato otros bancos</MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={handleDownload} disabled={downloadingOpen} variant='contained' color='buttonColor' sx={{width: '35%'}}>
                        <img
                            style={{
                                width: '17px',
                                marginRight: '10px'
                            }}
                            src={downloadIcon}
                            alt="descargar"
                        />
                        Descargar
                    </Button>
                </Box>
                <Box mt={3}>
                    {downloadFormat === 'universal' ? "" :
                        <Typography variant='h6' color='secondary' textAlign='justify'>Se descargarán dos archivos, uno permite realizar el pago en el banco seleccionado, y el otro verificar la titularidad de las facturas.</Typography>
                    }
                </Box>
                <Card className="table-container">
                    <Tables
                        headers={headers}
                        labels={labels}
                        rows={rows}
                    />
                </Card>
            </Grid>
        </>
    )
}