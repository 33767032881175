import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Box from '@mui/material/Box'
import Tables from '../../Components/Tables/Table'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '../../Components/Switches/Switches';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Grid from '@mui/material/Grid'
import Paginations from '../../Components/Pagination/Paginations'
import InputAdornment from '@mui/material/InputAdornment'
import axios from 'axios'

export default function LimitesTotales({
    fundsData,
    payersData,
    handleSetActiveFund,
    search,
    setSearch,
    changeAmount,
    setChangeAmount,
    changeAmountPayer,
    setChangeAmountPayer
}) {
    const {
        activeFund,
        formatAmount,
        downloadExcel,
        downloadPdf,
        hostService,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType
    } = useAppContext();

    const [pageFunds, setPageFunds] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(fundsData);
    const [newMaxTotalAmount, setNewMaxTotalAmount] = useState([]);

    const handleAddNewMaxTotalAmount = (object) => {
        var temp = newMaxTotalAmount.filter(x => x.id !== object.id);
        temp.push(object)
        setNewMaxTotalAmount(temp);
    }

    const setMaxtotalAmount = (id) => {
        if (newMaxTotalAmount.find(x => x.id === id) !== undefined) {
            if (newMaxTotalAmount.find(x => x.id === id).amount !== "") {
                axios.post(hostService + `servicios/Compradores.asmx/ActualizarMontoMaximoTotalFondo`, { idFondo: id, monto: newMaxTotalAmount.find(x => x.id === id).amount }, { withCredentials: true })
                    .then(response => {
                        if (response.data.d === 'errorSession') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('La sesión ha caducado');
                            setAlertMessageDescription('');
                            setAlertMessageType('error');
                            window.location = "/login";
                        } else if (response.data.d === 'errorPermiso') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Error de permiso');
                            setAlertMessageDescription('No tiene permiso para realizar esta acción');
                            setAlertMessageType('warning');
                        } else if (response.data.d === 'ok') {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Máximo Total Actualizado');
                            setAlertMessageDescription('');
                            setAlertMessageType('success');
                            setNewMaxTotalAmount(newMaxTotalAmount.filter(x => x.id !== id));
                            setChangeAmount(!changeAmount);
                        } else if(response.data.d === "montoInsuficiente"){
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Monto Insuficiente');
                            setAlertMessageDescription('El monto máximo no puede ser menor o igual al monto usado');
                            setAlertMessageType('warning');
                        } else {
                            setAlertMessageOpen(true);
                            setAlertMessageTitle('Ocurrió un error inesperado');
                            setAlertMessageDescription('Inténtalo nuevamente');
                            setAlertMessageType('error');
                        }
                    }).catch(error => {
                        console.log(error);
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    });
            }
        }
    }

    const handleDownloadExcel = () => {
        let title = 'Administrar límites totales de fondos';
        let headers = {
            nombre: 'Nombre',
            montoMaximo: 'Monto Disponible ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoDisponible: 'Monto Máximo total ($)',
            activo: 'Activo'
        }
        let rows = currentTransactions.map((e, i) => {
            let row = {
                name: e.nombre,
                montoDisponible: formatAmount(e.montoDisponibleTotal),
                montoUsado: formatAmount(e.montoUsadoTotal),
                montoMaximo: formatAmount(e.maximoTotal),
                active: e.nombre === activeFund.nombre ? 'Activo' : 'Inactivo'
            }
            return row;
        });

        downloadExcel(title, headers, rows);
    }

    const handleDownloadPdf = () => {
        let title = 'Administrar límites totales de fondos';
        let headers = {
            nombre: 'Nombre',
            montoMaximo: 'Monto Disponible ($)',
            montoUtilizado: 'Monto Utilizado ($)',
            montoDisponible: 'Monto Máximo total ($)',
            activo: 'Activo'
        }
        let rows = currentTransactions.map((e, i) => {
            let row = {
                name: e.nombre,
                montoDisponible: formatAmount(e.montoDisponibleTotal),
                montoUsado: formatAmount(e.montoUsadoTotal),
                montoMaximo: formatAmount(e.maximoTotal),
                active: e.nombre === activeFund.nombre ? 'Activo' : 'Inactivo'
            }
            return row;
        });

        downloadPdf(title, headers, rows);
    }

    const renderTableFunds = () => {
        let data = currentTransactions.map((e) => {
            let row = {
                name: e.nombre,
                maxTotalAmount: formatAmount(e.maximoTotal),
                    usedTotalAmount: formatAmount(e.montoUsadoTotal),
                    availableTotalAmount: formatAmount(e.montoDisponibleTotal),
                    updateTotalAmount:
                        <>
                            <TextField
                                sx={{maxWidth: 150}}
                                size='small'
                                variant="outlined"
                                color='primary'
                                value={newMaxTotalAmount.find(x => x.id === e.id) !== undefined ? newMaxTotalAmount.find(x => x.id === e.id).amount : ''}
                                onChange={(event) => handleAddNewMaxTotalAmount({ id: e.id, amount: event.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography sx={{ color: '#BFBFBF' }}>$</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant='contained'
                                color='buttonColor'
                                size='small'
                                sx={{ marginLeft: 1 }}
                                onClick={() => setMaxtotalAmount(e.id)}
                            >Actualizar
                            </Button >
                        </>,
                active: <Switch
                    val={e.nombre}
                    checked={activeFund.nombre}
                    disabled={activeFund.nombre === e.nombre}
                    handleChange={handleSetActiveFund}
                />
            }

            return row
        })
        return data
    }

    const rows = renderTableFunds()

    const renderHeadersFunds = () => {
        let headers = ['Nombre', 'Monto disponible total ($)', 'Monto Utilizado total ($)', 'Máximo total ($)', 'Actualizar máximo total ($)', 'activo'];
        return headers;
    }

    const headersFunds = renderHeadersFunds();

    const renderLabelFunds = () => {

        let labels = ['name', 'availableTotalAmount', 'usedTotalAmount', 'maxTotalAmount', 'updateTotalAmount', 'active'];
        return labels;
    }

    const labelFunds = renderLabelFunds();

    useEffect(() => {
        if (search) {
            setPageFunds(0);
            setCurrentTransactions(fundsData.filter((element) => {
                return element.nombre.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || element.rut.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || element.razonSocial.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }
            ));
        } else {
            setCurrentTransactions(fundsData);
        }
    }, [search]);

    useEffect(() => {
        setSearch('');
        setCurrentTransactions(fundsData);
    }, [fundsData]);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                {/* <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid> */}
            </Grid>

            <Box marginTop="20px">
                <Typography variant='h5' fontWeight={600}>
                    Administrar límites totales de Fondos
                </Typography>
                <Card className="table-container">
                    <Tables
                        rows={rows.slice((pageFunds * 3), (pageFunds * 3) + 3)}
                        headers={headersFunds}
                        labels={labelFunds}
                    />
                </Card>
            </Box>
            <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                <Paginations
                    onChange={(event, val) => { setPageFunds(val - 1) }}
                    count={Math.ceil(rows.length / 3)}
                />
            </Box>
        </>
    )
}