const data = [
    {
        time: new Date().getTime() - 10000*4,
        amount: 200,
    },
    {
        time: new Date().getTime() - 1000*90,
        amount: 200,
    },
    {
        time: new Date().getTime() - 1000*6,
        amount: 100,
    },
    {
        time: new Date().getTime() - 10000*6,
        amount: 278,
    },
    {
        time: new Date().getTime() - 10000*12,
        amount: 989,
    },
    {
        time: new Date().getTime() - 10000*3,
        amount: 239,
    },
    {
        time: new Date().getTime() - 10000*1,
        amount: 189,
    },
    {
        time: new Date().getTime() - 10000*2,
        amount: 349,
    },
    {
        time: new Date().getTime() - 1000*9,
        amount: 739,
    },
    {
        time: new Date().getTime() - 10000*5,
        amount: 679,
    },
    {
        time: new Date().getTime() - 10000*8,
        amount: 879,
    },
    {
        time: new Date().getTime(),
        amount: 450,
    },
  ];

  export default data