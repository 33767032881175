import React from 'react';
import Pagination from '@mui/material/Pagination';

import './paginations.css'

export default function Paginations({
    onChange,
    count
}) {
    return (
        <Pagination className="pagination" onChange={onChange} count={count} siblingCount={0} color="pagination" />
    )
}