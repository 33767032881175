import React, { useState, useEffect } from "react";
import { useAppContext } from '../../libs/contextlib'
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button'
import moment from 'moment'

import "./mercado.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography'

// Views
import LastTransactions from "./MercadoViews/LastTransactions";
import MarketTransactions from "./MercadoViews/MarketTransactions";

export default function Mercado() {
    const {
        mercadoView,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatAmount,
        formatFee,
        hostService,
        downloadExcel,
        downloadPdf

    } = useAppContext();

    const [summaryData, setSummaryData] = useState([]);
    const [summaryInfo, setSummaryInfo] = useState([]);
    const [ultimasTransacciones, setUltimasTransacciones] = useState([]);
    const [search, setSearch] = useState('');
    const [openModalSummary, setOpenModalSummary] = useState(false);
    const [marketTransactionsTableData, setMarketTransactionsTableData] = useState([]);
    const [payingCompaniesData, setPayingCompaniesData] = useState([]);
    const [datosGrafico, setDatosGrafico] = useState([{}, {}]);
    const [payingCompany, setPayingCompany] = useState([]);
    const [sinceValue, setSinceValue] = useState(moment().get());
	const [untilValue, setUntilValue] = useState(moment().get());

    const handleOpenModalSummary = (pagadoraId, compradorId) => {
        axios.post(hostService + `servicios/Compradores.asmx/GetResumenPagadorasInfo`, { idPagadora: pagadoraId }, { withCredentials: true })
            .then(response => {
                let respuesta = JSON.parse(response.data.d)
                if (respuesta.status === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (respuesta.status === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    const data = [];
                    respuesta.data.data.map((object) => data.push({
                        rate: object.idComprador === compradorId ? <Typography sx={{ fontWeight: 'bold' }} color="#92adff">{formatFee(object.tasa)}</Typography> : formatFee(object.tasa),
                        availableAmount: object.montoDisponible === null ? "Sin límite" : object.idComprador === compradorId ? <Typography sx={{ fontWeight: 'bold' }} color="#92adff">{formatAmount(object.montoDisponible)}</Typography> : formatAmount(object.montoDisponible),
                        payerName: object.nombrePagadora
                    }));

                    setSummaryInfo(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
        setOpenModalSummary(!openModalSummary);
    }

    //   Last transactions
    const lastTransactionsHeaders = ['Fecha', 'Pagadora', 'Tasa (%)', 'Monto ($)']
    const lastTransactionsLabels = ['date', 'payerName', 'fee', 'amount']

    //   Summary table
    const summaryTableHeaders = ['Nombre pagadora', 'Mi mejor tasa (%)', 'Mi monto disponible ($)', 'Profundidad de Mercado']
    const summaryTableLabels = ['payingName', 'minRate', 'availableAmount', 'info']

    //  Market transactions
    const marketTransactionsTableHeaders = ['Nombre', 'Última (%)', 'Volumen ($ M)', 'Menor (%)', 'Volumen ($ M)', 'Promedio (%)', 'Volumen total ($ M)']
	const marketTransactionsTableLabels = ['payerName', 'last', 'lastVolume', 'min', 'minVolume', 'average', 'totalVolume']


    const UltimasTransacciones = () => {
        axios.post(hostService + `servicios/Compradores.asmx/UltimasTransacciones`, { }, { withCredentials: true })
            .then(response => {
                let respuesta = JSON.parse(response.data.d);
                if (respuesta.status === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
                } else if (respuesta.status === 'error') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    const data = [];
                    respuesta.data.data.map((object, i) => data.push({
                        payerId: object.idPagadora,
                        payerName: <Typography sx={{ fontWeight: i === 0 ? 'bold' : '' }} color={object.propia === 'True' ? '#92adff' : ''} >{object.nombrePagadora}</Typography>,
                        fee: <Typography sx={{ fontWeight: i === 0 ? 'bold' : '' }} color={object.propia === 'True' ? '#92adff' : ''} >{formatFee(object.tasa)}</Typography>,
                        amount: <Typography sx={{ fontWeight: i === 0 ? 'bold' : '' }} color={object.propia === 'True' ? '#92adff' : ''} >{formatAmount(object.monto)}</Typography>,
                        date: <Typography sx={{ fontWeight: i === 0 ? 'bold' : '' }} color={object.propia === 'True' ? '#92adff' : ''} >{object.fecha}</Typography>
                    }));

                    setUltimasTransacciones(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        UltimasTransacciones();
        const interval = setInterval(UltimasTransacciones, 30000);
        return () => { clearInterval(interval) };
    }, []);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetResumenPagadoras`, {}, { withCredentials: true })
            .then(response => {
                let respuesta = JSON.parse(response.data.d)
                if (respuesta.status === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (respuesta.status === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    const data = [];
                    respuesta.data.data.map((object) => data.push({
                        payingName: object.nombrePagadora,
                        minRate: parseFloat(object.mejorTasa.replace(',', '.')) > 0 ? formatFee(object.mejorTasa) : <Typography sx={{ fontWeight: 'bold' }} color="red">Sin ofertas</Typography>,
                        availableAmount: formatAmount(object.montoDisponible),
                        info: parseFloat(object.cantidadMercado.replace(',', '.')) > 0 ? <Button onClick={() => [handleOpenModalSummary(object.idPagadora, object.idComprador)]}><InfoOutlinedIcon /></Button> : <Typography sx={{ fontWeight: 'bold' }} color="red">Sin ofertas</Typography>
                    }));

                    setSummaryData(data);
                }
            }).catch(error => {
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, []);

    useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/GetTransaccionesDelMercado`, { desde: sinceValue.format('YYYY-MM-DD'), hasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
			.then(response => {
				let respuesta = JSON.parse(response.data.d)
				if (respuesta.status === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else if (respuesta.status === 'error') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('Ocurrió un error inesperado');
					setAlertMessageDescription('Inténtalo nuevamente');
					setAlertMessageType('error');
				} else {
					const data = [];
					respuesta.data.data.map((object) => data.push({ 
						payerId: object.idPagadora,
                        payerName: object.nombrePagadora,
						last: formatFee(object.ultimaTasa),
						lastVolume: ((object.ultimoMonto.replace(',', '.'))/1000000).toLocaleString('es-CL', {minimumFractionDigits: 2, maximumFractionDigits: 2,}),
						min: formatFee(object.menorTasa),
						minVolume: ((object.montoMenorTasa.replace(',', '.'))/1000000).toLocaleString('es-CL', {minimumFractionDigits: 2, maximumFractionDigits: 2,}),
						average: formatFee(object.promedioTasa),
						totalVolume: ((object.montoTotal.replace(',', '.'))/1000000).toLocaleString('es-CL', {minimumFractionDigits: 2, maximumFractionDigits: 2,})
					}));

					setMarketTransactionsTableData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, [sinceValue, untilValue]);

    useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/PagadorasDelComprador`, {}, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else {
                    var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.pagadoras.map((object) => data.push({ id: object.idPagadora, nombre: object.nombrePagadora, montoMaximo: object.montoMaximo }));
					setPayingCompaniesData(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, []);


    useEffect(() => {
        if (payingCompany.length > 0 && payingCompaniesData.length > 0) {
            axios.post(hostService + `servicios/Compradores.asmx/ObtenerDataTransacciones`, { idPagadora: payingCompaniesData.find(company => company.nombre === payingCompany).id, fechaDesde: sinceValue.format('YYYY-MM-DD'), fechaHasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
                .then(response => {
                    let respuesta = JSON.parse(response.data.d)
                    if (respuesta.status === 'errorSession') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('La sesión ha caducado');
                        setAlertMessageDescription('');
                        setAlertMessageType('error');
                        window.location = "/login";
                    } else if (respuesta.status === 'error') {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Ocurrió un error inesperado');
                        setAlertMessageDescription('Inténtalo nuevamente');
                        setAlertMessageType('error');
                    } else {
                        const data = [{}, {}];
                        respuesta.data.data.map((object) => data.push({ time: new Date(object.fecha).getTime(), rate: (object.tasa), amount: (object.monto / 1000000) }));
                        setDatosGrafico(data);
                    }
                }).catch(error => {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                });
        }
    }, [sinceValue, untilValue, payingCompany]);


    return (
        <Container maxWidth="xxl">
            <Grid container className="market-container">
                {mercadoView === 'lastTransactions' ?
                    <LastTransactions
                        headers={summaryTableHeaders}
                        labels={summaryTableLabels}
                        rows={summaryData}
                        lastTransactionsHeaders={lastTransactionsHeaders}
                        lastTransactionsLabels={lastTransactionsLabels}
                        lastTransactionsRows={ultimasTransacciones}
                        search={search}
                        setSearch={setSearch}
                        rowsInfo={summaryInfo}
                        openModalSummary={openModalSummary}
                        setOpenModalSummary={setOpenModalSummary}
                        downloadExcel={downloadExcel}
                        downloadPdf={downloadPdf}
                    />
                    : <MarketTransactions
                        rows={marketTransactionsTableData}
                        headers={marketTransactionsTableHeaders}
                        labels={marketTransactionsTableLabels}
                        datosGrafico={datosGrafico}
                        payingCompany={payingCompany}
                        setPayingCompany={setPayingCompany}
                        payingCompaniesData={payingCompaniesData}
                        sinceValue={sinceValue}
                        setSinceValue={setSinceValue}
                        untilValue={untilValue}
                        setUntilValue={setUntilValue}
                    />
                }
            </Grid>
        </Container>
    );
}
