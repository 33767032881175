import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import LineChart from '../../../Components/LineChart/LineChart'
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TableWithSeparation from '../../../Components/Tables/TableWithSeparation'
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Paginations from '../../../Components/Pagination/Paginations'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import esLocale from 'date-fns/locale/es';
import moment from 'moment'



export default function MarketTransactions ({
    rows,
    headers,
    labels,
    datosGrafico,
    payingCompany,
    setPayingCompany,
    payingCompaniesData,
    sinceValue,
    setSinceValue,
    untilValue,
    setUntilValue
}) {

    const [page, setPage] = useState(0);
    const [currentTransactions, setCurrentTransactions] = useState(rows);
    const [selectedPayers, setSelectedPayers] = useState([])
    const [period, setPeriod] = useState(0);
    
    const icon = <CheckBoxOutlineBlankIcon sx={{ color: 'blue' }} fontSize="small" />;
    const checkedIcon = <CheckBoxIcon sx={{ color: 'blue' }} fontSize="small" />;

    const handlePeriod = (event) => {
        setPeriod(event);
        setUntilValue(moment().get());
        setSinceValue(moment().subtract(event, 'days'));
    }

    useEffect(() => {
        if(selectedPayers.length > 0)
            setCurrentTransactions(rows.filter((object) => selectedPayers.some(item => item.id === object.payerId)))
        else
            setCurrentTransactions(rows)
    }, [rows]);

    const handlePayingCompany = (event) => {
        setPayingCompany(event.target.value);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card className="paying-company-cards" style={{ height: 80, textAlign: 'center', background: '#262626' }} >
                        <Box>
                            <ButtonGroup size='small'>
                                <Button onClick={() => handlePeriod(0)} disabled={period === 0}>1 día</Button>
                                <Button onClick={() => handlePeriod(7)} disabled={period === 7}>1 semana</Button>
                                <Button onClick={() => handlePeriod(30)} disabled={period === 30}>1 mes</Button>
                                <Button onClick={() => handlePeriod(90)} disabled={period === 90}>3 meses</Button>
                                <Button onClick={() => handlePeriod(180)} disabled={period === 180}>6 meses</Button>
                                <Button onClick={() => handlePeriod(365)} disabled={period === 365}>1 año</Button>
                                <Button onClick={() => handlePeriod(1825)} disabled={period === 1825}>5 años</Button>
                            </ButtonGroup>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Desde</Typography>}
                                            value={sinceValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setSinceValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" size='small' {...params} />}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: 190 }}>
                                        <DatePicker
                                            label={<Typography variant='h6' color="primary">Hasta</Typography>}
                                            value={untilValue}
                                            InputAdornmentProps={{
                                                className: 'datePickerCalendars'
                                            }}
                                            onChange={(newValue) => {
                                                setUntilValue(moment(newValue));
                                            }}
                                            renderInput={(params) => <TextField className="datePickerContainer" {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </LocalizationProvider>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ width: '20vw' }} >
                        <InputLabel sx={{ color: 'white' }} >Empresa pagadora</InputLabel>
                        <Select
                            className="filters-select"
                            value={payingCompany}
                            label="Empresa pagadora"
                            onChange={handlePayingCompany}
                            sx={{ height: '53.45px' }}
                            MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
                        >
                            {payingCompaniesData.map((object, i) => {
                                return <MenuItem key={object.id} value={object.nombre}>{object.nombre}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <Typography variant='h5'  marginTop={'20px'} fontWeight={600}>
                        Transacciones del mercado
                    </Typography>
                    <Card className="home-cards home-cards-line" style={{
                        maxHeight: "40vh",
                        overflow: "auto",
                        marginTop: "20px",
                    }}>
                        <LineChart
                            data={datosGrafico}
                            sinceValue={sinceValue}
                            untilValue={untilValue}
                            leftTitle={"tasa promedio %"}
                            rightTitle={"$ M"}
                        />
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                        className='custom-autocomplete'
                        id="payers-tags"
                        multiple
                        options={payingCompaniesData}
                        disableCloseOnSelect
                        renderTags={() => { }}
                        onChange={(event, newValue) => {
                            setSelectedPayers(newValue)
                            if (newValue.length > 0)
                                setCurrentTransactions(rows.filter((object) => newValue.some(item => item.id === object.payerId)))
                            else
                                setCurrentTransactions(rows)
                        }}
                        noOptionsText='No se encuentran pagadoras'
                        getOptionLabel={(option) => option.nombre}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.nombre}
                            </li>
                        )}
                        style={{ width: '20vw' }}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="h7" color="primary">Empresas pagadoras</Typography>} placeholder="Buscar..." />
                        )}
                    />
                    <Typography variant="h5"  marginTop={'20px'} fontWeight={600}>
                        Resumen del mercado
                    </Typography>
                    <Card style={{ padding: "10px 20px", marginTop: "20px" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TableWithSeparation
                                    rows={currentTransactions.slice((page * 15), (page * 15) + 15)}
                                    headers={headers}
                                    labels={labels}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                    <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                        <Paginations
                            onChange={(event, val) => { setPage(val - 1) }}
                            count={Math.ceil(currentTransactions.length / 15)}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}