import { AppContext } from './libs/contextlib'
import { useState } from 'react';

import './App.css';
// Router
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// Style theme
import { ThemeProvider } from '@mui/material/styles'
import theme from '../src/style/theme';
import axios from 'axios'

import Menu from './Components/Menu/Menu';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Fondos from './Pages/Fondos/Fondos'
import Transacciones from './Pages/Transacciones/Transacciones';
import Pagos from './Pages/Pagos/Pagos';
import Cobros from './Pages/Cobros/Cobros';
import Mercado from './Pages/Mercado/Mercado';
import Dialog from './Components/Dialog/dialog';
import DownloadSnackbar from './Components/DownloadSnackbar/downloadSnackbar';

import Perfil from './Pages/Perfil/Perfil';
import NuevoUsuario from './Pages/NuevoUsuario/NuevoUsuario';
import RecuperarClave from './Pages/RecuperarClave/recuperarClave';

function App() {
    const [activeFund, setActiveFund] = useState({})
    // const[ isAuthenticated, setIsAuthenticated] = useState(false)
    // Fondos
    const [fundView, setFundView] = useState('dailyLimits')
    // Transacciones
    const [transactionView, setTransactionView] = useState('allTransactions')
    // Pagos
    const [pagosView, setPagosView] = useState('downloadarchive')
    // Cobros
    const [cobrosView, setCobrosView] = useState('collectioncalendar')
    // Mercado
    const [mercadoView, setMercadoView] = useState('lastTransactions')
    // Modal activo
    const [alertMessageOpen, setAlertMessageOpen] = useState(false)
    // Titulo modal
    const [alertMessageTitle, setAlertMessageTitle] = useState('')
    // Descripción modal
    const [alertMessageDescription, setAlertMessageDescription] = useState('')
    // Tipo modal (error, warning, success)
    const [alertMessageType, setAlertMessageType] = useState('')
    // SnackBar para descarga
    const [downloadingOpen, setDownloadingOpen] = useState(false)
    // Título SnackBar descarga
    const [downloadingTitle, setDownloadingTitle] = useState('')
    // Usa límite de oferta?
    const [offerLimit, setOfferLimit] = useState(false);
    // Usa límite de fondo?
    const [fundLimit, setFundLimit] = useState(false);
    // Usa límite de pagadora?
    const [payerLimit, setPayerLimit] = useState(false);
    // Acepta un valor máximo por factura por fondo?
    const [maxInvoiceFund, setMaxInvoiceFund] = useState(false);
    // Acepta un valor mínimo por factura por fondo?
    const [minInvoiceFund, setMinInvoiceFund] = useState(false);
    // Acepta un valor máximo por factura por pagadora?
    const [maxInvoicePayer, setMaxInvoicePayer] = useState(false);
    // Acepta un valor mínimo por factura por pagadora?
    const [minInvoicePayer, setMinInvoicePayer] = useState(false);
    // Tiene un límite total para fondo?
    const [maxTotalFund, setMaxTotalFund] = useState(false);
    // Tiene operaciones rechazadas?
    const [rejectedPayments, setRejectedPayments] = useState(false);
    // Se informan operaciones rechazadas
    const [rechazados, setRechazados] = useState(false);
    // Rol de usuario comprador
    const [rolComprador, setRolComprador] = useState('analistaComprador');
    // Usa horario personalizado?
    const [customSchedule, setCustomSchedule] = useState(false);
    // Horario de apertura
    const [openingTime, setOpeningTime] = useState();
    // Horario de cierre
    const [closingTime, setClosingTime] = useState();
    // Horario de apertura Btrust
    const [openingTimeBtrust, setOpeningTimeBtrust] = useState();
    // Horario de cierre
    const [closingTimeBtrust, setClosingTimeBtrust] = useState();
    // Actualizar indicador de horario?
    const [updateTimeIndicator, setUpdateTimeIndicator] = useState(false);

    //Host del servicio
    const hostService = 'https://portal.btrust.finance/';


    const formatAmount = (monto) => {
        if(monto < 0) return "0.00";
        monto += '';
        if(monto === "-0") return "0.00"
        let x = monto.split(',');
        let x1 = x[0];
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1.$2');
        }
        return x1;
    }

    const formatFee = (fee) => {
        fee = fee + '';
        return (parseFloat(fee.replace(',', '.')).toFixed(4)).replace('.', ',');
    }

    const formatFeeComplete = (fee) => {
        fee = (fee + '').replace('.', ',');

        let decimal = fee.split(',');
        
        fee = parseInt(decimal[0] + decimal[1].substring(0, 2)) + "," + decimal[1].substring(2);

        decimal = fee.split(',');
        
        while(decimal[1].length < 20){
            decimal[1] += '0';
        }

        fee = decimal[0] + "," + decimal[1];

        let first = fee.substring(0, 6);
        let second = fee.substring(6).replace(/0*$/, "");

        return first + second;
    }

    const downloadBase64 = (name, base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var sampleArr = bytes;
        var blob = new Blob([sampleArr], { type: "application/octet-stream" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = name;
        link.download = fileName;
        link.click();
    }

    const verifyPassword = (password) => {
        var blanksRegex = /^[^ ]+$/;
        var lengthRegex = /^(.){8,30}$/;
        var digitRegex = /(?=.*\d)/;
        var letterRegex = /(?=.*[a-zA-Z])/;

        var blanksTest = blanksRegex.test(password);
        var lengthTest = lengthRegex.test(password);
        var digitTest = digitRegex.test(password);
        var letterTest = letterRegex.test(password);

        return { blank: blanksTest, len: lengthTest, digit: digitTest, letter: letterTest };
    }

    const resultPassword = (password) => {
        const passTest = verifyPassword(password);
        const blank = passTest.blank ? '' : 'La contraseña no puede contener espacios en blanco.\n';
        const len = passTest.len ? '' : 'La contraseña debe tener entre 8 y 30 caracteres.\n';
        const digit = passTest.digit ? '' : 'La contraseña debe contener al menos un digito.\n';
        const letter = passTest.letter ? '' : 'La contraseña debe contener al menos una letra.\n';

        return blank + len + digit + letter;
    }

    const downloadExcel = (title, headers, rows) => {
        setDownloadingTitle('Descargando Excel');
        setDownloadingOpen(true);
        let data = {
            title: title,
            headers: JSON.stringify(headers),
            rows: JSON.stringify(rows)
        }
        axios.post(hostService + `servicios/Compradores.asmx/DownloadExcel`, data, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay datos para descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                console.log(error);
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const downloadPdf = (title, headers, rows) => {
        setDownloadingTitle('Descargando Pdf');
        setDownloadingOpen(true);
        let data = {
            title: title,
            headers: JSON.stringify(headers),
            rows: JSON.stringify(rows)
        }
        axios.post(hostService + `servicios/Compradores.asmx/DownloadPdf`, data, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay datos para descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                console.log(error);
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider
                value={{
                    activeFund,
                    setActiveFund,
                    fundView,
                    setFundView,
                    offerLimit,
                    setOfferLimit,
                    fundLimit,
                    setFundLimit,
                    payerLimit,
                    setPayerLimit,
                    maxInvoiceFund,
                    setMaxInvoiceFund,
                    minInvoiceFund,
                    setMinInvoiceFund,
                    maxInvoicePayer,
                    setMaxInvoicePayer,
                    minInvoicePayer,
                    setMinInvoicePayer,
                    maxTotalFund,
                    setMaxTotalFund,
                    customSchedule,
                    setCustomSchedule,
                    openingTime,
                    setOpeningTime,
                    closingTime,
                    setClosingTime,
                    openingTimeBtrust,
                    setOpeningTimeBtrust,
                    closingTimeBtrust,
                    setClosingTimeBtrust,
                    updateTimeIndicator,
                    setUpdateTimeIndicator,
                    rejectedPayments,
                    setRejectedPayments,
                    transactionView,
                    setTransactionView,
                    pagosView,
                    setPagosView,
                    cobrosView,
                    setCobrosView,
                    mercadoView,
                    setMercadoView,
                    alertMessageOpen,
                    setAlertMessageOpen,
                    alertMessageTitle,
                    setAlertMessageTitle,
                    alertMessageDescription,
                    setAlertMessageDescription,
                    alertMessageType,
                    setAlertMessageType,
                    formatAmount,
                    formatFee,
                    formatFeeComplete,
                    downloadBase64,
                    resultPassword,
                    downloadingOpen,
                    setDownloadingOpen,
                    setDownloadingTitle,
                    hostService,
                    rechazados,
                    setRechazados,
                    rolComprador,
                    setRolComprador,
                    downloadExcel,
                    downloadPdf
                }}
            >
                <Router>
                    <Routes>
                        <Route
                            element={
                                <Login />
                            }
                            exact path='/login'
                        />
                        <Route
                            element={
                                <Login />
                            }
                            exact path='/'
                        />
                        <Route
                            element={
                                <>
                                    <Menu isActive={'/'} />
                                    <Home />
                                </>
                            } exact path='/home'
                        />
                        <Route
                            element={
                                <>
                                    <Menu isActive={'fondos'}
                                    />
                                    <Fondos />
                                </>
                            } exact path='/fondos'
                        />
                        <Route
                            element={
                                <>
                                    <Menu isActive={'mercado'}
                                    />
                                    <Mercado />
                                </>
                            } exact path='/mercado'
                        />
                        <Route
                            element={
                                <>
                                    <Menu
                                        isActive={'transacciones'}
                                    />
                                    <Transacciones />
                                </>
                            } exact path='/transacciones'
                        />
                        <Route
                            element={
                                <>
                                    <Menu
                                        isActive={'pagos'}
                                    />
                                    <Pagos />
                                </>
                            } exact path='/pagos'
                        />
                        <Route
                            element={
                                <>
                                    <Menu
                                        isActive={'cobros'}
                                    />
                                    <Cobros />
                                </>
                            } exact path='/cobros'
                        />
                        <Route
                            element={
                                <>
                                    <Menu
                                        isActive={'perfil'}
                                    />
                                    <Perfil />
                                </>
                            } exact path='/perfil'
                        />
                        <Route
                            element={
                                <NuevoUsuario />
                            }
                            exact path='/NuevoUsuario'
                        />
                        <Route
                            element={
                                <RecuperarClave />
                            }
                            exact path='/RecuperarClave'
                        />
                    </Routes>
                </Router>
                <DownloadSnackbar
                    open={downloadingOpen}
                    title={downloadingTitle}
                />
                <Dialog/>
            </AppContext.Provider>
        </ThemeProvider>
    );
}

export default App;
