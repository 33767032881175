import React from 'react'
import { ComposedChart, Area, Label, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import moment from 'moment'
import Typography from '@mui/material/Typography'

import './linechart.css'

export default function LineChart({
    data,
    sinceValue,
    untilValue,
    leftTitle,
    rightTitle
}) {
    data = data.sort((a, b) => a.time - b.time)

    let diff = untilValue.diff(sinceValue)

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    {diff > 7 ?
                        <p className="label">Fecha: {`${moment(label).format('DD-MM-YYYY')}`}</p>
                        : <p className="label">Hora: {`${moment(label).format('HH:mm')}`}</p>
                    }
                    <p className="label">Tasa: {(payload[0].value).toFixed(4).replace('.', ',')}%</p>
                    <p className="label">Monto: {payload[1].value.toLocaleString('es-CL')} M</p>
                </div>
            );
        }

        return null;
    };

    const formatYAxis = (tickItem) => {
        return `${parseFloat(tickItem).toFixed(2).replace('.', ',')}`
    }

    const formatYAxisAmount = (tickItem) => {
        return `${parseFloat(tickItem).toFixed(0)}`
    }

    return (
        <>
        <ResponsiveContainer width="100%" height="100%" >
            <ComposedChart
                width={500}
                height={400}
                data={data}
                label={<Label fill={'white'} />}
                margin={{
                    top: 45,
                    right: 10,
                    left: 10,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#3760D9" stopOpacity={1} />
                        <stop offset="100%" stopColor="#3760D9" stopOpacity={0.2} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <XAxis
                    dataKey='time'
                    domain={[data[1].time, data[data.length - 1].time]}
                    hide={true}
                    type='number'
                    padding={{ left: 10, right: 10 }}
                />
                <YAxis
                    yAxisId="left"
                    orientation="left"
                    tick={{ fill: '#fff', strokeWidth: 1 }}
                    tickFormatter={formatYAxis}
                    dataKey="rate"
                    domain={[0, ((Math.max(...data.map(x => {
                        if (x.amount !== undefined) {
                            return x.rate
                        }
                        return 0;
                        }
                    )))) * 1.1 ]}
                    label={{ 
                        value: leftTitle, 
                        angle: 0, 
                        fill: 'white',
                        stroke: "white",
                        viewBox: {x: 70, y: 0, width: 0, height: 15}
                    }}
                />
                <YAxis
                    yAxisId="right"
                    type="number"
                    dataKey="amount"
                    orientation="right"
                    tick={{ fill: '#fff', strokeWidth: 1 }}
                    tickFormatter={formatYAxisAmount}
                    domain={[0, ((Math.max(...data.map(x => {
                        if (x.amount !== undefined) {
                            return x.amount
                        }
                        return 0;
                        }
                    )))) * 1.1 ]}
                    label={{ 
                        value: rightTitle, 
                        angle: 0, 
                        position: "top", 
                        offset: 30,
                        fill: 'white',
                        stroke: "white"
                    }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" yAxisId="left" dataKey="rate" strokeWidth={2} stroke="#3760D9" fill="url(#colorUv)" />
                <Bar dataKey="amount" yAxisId="right" barSize={5} fill="#3760d9" />
            </ComposedChart>
        </ResponsiveContainer>
        </>
    )
}