import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tables from '../../../Components/Tables/Table'
import Paginations from '../../../Components/Pagination/Paginations'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Modal from '@mui/material/Modal'

export default function Balance({
    rows,
    headers,
    labels,
    openModal,
    balanceInfoHeaders,
    balanceInfoLabels,
    balanceInfoRows,
    setOpenModal,
    balanceData,
    search,
    setSearch,
    downloadExcel,
    downloadPdf
}) {
    const [page, setPage] = useState(0);
    const [pageModal, setPageModal] = useState(0);
    
    const handleDownloadExcel = () => {
        let title = 'Saldos ' + balanceInfoRows[0].payerName;
        let headerRow = {};
        balanceInfoHeaders.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        downloadExcel(title, headerRow, balanceInfoRows);
    }

    const handleDownloadPdf = () => {
        let title = 'Saldos ' + balanceInfoRows[0].payerName;
        let headerRow = {};
        balanceInfoHeaders.map(e => {
            let element = {};
            element[e] = e;
            headerRow = { ...headerRow, ...element };
            return element;
        });

        downloadPdf(title, headerRow, balanceInfoRows);
    }

    // useEffect(() => {
    //     if (search) {
    //         setPage(0);
    //         setCurrentRows(rows.filter((element) => {
    //             return element.name.props.children.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //         }));
    //     } else {
    //         setCurrentRows(rows);
    //     }
    // }, [search]);

    // useEffect(() => {
    //     setSearch('');
    //     setCurrentRows(rows);
    // }, [balanceData]);

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                {/* <Grid item xs={12} className='buttons-container'>
                    <Button onClick={handleDownloadExcel}
                        variant='contained'
                        color='buttonColor'
                    >
                        Descargar Excel
                    </Button>
                    <Button onClick={handleDownloadPdf}
                        variant='contained'
                        color='buttonColor'>
                        Descargar PDF
                    </Button>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                    ></SearchBar>
                </Grid> */}
            </Grid>
            <Grid
                item
                xs={8}
                style={{
                    maxHeight: "75vh",
                    overflow: "auto",
                    marginTop: "20px",
                }}
            >
                <Typography variant="h5" fontWeight={600}>
                    Saldos
                </Typography>
                <Card style={{ padding: "10px 20px", marginTop: "10px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tables
                                rows={rows.slice((page * 14), (page * 14) + 14)}
                                headers={headers}
                                labels={labels}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Box display="flex" justifyContent="end" marginTop="20px" width='68%'>
                <Paginations
                    onChange={(event, val) => { setPage(val - 1) }}
                    count={Math.ceil(rows.length / 14)}
                />
            </Box>
            <Modal
                open={openModal}
                onClose={() => {
                    setPageModal(0)
                    setOpenModal(false)
                }}
            >
                <Box className='balance-modal'>
                    <Grid container>
                        <Grid item xs={12} className='buttons-container'>
                            <Button onClick={handleDownloadExcel}
                                variant='contained'
                                color='buttonColor'
                            >
                                Descargar Excel
                            </Button>
                            <Button onClick={handleDownloadPdf}
                                variant='contained'
                                color='buttonColor'>
                                Descargar PDF
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '20px' }}>
                            <Tables
                                rows={balanceInfoRows.slice((pageModal * 10), (pageModal * 10) + 10)}
                                headers={balanceInfoHeaders}
                                labels={balanceInfoLabels}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="end" marginTop="20px" width='100%'>
                        <Paginations
                            onChange={(event, val) => { setPageModal(val - 1) }}
                            count={Math.ceil(balanceInfoRows.length / 10)}
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    )
}