import { styled } from '@mui/material/styles';
import { Link, Typography, Box, Grid, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Alert } from "@mui/material";

import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

import axios from 'axios';
import { useAppContext } from "../../../libs/contextlib";
import data from '../../../mockData/Transacciones/payingCompanyLineChartData';
import { useState } from 'react';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});



export default function BulkLoad() {

    const { hostService,
        activeFund,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType, } = useAppContext();

    const [errorList, setErrorList] = useState([]);

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            uploadExcel(file);
        }
    }

    async function uploadExcel(excel) {
        const formData = new FormData();
        formData.append('file', excel);

        try {
            axios({
                method: "post",
                url: hostService + 'servicios/Compradores.asmx/UpdateAmountAndRateMassively',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
                responseType: 'text'
            })
                .then(response => {
                    const xmlString = response.data;
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
                    const jsonString = xmlDoc.getElementsByTagName('string')[0].textContent;
                    const jsonResponse = JSON.parse(jsonString);
                    const property = Object.keys(jsonResponse.data.data);

                    if (property.indexOf('Columnas incorrectas') !== -1) {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle('Error de formato');
                        setAlertMessageDescription('Las columnas del archivo excel no se encuentran en el formato correcto');
                        setAlertMessageType('error');
                        return
                    }

                    setErrorList([]);
                    setErrorList(prevErrorList => {
                        const newErrors = [];
                        for (let pagadora in jsonResponse.data.data) {
                            if (Array.isArray(jsonResponse.data.data[pagadora]) && jsonResponse.data.data[pagadora].length > 0) {
                                newErrors.push({ rutPagadora: pagadora, error: jsonResponse.data.data[pagadora] });
                            }
                        }
                        return [...prevErrorList, ...newErrors];
                    });

                    let errorCount = 0;
                    for (let pagadora in jsonResponse.data.data) {
                        if (Array.isArray(jsonResponse.data.data[pagadora]) && jsonResponse.data.data[pagadora].length > 0) {
                            errorCount++;
                        }
                    }

                    let successfullOperations
                    if (errorCount == property.length) {
                        setAlertMessageOpen(true);
                        setAlertMessageTitle("Operación fallida");
                        setAlertMessageDescription("No se logró actualizar ninguna pagadora");
                        setAlertMessageType('error');
                    }
                    else if (errorCount > 0) {
                        successfullOperations = Object.keys(jsonResponse.data.data).length - errorCount;
                        setAlertMessageOpen(true);
                        setAlertMessageTitle("Operación completada");
                        setAlertMessageDescription("Se actualizaron " + successfullOperations + " pagadoras exitosamente");
                        setAlertMessageType('info');
                    }
                    else {
                        successfullOperations = Object.keys(jsonResponse.data.data).length
                        setAlertMessageOpen(true);
                        setAlertMessageTitle("Operación completada");
                        setAlertMessageDescription("Se actualizaron " + successfullOperations + " pagadoras exitosamente");
                        setAlertMessageType('success');
                    }
                })

        } catch (error) {
            console.error('Error:', error);
        }
    }


    return (
        <Grid item xs={6} sx={{
            height: "85vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Box>
                <Typography
                    fontWeight={600}
                    align="left"
                    variant="h4"
                    sx={{ padding: "1rem 0" }}>
                    Carga Masiva
                </Typography>
                <Typography
                    align="center"
                    variant="body">
                    Carga de manera masiva las tasas de interes y los montos totales para cada pagadora mediante un archivo excel.
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", padding: "2rem 0" }}>
                    <Link href={hostService + 'Ejemplos/Plantilla carga masiva de tasas y montos.xlsx'}>
                        <Button component="label" variant="outlined" startIcon={<DownloadIcon />}>
                            Descargar Plantilla
                        </Button>
                    </Link>
                    <Button
                        component="label"
                        variant="contained"
                        color="buttonColor"
                        startIcon={<FileUploadIcon />}
                    >
                        Subir Excel
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                    </Button>
                </Box>
            </Box>
            {
                errorList.length > 0 && <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Rut de la pagadora</TableCell>
                                <TableCell align="center">Errores</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errorList.map((row) => (
                                <TableRow
                                    key={row.rutPagadora}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {row.rutPagadora}
                                    </TableCell>
                                    <TableCell align="center">{row.error.map(errores => errores)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Grid >
    )
}