import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../libs/contextlib'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/material/styles'
import CollectionCalendar from './CobrosViews/CollectionCalendar'
import DelinquentOperations from './CobrosViews/DelinquentOperations'
import CollectedOperations from './CobrosViews/CollectedOperations'
import AcceptReprogramming from './CobrosViews/AcceptReprogramming'
import ReprogrammingRecords from './CobrosViews/ReprogrammingRecords'
import downloadIcon from '../../assets/images/download-icon.png'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

//import notificationsData from '../../mockData/Cobros/notificaciones'
//import collectionData from '../../mockData/Cobros/collectionCalendarData'
//import rescheduleRecordData from '../../mockData/Cobros/rescheduleRecords'
//import rescheduleBillInfo from '../../mockData/Cobros/rescheduleBillInfo'

import axios from 'axios'
import moment from 'moment'

import profileIcon from '../../assets/images/profile-icon.png'

import './cobros.css'
import { Typography } from '@mui/material'

export default function Cobros() {
    const {
        cobrosView,
        setAlertMessageOpen,
        setAlertMessageTitle,
        setAlertMessageDescription,
        setAlertMessageType,
        formatAmount,
        formatFee,
        formatFeeComplete,
        downloadBase64,
        downloadingOpen,
        setDownloadingOpen,
        setDownloadingTitle,
        hostService,
        downloadExcel,
        downloadPdf
    } = useAppContext();

    const [openModal, setOpenModal] = useState(false);
    const [openRecordsModal, setOpenRecordsModal] = useState(false);
    const [collectionData, setCollectionData] = useState([]);
    const [notificationsData, setNotificationsData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [calendarTableHeaders, setCalendarTableHeaders] = useState(['Pagadora']);
    const [sended, setSended] = useState(false);
    const [totals, setTotals] = useState([<Typography variant="h6">Totales</Typography>, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [search, setSearch] = useState('');
    const [chargingSend, setChargingSend] = useState(false);
    const [rescheduleRecordData, setRescheduleRecordData] = useState([]);
    const [rescheduleBillInfo, setRescheduleBillInfo] = useState([]);
    const [delinquentOperationsRows, setDelinquentOperationsRows] = useState([]);
    const [collectedRows, setCollectedRows] = useState([]);
    const [infoId, setInfoId] = useState();
    const [modalOperationsOpen, setModalOperationsOpen] = useState(false);
    const [modalCollectedOpen, setModalCollectedOpen] = useState(false);
    const [sinceValue, setSinceValue] = useState(moment().get());
	const [untilValue, setUntilValue] = useState(moment().get());

    const handleOpenRecordsModal = () => setOpenRecordsModal(!openRecordsModal);

    const handleOpenModal = (pagadoraId) => {
        setModalData(notificationsData.filter(notificacion => notificacion.pagadoraId === pagadoraId));
        setOpenModal(true)
    };

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleToggleModalOperations = () => {
		setModalOperationsOpen(!modalOperationsOpen);
	}

    const handleToggleModalCollected = () => {
        setModalCollectedOpen(!modalCollectedOpen);
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#171717',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 'none',
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

    const renderProfiles = (name, pagadoraId, notifications, payerName, contactname, contactEmail, contactPhone) => {
        return (
            <div className='pagadora-profile'>
                <div>
                    <HtmlTooltip
                        placement='right'
                        title={
                            <React.Fragment>
                                <Typography fontSize={18} color="primary"><strong>Empresa:</strong> {payerName}</Typography>
                                <Typography>""</Typography>
                                <Typography fontSize={15} color="primary"><strong>Tesorero:</strong> {contactname}</Typography>
                                <Typography fontSize={15} color="primary"><LocalPhoneIcon fontSize='medium'/> {contactPhone}</Typography>
                                <Typography fontSize={15} color="primary"><EmailIcon fontSize='medium'/> {contactEmail}</Typography>
                            </React.Fragment>
                        }
                    >
                        <img src={profileIcon} alt="profile" />
                    </HtmlTooltip>
                    {notifications > 0 &&
                        notificationsData.length > 0 ? <Box onClick={() => handleOpenModal(pagadoraId)}>{notificationsData.length}</Box> : null
                    }
                </div>
                {name}
            </div>
        )
    }

    // CollectionCalendar table
    function createCalendarData(payer, d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12) {
        return { payer, d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12 };
    }

    //const calendarTableHeaders = ['Pagadora', 'Tasa OTC (%)', 'Monto disponible']
    const calendarTableLabels = ['payer', 'd1', 'd2', 'd3', 'd4', 'd5', 'd6', 'd7', 'd8', 'd9', 'd10', 'd11', 'd12']

    const renderCollectionCalendar = () => {
        let data = collectionData.map((e) => {
            let row = {
                payer: renderProfiles(e.payer[0], e.payer[1], e.payer[2], e.payerName, e.contactname, e.contactEmail, e.contactPhone),
                d1: e.d1,
                d2: e.d2,
                d3: e.d3,
                d4: e.d4,
                d5: e.d5,
                d6: e.d6,
                d7: e.d7,
                d8: e.d8,
                d9: e.d9,
                d10: e.d10,
                d11: e.d11,
                d12: e.d12,
            }
            return row
        })
        return data
    }
    const calendarRows = renderCollectionCalendar()

    if (calendarRows.length !== 0) {
        calendarRows.push(createCalendarData(...totals))
    }
    // CollectionCalendar table


    const getReprogramBtns = (extensionId) => {
        return (
            <Box>
                {chargingSend ?
                    <LoadingButton loading variant="contained" color="buttonColor" sx={{ marginRight: '8px' }}>
                        Aceptar
                    </LoadingButton>
                    :
                    <Button onClick={() => handleAcceptExtension(extensionId)} variant="contained" color="buttonColor" sx={{ marginRight: '8px' }}>
                        Aceptar
                    </Button>}
                <Button disabled={chargingSend} onClick={() => handleRejectExtension(extensionId)} variant="contained" color="buttonColor">
                    Rechazar
                </Button>
            </Box>
        )
    }

    // Reschedule payments table
    const acceptRescheduleTableHeaders = ['Pagadora', 'Cantidad facturas', 'Monto Total ($)', 'Antigua semana de pago', 'Nueva semana de pago', 'Acción']
    const acceptRescheduleTableLabels = ['payer', 'quantity', 'totalAmount', 'previousPayWeek', 'newPayWeek', 'action']

    const renderRescheduleTables = () => {
        let data = notificationsData.map((e) => {
            let row = {
                payer: e.pagadora,
                quantity: e.nFacturas,
                totalAmount: formatAmount(e.monto),
                previousPayWeek: e.vencimientoOriginal,
                newPayWeek: e.solicitudNuevaFecha,
                action: getReprogramBtns(e.extensionId)
            }
            return row
        })
        return data
    }
    const acceptRescheduleRows = renderRescheduleTables()
    // Reschedule payments table

    const getRescheduleRecordsBtns = (reprogramacionId) => {
        return (
            <Box>
                <Button
                    onClick={() => handleViewMore(reprogramacionId)}
                    variant="contained"
                    color="buttonColor"
                    sx={{ marginRight: '8px' }}
                >
                    Ver más
                </Button>
                <Button disabled={downloadingOpen} onClick={() => handleDownloadContract(reprogramacionId)} variant="contained" color="buttonColor">
                    <img
                        style={{
                            width: '17px',
                            marginRight: '10px'
                        }}
                        src={downloadIcon}
                        alt="descargar"
                    />
                    Descargar Contrato
                </Button>
            </Box>
        )
    }

    // Reschedule records table
    const rescheduleRecordsTableHeaders = ['Pagadora', 'Fecha de solicitud', 'Fecha Original de Pago', 'Nueva Fecha de Pago', 'Cantidad de Facturas', 'Monto total ($)', 'Tasa Acordada', 'Acción']
    const rescheduleRecordsTableLabels = ['payer', 'requestDate', 'originalPayingDate', 'newPayingDate', 'nBills', 'totalAmount', 'rate', 'action']

    const renderRescheduleRecordsTables = () => {
        let data = rescheduleRecordData.map((e) => {
            let row = {
                payer: e.nombrePagadora,
                requestDate: e.fecha,
                originalPayingDate: e.vencimientoOriginal,
                newPayingDate: e.nuevoVencimiento,
                nBills: e.cantidadFacturas,
                totalAmount: formatAmount(e.monto),
                rate: formatFee(e.tasa),
                action: getRescheduleRecordsBtns(e.reprogramacionId)
            }
            return row
        })
        return data
    }
    const rescheduleRecordsRows = renderRescheduleRecordsTables()
    // Reschedule records table

    // Reschedule payments details table
    const rescheduleDetailsTableHeaders = ['Folio', 'Tipo de Documento', 'Rut del Emisor', 'Monto Factura ($)']
    const rescheduleDetailsTableLabels = ['number', 'type', 'emitingRut', 'amount']

    const renderRescheduleDetailsTables = () => {
        let data = rescheduleBillInfo.map((e) => {
            let row = {
                number: e.folio,
                type: e.tipoFolio,
                emitingRut: e.rutEmisor,
                amount: formatAmount(e.montoFactura),
                buyerUser: e.nombreUsuarioComprador,
                buyerRut: e.rutUsuarioComprador,
                payerUser: e.nombreUsuarioPagadora,
                payerRut: e.rutUsuarioPagadora
            }
            return row
        })
        return data
    }
    const rescheduleDetailsRows = renderRescheduleDetailsTables()
    // Reschedule payments details table

    const formatMonto = (monto) => {
        monto = monto / 1000000;
        monto = Math.round(monto);
        monto += '';
        let x = monto.split('.');
        let x1 = x[0];
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1.$2');
        }
        return x1;
    }

	const delinquentOperationsTableHeaders = ['Id', 'Pagadora', 'RUT Pagadora', 'Folio factura', 'Fecha de operación', 'Fecha vencimiento', 'Monto cedido ($)', 'Monto financiado ($)', 'Días mora', 'Info']
	const delinquentOperationsTableLabels = ['id', 'company', 'rut', 'folio', 'date', 'dueDate', 'amountCeded', 'amountBought', 'daysPastDue', 'info']

    const collectedOperationsTableHeaders = ['Id', 'Pagadora', 'RUT Pagadora', 'Folio factura', 'Fecha de operación', 'Fecha vencimiento', 'Fecha recaudación', 'Monto cedido ($)', 'Monto financiado ($)', 'Monto recaudado ($)', 'Info', 'Comprobante']
	const collectedOperationsTableLabels = ['id', 'company', 'rut', 'folio', 'date', 'dueDate', 'collectionDate', 'amountCeded', 'amountBought', 'amountCollected', 'info', 'voucher']

    const handleAcceptExtension = (extensionId) => {
        setChargingSend(true);
        axios.post(hostService + `servicios/conector.asmx/AceptarSolicitudExtension`, { extensionId: extensionId }, { withCredentials: true })
            .then(response => {
                handleCloseModal();
                setChargingSend(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Solicitud rechazada correctamente');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                    setSended(!sended);
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleRejectExtension = (extensionId) => {
        axios.post(hostService + `servicios/conector.asmx/RechazarSolicitudExtension`, { extensionId: extensionId }, { withCredentials: true })
            .then(response => {
                handleCloseModal();
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'ok') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Solicitud rechazada correctamente');
                    setAlertMessageDescription('');
                    setAlertMessageType('success');
                    setSended(!sended);
                } else {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleViewMore = (reprogramacionId) => {
        axios.post(hostService + `servicios/conector.asmx/GetReprogramacionesInfo`, { reprogramacionId: reprogramacionId }, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setRescheduleBillInfo(respuesta.facturas);
                    handleOpenRecordsModal();
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleDownloadContract = (reprogramacionId) => {
        setDownloadingTitle('Descargando contrato de reprogramación');
        setDownloadingOpen(true);
        axios.post(hostService + `servicios/conector.asmx/DescargarDocumentoReprogramacion`, { reprogramacionId: reprogramacionId }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                setDownloadingOpen(false);
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const getVencimientosPorSemana = () => {
        axios.post(hostService + `servicios/conector.asmx/VencimientosPorSemana`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
                    const notificaciones = [];
                    Object.entries(respuesta.data.pagadoras).forEach(([nombrePagadora, value], i) => {
                        let pagadora = {
                            payer: [nombrePagadora, value.info.pagadoraId, value.info.notificaciones === '' ? 0 : value.info.notificaciones.cantidad],
                            otc: String(value.info.tasa).replace('.', ','),
                            available: formatMonto(value.info.montoDisponible),
                            contactname: value.info.nombreContacto,
                            contactPhone: value.info.telefonoContacto,
                            contactEmail: value.info.emailContacto,
                            payerName: value.info.nombreComprador
                        };

                        let montos = {};
                        let index = 1;
                        Object.entries(value.semanas).forEach(([key, val]) => {
                            if(i === 0) totals[index] = 0;
                            montos["d" + index] = formatMonto(val.monto);
                            totals[index] += parseInt(formatMonto(val.monto).replace('.', ''));
                            index++;
                        });
                        data.push({ ...pagadora, ...montos });

                        if (value.info.notificaciones !== '') {
                            value.info.notificaciones.info.map((notificacion) => {
                                notificaciones.push({
                                    pagadoraId: value.info.pagadoraId,
                                    extensionId: notificacion.extensionId,
                                    pagadora: notificacion.nombreEmpresa,
                                    monto: formatAmount(notificacion.monto),
                                    tasa: notificacion.tasa.replace('.', ','),
                                    nFacturas: notificacion.cantidadFacturas,
                                    vencimientoOriginal: notificacion.vencimientoOriginal,
                                    solicitudNuevaFecha: notificacion.nuevoVencimiento,
                                    status: notificacion.status
                                });
                                return notificaciones;
                            });
                        }
                    });

                    if (calendarTableHeaders.length === 1) {
                        Object.entries(respuesta.data.semanas).forEach(([fecha, value]) => {
                            calendarTableHeaders.push(moment(fecha, "DD-MM-YYYY").format("DD/MM"));
                        });
                    }

                    setNotificationsData(notificaciones);
                    setCollectionData(data);

                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleDownloadVoucherById = (idOperacion) => {
        setDownloadingTitle('Descargando comprobante de recaudación');
        setDownloadingOpen(true);
        axios.post(hostService + `servicios/Compradores.asmx/GetComprobanteRecaudacionPorId`, { idOperacion: idOperacion }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay comprobantes para descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    const handleDownloadVoucherByDate = () => {
        setDownloadingTitle('Descargando comprobante de recaudación');
        setDownloadingOpen(true);
        axios.post(hostService + `servicios/Compradores.asmx/GetComprobanteRecaudacionPorFecha`, { desde: sinceValue.format('YYYY-MM-DD'), hasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
            .then(response => {
                setDownloadingOpen(false);
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === '') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('No hay comprobantes para descargar');
                    setAlertMessageDescription('');
                    setAlertMessageType('warning');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    downloadBase64(respuesta.documents[0].nombre, respuesta.documents[0].base64);
                }
            }).catch(error => {
                setDownloadingOpen(false);
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }

    useEffect(() => {
        getVencimientosPorSemana();
        const interval = setInterval(getVencimientosPorSemana, 30000);

        axios.post(hostService + `servicios/conector.asmx/GetReprogramaciones`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    setRescheduleRecordData(respuesta.reprogramaciones);
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });

            return () => {clearInterval(interval)};
    }, [sended]);

    useEffect(() => {
        axios.post(hostService + `servicios/Compradores.asmx/GetOperacionesMora`, {}, { withCredentials: true })
            .then(response => {
                if (response.data.d === 'errorSession') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('La sesión ha caducado');
                    setAlertMessageDescription('');
                    setAlertMessageType('error');
                    window.location = "/login";
                } else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
                    var respuesta = JSON.parse(response.data.d);
                    const data = [];
					respuesta.operaciones.map((object) => data.push({
						id: object.idOperacion,
						company: object.nombrePagadora,
						rut: object.rutPagadora,
						date: object.fecha,
						emissionDate: object.fechaEmision,
						dueDate: object.fechaCobro,
						amountCeded: formatAmount(object.montoCedible),
						amountBought: formatAmount(object.montoComprado),
						comission: formatAmount(object.comisionConIva),
						difference: formatAmount(object.interesGanado),
						deposit: formatAmount(object.montoDepositar),
						razonSocialEmisor: object.razonSocialEmisor,
						rutEmisor: object.rutEmisor,
						tipoFolio: object.tipoFolio,
						folio: object.folio,
						montoOriginal: formatAmount(object.montoOriginal),
						razonSocialCesionario: object.razonSocialCesionario,
						rutCesionario: object.rutCesionario,
						tasa: formatFeeComplete((object.tasa)),
                        daysPastDue: object.diasMora,
						info: <Button onClick={() => [handleToggleModalOperations(), setInfoId(object.idOperacion)]}><InfoOutlinedIcon /></Button>
					}));

                    setDelinquentOperationsRows(data);
                }
            }).catch(error => {
                console.log(error)
                setAlertMessageOpen(true);
                setAlertMessageTitle('Ocurrió un error inesperado');
                setAlertMessageDescription('Inténtalo nuevamente');
                setAlertMessageType('error');
            });
    }, []);

    useEffect(() => {
		axios.post(hostService + `servicios/Compradores.asmx/GetOperacionesRecaudadas`, { desde: sinceValue.format('YYYY-MM-DD'), hasta: untilValue.format('YYYY-MM-DD') }, { withCredentials: true })
			.then(response => {
				if (response.data.d === 'errorSession') {
					setAlertMessageOpen(true);
					setAlertMessageTitle('La sesión ha caducado');
					setAlertMessageDescription('');
					setAlertMessageType('error');
					window.location = "/login";
				} else if (response.data.d === 'error') {
                    setAlertMessageOpen(true);
                    setAlertMessageTitle('Ocurrió un error inesperado');
                    setAlertMessageDescription('Inténtalo nuevamente');
                    setAlertMessageType('error');
                } else {
					var respuesta = JSON.parse(response.data.d);
					const data = [];
					respuesta.operaciones.map((object) => data.push({
						id: object.idOperacion,
						company: object.nombrePagadora,
						rut: object.rutPagadora,
						date: object.fecha,
						emissionDate: object.fechaEmision,
						dueDate: object.fechaCobro,
                        collectionDate: object.fechaRecaudacion,
						amountCeded: formatAmount(object.montoCedible),
						amountBought: formatAmount(object.montoComprado),
                        amountCollected: formatAmount(object.montoRecaudado),
						comission: formatAmount(object.comisionConIva),
						difference: formatAmount(object.interesGanado),
						deposit: formatAmount(object.montoDepositar),
						razonSocialEmisor: object.razonSocialEmisor,
						rutEmisor: object.rutEmisor,
						tipoFolio: object.tipoFolio,
						folio: object.folio,
						montoOriginal: formatAmount(object.montoOriginal),
						razonSocialCesionario: object.razonSocialCesionario,
						rutCesionario: object.rutCesionario,
						tasa: formatFeeComplete((object.tasa)),
						info: <Button onClick={() => [handleToggleModalOperations(), setInfoId(object.idOperacion)]}><InfoOutlinedIcon /></Button>,
                        voucher: <Button onClick={() => handleDownloadVoucherById(object.idOperacion) }><FileDownloadOutlinedIcon /></Button>
					}));
					setCollectedRows(data);
				}
			}).catch(error => {
				setAlertMessageOpen(true);
				setAlertMessageTitle('Ocurrió un error inesperado');
				setAlertMessageDescription('Inténtalo nuevamente');
				setAlertMessageType('error');
			});
	}, [sinceValue, untilValue]);

    return (
        <Container maxWidth="xxl">
            <Grid container className="pagos-container">
                {cobrosView === 'collectioncalendar' ?
                    <CollectionCalendar
                        headers={calendarTableHeaders}
                        labels={calendarTableLabels}
                        rows={calendarRows}
                        openModal={openModal}
                        modalData={modalData}
                        handleOpenModal={handleOpenModal}
                        handleCloseModal={handleCloseModal}
                        handleRejectExtension={handleRejectExtension}
                        handleAcceptExtension={handleAcceptExtension}
                        collectionData={collectionData}
                        search={search}
                        setSearch={setSearch}
                        chargingSend={chargingSend}
                        downloadExcel={downloadExcel}
                        downloadPdf={downloadPdf}
                    />
                    : cobrosView === 'delinquentOperations' ?
                        <DelinquentOperations
                            headers={delinquentOperationsTableHeaders}
                            labels={delinquentOperationsTableLabels}
                            rows={delinquentOperationsRows}
                            infoId={infoId}
                            modalOperationsOpen={modalOperationsOpen}
                            handleToggleModalOperations={handleToggleModalOperations}
                            search={search}
                            setSearch={setSearch}
                            formatAmount={formatAmount}
                            downloadExcel={downloadExcel}
                            downloadPdf={downloadPdf}
                        />
                        : cobrosView === 'collectedOperations' ?
                            <CollectedOperations
                                headers={collectedOperationsTableHeaders}
                                labels={collectedOperationsTableLabels}
                                rows={collectedRows}
                                infoId={infoId}
                                modalOperationsOpen={modalOperationsOpen}
                                handleToggleModalOperations={handleToggleModalOperations}
                                handleDownloadVoucherById={handleDownloadVoucherById}
                                handleDownloadVoucherByDate={handleDownloadVoucherByDate}
                                search={search}
                                setSearch={setSearch}
                                sinceValue={sinceValue}
								setSinceValue={setSinceValue}
								untilValue={untilValue}
								setUntilValue={setUntilValue}
                                downloadExcel={downloadExcel}
                                downloadPdf={downloadPdf}
                            />
                            : cobrosView === 'acceptreprogramming' ?
                                <AcceptReprogramming
                                    headers={acceptRescheduleTableHeaders}
                                    labels={acceptRescheduleTableLabels}
                                    rows={acceptRescheduleRows}
                                    search={search}
                                    setSearch={setSearch}
                                    notificationsData={notificationsData}
                                    downloadExcel={downloadExcel}
                                    downloadPdf={downloadPdf}
                                />
                                : cobrosView === 'reprogrammingrecords' ?
                                    <ReprogrammingRecords
                                        headers={rescheduleRecordsTableHeaders}
                                        labels={rescheduleRecordsTableLabels}
                                        rows={rescheduleRecordsRows}
                                        openRecordsModal={openRecordsModal}
                                        handleOpenRecordsModal={handleOpenRecordsModal}
                                        recordHeaders={rescheduleDetailsTableHeaders}
                                        recordLabels={rescheduleDetailsTableLabels}
                                        recordRows={rescheduleDetailsRows}
                                        search={search}
                                        setSearch={setSearch}
                                        rescheduleRecordData={rescheduleRecordData}
                                        downloadExcel={downloadExcel}
                                        downloadPdf={downloadPdf}
                                    />
                                    :
                                    null
                }
            </Grid>
        </Container>
    )
}